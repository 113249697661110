import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import nguia0 from "../../svg/portadas-naidi/naidi-1-1.svg";
import nguia1 from "../../svg/portadas-naidi/naidi-2-1.svg";
import nguia2 from "../../svg/portadas-naidi/naidi-3-1.svg";
import nguia3 from "../../svg/portadas-naidi/naidi-4-1.svg";

class GuiaNaidi1  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

  render(){
    return(
      <div className="App-book">
        <div className="breadcrbs">
          <h5>Inicio</h5>
          <FontAwesomeIcon icon={faAngleRight}/>
          <h5>Módulos</h5>
          <FontAwesomeIcon icon={faAngleRight}/>
          <h5>Guías</h5>
        </div>
        <div className="modulos">
          <div className="Lista-modulos">
            <span onClick={(e)=>this.someMethod('', '', false,'nguia0')}><Lista courses="Guía I" imgurl={nguia0} imgalt="Introducción a la Electrónica"/></span>
            <span onClick={(e)=>this.someMethod('', '', false,'nguia1')}><Lista courses="Guía II" imgurl={nguia1} imgalt="Introducción al Arduinio"/></span>
            <span onClick={(e)=>this.someMethod('', '', false,'nguia2')}><Lista courses="Guía III" imgurl={nguia2} imgalt="Introducción a MBLOCK"/></span>
            <span onClick={(e)=>this.someMethod('', '', false,'nguia3')}><Lista courses="Guía IV" imgurl={nguia3} imgalt="Introducción a la Robótica"/></span>
          </div>
        </div>
      </div>
    );
  }
}

export default GuiaNaidi1;
