import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft,faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube,faVimeo } from "@fortawesome/free-brands-svg-icons";
/* import { faFilePdf } from "@fortawesome/free-regular-svg-icons"; */
/* Guia2 */
class Guia2Naidi1  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
    
      <div className="flip-container">
                {/* paper 1 */}
                <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada1">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 1</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>Conceptos basicos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p> Estimados lectores, en la guía anterior aprendimos los conceptos básicos sobre la electricidad, la electrónica y todos sus actores, 
                                            de igual manera conocimos los componentes electrónicos básicos que podemos encontrar en un circuito,
                                             estos componentes normalmente los encontramos en todos aquellos electrodomésticos y elementos que en su composición, tengan algo de electrónica.
                                          </p>
                                            
                                                          
                                            </div>

                                            </div>
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       En ésta guía vamos a ir un poco más allá de lo básico,
                                        conoceremos un componente electrónico que ha aportado
                                        mucho a la revolución del mundo de la automatización, la
                                        inteligencia artificial, y la robótica.
                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          

                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                 
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px', textDecorationLine:'underline', color:'red'}}><h4 style={{fontFamily:'Montserrat-SemiBold' }}>Introduccion al arduino</h4> <h4 style={{ fontFamily:'Montserrat-SemiBold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                      Al culminar esta guia, los estudiantes estaran en la capacidad de
                                      reconocer la placa, arduino como el cerebro de las automatizaciones
                                      y la robotica, conociendo las diferentes placas existentes y en que
                                      caso usarlas, de igual manera, identificaran en que campos se puede
                                      usar el arduino y que aportes hace a diferentes areas en la vida del
                                      ser humano.
                                        

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Aprender conceptos básicos del arduino y los tipos de arduino que existen.

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Conocer sus características y diferentes modos de configuración.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Indagar los usos y aplicación del arduino en la vida real.
                                     </p>


                                     


                               
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                    
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: ¿Que es un arduino?</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'0px 0px 5px 0px'}}>
                                      Arduino es una de las placas mas populares del mundo. su versatilidad y la
                                      infinidad de posibilidades que ofeece la convierten en una de las herramientas
                                      de programacion mas completas del mercado. si no sabes que es un arduino, estas
                                      en el lugar indicado: a continuacion te explicaremos que es y para que sirve un
                                      arduino.
                                   </p>

                                   <p>  
                                   Basada en la filosofía del software libre, Arduino es una plataforma de electrónica “open-source”o de
                                  código abierto cuyos principios son contar con software y hardware fáciles de usar. Básicamente lo
                                  que permite esta herramienta es la generación de infinidad de tipos de microordenadores de una
                                  sola placa, que luego pueden tener una amplia variedad de usos según la necesidad de la persona
                                  que lo cree. Es decir, una forma sencilla de realizar proyectos interactivos para cualquier persona.
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/gifar.gif" alt="" />

                                     </div>

                         

                                   
                                  
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                               
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p>
                                    Arduino contiene la siguiente distribución de pines:
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Disponemos de 14 pines digitales que pueden ser configurados como entradas o salidas, de
                                      los cuales (serigrafiadas con el símbolo ~) pueden ser utilizados como señales digitales PWM
                                      6 pines.

                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Igualmente disponemos de 6 pines analógicos serigrafiados desde A0 hasta A5 para las entradas analógicas.
 
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● También disponemos de 3 pines GND para conectar a tierra nuestros circuitos.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}} >
                                    ● Y por último 2 pines de alimentación de 5V y 3.3V respectivamente.   
                                    <span  style={{color:'red'}}>Amplía información aquí </span> 
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/ElCableAmarillo/Introduccion-a-Arduino">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/arduino1.png" alt="" />

                                      </div>
                      
                                      
                                   
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>


                                  <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 5px 0px'}} >

                                      <div style={{padding:'0px 10px 0px 0px'}}>

                          <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                              </div>

                          <div className="container" style={{width:'95%'}}>


                        <p> Para que entiendan con más facilidad las características del Arduino, 
                    reproduzcan el siguiente video<span style={{color:'red'}}>  : Características principales de Arduino UNO</span>
                <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=ginIJFDFYUQ&list=PLHsWxcSr3WR4BTj_Wp6QDKJY3Qa7CV1Vn&index=4&t=50s">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                        </p>

                      </div>

                              </div>
                              <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> ¿Para que sirve un arduino?</h5></div>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                      Arduino se puede utilizar para desarrollar elementos autónomos, o bien conectarse a otros
                                      dispositivos o interactuar con otros programas, para interactuar tanto con el hardware como con el
                                      software. Sirve tanto para controlar un elemento, pongamos por ejemplo un motor que nos suba o
                                      baje una persiana basada en la luz que haya gracias a un sensor conectado al Arduino, o bien para
                                      transformar la información de una fuente, como puede ser un teclado, y convertir la información a
                                      algo que entienda, por ejemplo, un ordenador. </p>

                             
                        
                                     
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                  <p style={{ padding:'5px 0px 10px 0px',fontFamily:'Montserrat-SemiBold'}}>Actualmente, el uso de Arduino puede catalogarse en dos grandes grupos:</p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>● Arduino es utilizado como un microcontrolador, cuando tiene un programa descargado desde un
                                      ordenador y funciona de forma independiente de éste, y controla y alimenta determinados
                                      dispositivos y toma decisiones de acuerdo al programa descargado e interactúa con el mundo físico
                                      gracias a sensores y actuadores.</p>


                                      <p style={{ padding:'5px 0px 0px 0px'}}>● Arduino hace de interfaz entre un ordenador u otro dispositivo, que ejecuta una determinada tarea,
                                        para traducir dicha tarea en el mundo físico a una acción. Y viceversa, gracias a sensores que están
                                        conectados a la placa Arduino podemos hacer que el ordenador ejecute determinada acción.
                                        <span style={{color:'red'}}> Amplía información aquí</span>

                                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.fundacionaquae.org/wiki/sabes-arduino-sirve/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>

                                          </p>

                                          <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                         </div>

                                         
 
                                
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Clasificacion: Tipos de arduino - arduino uno</h5></div>

                                   

                                        <p style={{ padding:'5px 0px 0px 0px'}}> 
                                       <span style={{fontFamily:'Montserrat-SemiBold'}}>Arduino Uno es uno de los tipos de Arduinos más conocidos y utilizados</span> 
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Este es el Arduino más famoso y más vendido en el mercado. Así que, quien empieza a aprender
                                        sobre los microcontroladores de Arduino, normalmente compra Arduino Uno para empezar.
                                        Arduino Uno tiene 14 puertos digitales, 6 de los cuales pueden ser usados como salidas PWM, y tiene
                                        6 puertos analógicos.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Funciona con un microcontrolador ATmega328, que está montado en un enchufe de la placa, es
                                        decir, que puede quitarlo y cambiarlo como quiera. Si resulta que se quema el microcontrolador, sólo hay que cambiarlo,
                                         lo cual es una gran ventaja sobre otros Arduinos, que tienen el microcontrolador soldado en el tablero
                                        </p>


                                        <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle4.png" alt="" /> 

                                                </div>

                                     
                                    
                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                        El voltaje de suministro es de 7-12Vdc, y su voltaje de trabajo es de 5Vdc, y tiene 32kb de memoria.
                                        Es recomendable este Arduino a los principiantes, ya que su memoria es para muchos proyectos y
                                        tiene un buen número de puertos, además de la ventaja de poder cambiar el microcontrolador, algo
                                        que otros Arduinos no tienen.

                                        </p>

                                      
                                        <div style={{padding:'7px 0px 5px 0px'}}>

                                      <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/arduino2.png" alt="" />

                                      </div>

                                 
                                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino leonardo</h5></div>
                                     
                                      <p style={{ padding:'5px 0px 0px 0px'}}> 
                                        Muy parecido al Arduino Uno, pero con más puertas y otro microcontrolador.
                                          Tiene 20 puertos digitales, siendo 7 PWM, y 12 puertos analógicos. Sin embargo, el microcontrolador
                                          que es el Atmega32u4, está soldado en el tablero. Tiene 32kb de memoria.</p>

 
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Arduino Leonardo opera con 5Vdc y el voltaje de alimentación recomendado es de 7-12Vdc.
                                  La gran ventaja de Arduino Leonardo es que es reconocido como un teclado o un ratón por la
                                  computadora, esto debido al chip de conexión USB ya integrado en su microcontrolador, eliminando
                                  la necesidad de un circuito adicional.
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino_leonardo.png" alt="" />

                                </div>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino Mega 2560</h5></div>
                               
                       
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                      Tiene 54 puertos digitales, de los cuales 15 son PWM, y tiene 15 puertos analógicos. Funciona con el microcontrolador Atmega2560. 
                                      El Mega Arduino tiene 256kb de memoria, y un voltaje de alimentación de 7-12Vdc, y el voltaje de trabajo es de 5Vdc.

                                      </p>


                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Es adecuado para proyectos más grandes, que requerirán más puertos y más espacio de memoria
                                  para la programación. Por otro lado, cuesta el doble que un Arduino Uno, pero sigue siendo muy
                                  indicado, incluso para principiantes. La gran ventaja sobre la cantidad de puertos es la memoria
                                  extendida, que permite no pasar tanto tiempo optimizando el código para que quepa en memorias
                                  más pequeñas.
                                  </p>


                                  <div style={{padding:'10px 0px 5px 0px'}}>

                                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino_2560.png" alt="" />

                                  </div>



                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino Mega ADK</h5></div>
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Como el Arduino Mega 2560, tiene 54 puertos digitales, de estos 15 PWM, y tiene 15 puertos
                                      analógicos. Funciona con el microcontrolador 2560, y tiene 256kb de memoria.
                                      Voltaje de trabajo de 5Vdc y voltaje de suministro de 7-12Vdc.
                                      </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}> 
                                      La gran diferencia de Mega ADK es la capacidad de integrarse con los sistemas Android. Por lo tanto,
                                      tiene, además del puerto USB para la conexión en el ordenador, un puerto USB dedicado para la
                                      conexión en los dispositivos Android.

                                      </p>

                                

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p> 
                                  De esta manera, puedes usar Google ADK para crear programas para intercambiar información 
                                  entre Android y Arduino.
                                  </p>

                                  <p>
                                  Es un tablero muy interesante y diferenciado por su integración con Android. Así que, si tiene dudas
                                  entre el Mega 2560 y el Mega ADK, son el mismo tablero, con el único diferencial de la integración
                                  de Android. Así que, si pretende usar Android en sus proyectos, vale la pena comprar un Arduino
                                  Mega ADK.
                                  </p>
                                  
                                  <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino_adk.png" alt="" />

                                  </div>


                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino Due</h5></div>
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}>  
                                      La tarjeta con mayor capacidad de procesamiento, tiene un microcontrolador ARM AT915AM3X8E de 
                                      32 bits. Tiene unos increíbles 512kb de memoria.
                                      </p>
                                      <div style={{padding:'10px 0px 0px 0px'}}>

                          <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                    </div>

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Tiene 54 puertos digitales, siendo 12 PWM, y tiene 12 puertos analógicos.
                                  Su voltaje de alimentación es de 7-12Vdc, pero un detalle muy importante es que trabaja con 3,3Vdc,
                                  lo que hace imposible utilizar algunos escudos, que normalmente funcionan con 5Vdc.
                                  </p>

                                  <div style={{padding:'7px 0px 5px 0px'}}>

                                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino_due.png" alt="" />

                                  </div>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino Nano</h5></div>
                               
                                       <p  style={{ padding:'5px 0px 0px 0px'}}> 

                                        Tarjeta muy compacta, tiene sólo un conector USB para la alimentación y la conexión a la
                                        computadora, con 7-12Vdc de voltaje de alimentación y 5Vdc de voltaje de trabajo. De esta manera,
                                        el suministro externo se hace por el pin de Vin. 
                                      </p>

                                      <p  style={{ padding:'5px 0px 0px 0px'}}>
                                      Funciona con un microcontrolador Atmega168, que tiene 16kb de memoria (en versiones Nano 2.x)
                                      o un Atmega328, que tiene 32kb de memoria (en versiones Nano 3.x).
                                      Tiene 14 puertos digitales, 6 PWM y 8 puertos analógicos.
                                      </p>

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  El tamaño está bien reducido en comparación con otros Arduinos. Por lo tanto, este tablero es ideal
                                  para proyectos más compactos, con un tamaño reducido.
                                  </p>

                                  <div>

                              <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/arduino_nano.png" alt="" />

                                 </div>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino Pro Mini</h5></div>
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Placa aún más compacta, esta vez con microcontrolador Atmega168. Es más pequeño que Arduino Nano.
                                      </p>

                                      <p>

                                      Tiene dos versiones, una con un voltaje de trabajo de 3.3Vdc, y otra con un voltaje de trabajo de 5Vdc,
                                      tiene, como el Nano, 14 puertos digitales, siendo 6 PWM, y tiene 8 puertos analógicos.
                                      </p>

                                      <p>
                                      Un detalle es que no tiene un puerto USB para la comunicación, para ello debes comprar un módulo
                                      USB separado, o programarlo desde otro Arduino.
                                      </p>
                                      <div style={{padding:'5px 0px 0px 0px'}}>

                          <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/arduino_mini.png" alt="" />

                                        </div>

                                     
                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arduino Esplora</h5></div>

                                                           
                                     <p  style={{ padding:'5px 0px 5px 0px'}}> 
                                      Con un formato muy similar al de un control de videojuegos, este es un tablero diferente a todos los
                                       otros puntos de vista hasta ahora.   
                                      </p>

                                     <p>
                                     Está basado en el Arduino Leonardo, ya que funciona con un microcontrolador Atmega32u4, con 32kb de memoria. 
                                     Por lo tanto, el tablero viene con varios sensores y componentes incorporados,como:
                                     </p>

                                     <p>
                                     ● Joystick
                                     </p>

                                     <p>
                                     ● Potenciómetro deslizante lineal
                                     </p>
                                     <p>
                                     ● El sensor de temperatura
                                     </p>
                                     <p>
                                     ● Sensor de luz
                                     </p>

                                     <p>
                                     ● Acelerómetro
                                     </p>
                                     <p>
                                     ● LED RGB
                                     </p>
                                     <p>
                                     ● Micrófono
                                     </p>
                                     <p>
                                     ● Pulsadores
                                     </p>

                                     <div style={{padding:'5px 0px 0px 0px'}}>

                      <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino_esplora.png" alt="" />
                      <p><span  style={{color:'red'}}>Amplía información aquí </span> 
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a  rel="noreferrer" target='_blank' href="https://tueconomiafacil.com/tipos-de-arduinos/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span> </p> 

                                </div>

                                     

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Aplicación</h5></div>

                                  <h4 style={{fontWeight:'bold',textDecoration:'underline', color:'red'}}>Aplicaciones de arduino</h4>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  Arduino se ha popularizado por el creciente movimiento del DIY, como un elemento barato para hacer pequeños 
                                  proyectos de “bricolaje”, pero arduino va más allá. <span style={{color:'red'}}>Amplía información aquí </span> 

                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://en.wikipedia.org/wiki/Do_it_yourself">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span>
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  Existen multitud de entornos de aplicación de Arduino: automatización industrial, domótica,
                                  herramienta de prototipado, plataforma de entrenamiento para aprendizaje de electrónica,
                                  tecnología para artistas, eficiencia energética, monitorización, adquisición de datos, DIY, aprendizaje
                                  de habilidades tecnológicas y programación, etc…
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>

                                  En la educación tanto en institutos en secundaria y bachillerato como en formación profesional y la
                                  universidad, Arduino ha entrado con mucha fuerza para entrenar habilidades y como herramienta
                                  pedagógica.

                                  </p>

                               

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Robotica</h5></div>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 Robótica es otros de los entornos donde Arduino es muy usado, incluso ya hay un arduino robot:
                                 <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://docs.arduino.cc/retired/other/arduino-robot">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> o kits de robótica:
                                 <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="http://www.dx.com/p/arduino-compatible-bluetooth-controlled-robot-car-kits-146418#.Vvlc3uKLTcs">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> o en amazon 
                                 <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.amazon.com/Makeblock-Starter-Robot-V2-0-Electronics/dp/B00H3D8L9O">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                </p>  

                                <div style={{padding:'5px 0px 0px 0px'}}>

                                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/kartbot.png" alt="" />

                                </div>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                También existen muchos productos y proyectos basados en Arduino, lo que nos da una idea de la
                                amplitud de los entornos donde podemos usar arduino. En las web de crowdfunding podemos
                                encontrar muchos productos basados en Arduino:
                                <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.kickstarter.com/discover/advanced?term=Arduino&sort=most_funded">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                               
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                El programa Arduino at heart tiene productos comerciales con el sello Arduino:
                                <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.arduino.cc/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                </p>


      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Arte</h5></div>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Un ejemplo de uso de Arduino en el mundo del arte se pudo ver en el museo wurth
                                  (http://www.museowurth.es/) en el mes de febrero de 2016 en la exposición light kinetics: 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a  rel="noreferrer" target='_blank' href="https://www.museowurth.es/light_kinetics.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 

                                  </p>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  Este video es una muestra del montaje: 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://vimeo.com/149774067">  <FontAwesomeIcon icon={faVimeo} style={{ cursor:'pointer', fontSize:'1.1rem', color:'#3399FF'}}/> </a></span>,
                                  vemos que para las obras de arte usa un arduino mega <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://docs.arduino.cc/hardware/mega-2560">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                   y un sensor de 9 grados de libertad con acelerometro, gisroscopio y magnetometro<span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.sparkfun.com/products/retired/10724">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                    que manda os datos a un ordenador y este mediante software controla los módulos DMX para encender las luces
                                  </p>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>IoT</h5></div>

                                  <p style={{padding:'5px 0px 0px 0px'}}> Una de las principales aplicaciones de Arduino está en el Internet de las cosas (IoT) o Internet de todo o dispositivos conectados o M2M. 
                                    Internet de las cosas (IoT, por su siglas en inglés) es un concepto que se refiere a la interconexión digital de objetos cotidianos con Internet.
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Otro aspecto que está muy de moda en este momento es el IoT en la industria, que también se le denomina “Industria Conectada” o Industria 4.0.
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Arduino IoT: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://create.arduino.cc/iot/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                    </p>

    
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 20 */}
                 <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Placa Arduino especialmente diseñado para el IoT: 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://docs.arduino.cc/hardware/mkr-1000-wifi">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 

                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Nota de prensa de Atmel:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  “The Internet of Things (IoT) will create a market worth $1.9 trillion by 2020, according to Gartner.
                                  </p>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  Atmel provides all the key building blocks for IoT applications—from embedded processing and 
                                  connectivity to sensors, security, and software. These include: Atmel® AVR® and ARM®-based 
                                  microcontrollers (MCUs), CryptoAuthentication™ devices, A complete sensing platform, 
                                  Standardsbased wireless technologies, including wireless modules and System on a Chip (SoC) devices.”

                                  </p>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Impresoras 3D</h5></div>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  Otros productos por los que conocemos Arduino son las impresoras 3D y los drones. El código o 
                                  sketch que funcionan dentro de un Arduino usado en un drone o en una impresora 3D están 
                                  disponibles y podemos modificarlos. En este curso no vamos a aprender a montar un drone una 
                                  impresora 3D, pero entenderemos que hace el Arduino usado en estos dispositivos y cómo usa los 
                                  periféricos usados (motores, display, botones, etc…)
                                  </p>

                          </div>

                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 21 */}
                <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Este enlace explica los materiales necesarios para montar una impresora 3D con Arduino y algunos conceptos relacionados:
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="http://saber.patagoniatecnology.com/kit-electronica-impresora-3d-arduino-argentina-ptec/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 

                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Firmware para impresoras 3D del proyecto reprap:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Pagina (1) <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="http://saber.patagoniatecnology.com/kit-electronica-impresora-3d-arduino-argentina-ptec/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  </p>
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● pagina (2)  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://es.wikipedia.org/wiki/Proyecto_RepRap">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● pagina (3) <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/reprap">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● pagina (4) <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/BCN3D/BCN3DPlus-Firmware">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Firmware Marlin (5) <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://reprap.org/wiki/Marlin">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> y codigo 
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/MarlinFirmware/Marlin">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/imp3d.png" alt="" />

                                     </div>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     Interesante tutorial paso a paso para montar una impresora 3D: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.cooking-hacks.com/documentation/tutorials/3d-printer-step-by-step-tutorial-guide-prusa-it3.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                      que usa el firmware Marlin. 
                                     </p>


                                

                          </div>

                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Drones</h5></div>
                                  
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Firmware usado con Drones con MCUs iguales a las de Arduino:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Proyecto Ardupilot: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://ardupilot.org/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> y 
                                  la nueva web <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://diydrones.com/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Proyecto Arducopter: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="http://www.arducopter.co.uk/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  y <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://ardupilot.org/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 

                                  </p>
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Codigo: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/ArduPilot/ardupilot">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● HW APM: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://3drobotics.com/apm/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● miniAPM: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="http://rc-innovations.es/ardupilot-APM-mini-V3.1-compatible-mavlink-aviones-UAV-rover-waypoint-piloto-automatico">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                          
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 3DR: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.3dr.com/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Erle Robotics: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://erlerobotics.com/blog/tienda/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>


                                  <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/dron.png" alt="" />

                                           </div>

                               
                                 
   

                          </div>

                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 23 */}
                     <div id="movPage23" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Rover con arduino</h5></div>
                                  
                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                    para mas informacion de rover con arduino,<span style={{color:'red'}} >dar click aqui</span> 
                                     <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>   <a rel="noreferrer" target='_blank' href="https://ardupilot.org/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>
                                  
                                <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/rovert.png" alt="" />

                               </div>

                               <p style={{padding:'10px 0px 0px 0px'}}>
                               ● Arduino como elemento de prototipado en la industria
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● Ejemplo de las zapatillas john luck:
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ●  Zapatillas con medidor de potencia: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ciclismoafondo.es/material/novedades-material-ciclismo/luck-presenta-sus-zapatillas-con-medidor-de-potencia_38038_102.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● John Luck se une al Centro Tecnológico del Calzado de La Rioja: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://esmtb.com/john-luck-se-une-al-centro-tecnologico-del-calzado-de-la-rioja/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● El arduino que va montado y se ve en la foto, que vale 9.95$: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.sparkfun.com/products/11113">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                               </p>
                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● El modulo bluetooth, que vale 34.95$: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.sparkfun.com/products/retired/12580">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● El sensor que vale 1.5$, posiblemente no sea este puesto que sería necesario un sensor con mucha más sensibilidad. <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.sparkfun.com/products/10293">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                               </p>

                          </div>

                         </div>
                     <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                
                     {/* paper 24 */}
                     <div id="movPage24" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="19" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conclusiones</h5></div>
                                  
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Como puede verse el ámbito de aplicación de Arduino es enorme, pero no es siempre la mejor solución para todo, en algunos 
                                  casos podemos usar otras soluciones o estar matando moscas a cañonazos.
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ¿En qué ámbito tiene sentido usar un Arduino y en cuáles no?. Por ejemplo, supongamos que nos piden hacer un sistema de temporización de iluminación, 
                                  para iluminar ciertas habitaciones o zonas de forma alterna. Para ello habrá que manipular el cuadro eléctrico para poner unos interruptores en los circuitos 
                                  de iluminación. En este caso sería mejor poner relés temporizadores y hacer la configuración de ellos
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/temporizador.png" alt="" />

                                </div>

                          </div>

                         </div>
                     <div data-active="19" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage24', 23, '', '', '', "19")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage24', 23, '', '', '', "19")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 25 */}
                     <div id="movPage25" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="20" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage24', 6, '', '', '', "19")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage24', 6, '', '', '', "19")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Ahora bien, si lo que queremos es que podamos cambiar esa temporización remotamente desde
                                  cualquier parte del mundo sin tener que ir al cuadro o apagar y encender las luces manualmente
                                  desde una web, entonces la solución puede ser un arduino más unos relés y unos drivers de relés
                                  para poder manejarlo desde el Arduino.
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/arduinop.png" alt="" />

                                     </div>

                                     <h4>¿Que aplicación profesional o personal piensas que podrías usar Arduino?</h4>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     Ejemplo: Obtener datos de mi coche a través del conector ODB: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.dfrobot.com/index.php?route=product/product&product_id=857">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> (ODB – UART adapter)
                                     </p>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     Ejemplo: Hacer un coche RC: <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=ejZg2qm53Qc">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                                     Amplía información aquí  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2016/06/26/entornos-de-aplicacion-arduino/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                     </p>
                          </div>

                         </div>
                     <div data-active="20" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage25', 24, '', '', '', "20")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage25', 24, '', '', '', "20")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 26 */}
                  <div id="movPage26" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="21" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage25', 5, '', '', '', "20")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage25', 5, '', '', '', "20")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 10px 0px'}} >

                              <div style={{padding:'0px 10px 0px 0px'}}>

                            <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                              </div>

                        <div className="container" style={{width:'95%'}}>


                        <p> Para que entiendan con más facilidad  los conceptos anteriores reproduce el 
                          siguiente video :<span style={{color:'red'}}>¿Qué es Arduino y qué proyectos podemos crear con sus placas?</span>
                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=YMF2sK7gyD0&list=PLHsWxcSr3WR4BTj_Wp6QDKJY3Qa7CV1Vn&index=5">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                        </p>

                        </div>

                          </div>
                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>    

                          <p style={{padding:'5px 0px 0px 0px'}}>
                        ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p>

                        <p style={{padding:'5px 0px 5px 0px'}}>
                        Para verificar que los conocimientos se hayan comprendido
                        correctamente, el formador hará una divertida competencia en
                        vivo, la cual consiste en proyectar unas preguntas por medio de la
                         aplicación  <span style={{color:'red'}}>Kahoot</span><span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 2px'}}><a rel="noreferrer" target='_blank' href="https://kahoot.com/"><FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/></a></span>, y los equipos conectados a la misma por medio
                         de las tablets deberán rápidamente seleccionar la(s) respuesta(s) correcta(s).

                        </p> 

                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>  

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        1 Documento en excel generado por la aplicación Kahoo, en donde se muestra el detalle de las
                          respuestas que realizaron los equipos participantes. 
                        </p>   

                          </div>

                         </div>
                     <div data-active="21" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage26', 25, '', '', '', "21")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage26', 25, '', '', '', "21")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 27 */}
                 <div id="movPage27" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="22" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage26', 4, '', '', '', "21")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage26', 4, '', '', '', "21")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                             
                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>    


                        <p style={{padding:'5px 0px 0px 0px'}}>
                                  
                        ● GitHub - ElCableAmarillo/Introduccion-a-Arduino: Breve introducción a la electrónica
                        con Arduino. (s. f.). GitHub. Recuperado 8 de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/ElCableAmarillo/Introduccion-a-Arduino">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                        </p>  

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● Fundación Aquae. (2020, 30 octubre). ¿Sabes qué es un Arduino y para qué sirve? -
                        Fundación Aquae. Recuperado 8 de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.fundacionaquae.org/wiki/sabes-arduino-sirve/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>      
                        </p> 

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● Quiroga, F. (2020, 4 octubre). Tipos de Arduinos: Diferencias y ventajas. TU ECONOMÍA
                        FÁCIL. Recuperado 8 de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://tueconomiafacil.com/tipos-de-arduinos/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>      
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● Aplicaciones de Arduino. (2021, 10 noviembre). Aprendiendo Arduino. Recuperado 8 de
                        marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2017/01/22/aplicaciones-de-arduino/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>      
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● Entornos de Aplicación Arduino. (2016, 26 junio). Aprendiendo Arduino. Recuperado 8
                        de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2016/06/26/entornos-de-aplicacion-arduino/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>      
                        </p>



                          </div>

                         </div>
                     <div data-active="22" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage27', 26, '', '', '', "22")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage27', 26, '', '', '', "22")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>




                {/* paper 28 */}
                <div id="movPage28" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="23" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage27', 3, '', '', '', "22")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage27', 3, '', '', '', "22")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="23" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

export default Guia2Naidi1;
