import React, { Component } from "react";
import Lista from "../Lista.jsx";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';

class ModuloList3  extends Component{

  someMethod = () => {
    this.props.handler('', false, true, '')
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={this.someMethod}><Lista courses="Módulo Introductorio" imgurl="./img/cover_page/modulo-0.png" imgalt="Módulo Introductorio"/></span>
                <span><Lista courses="Módulo 1" imgurl="./img/cover_page/modulo-1.png" imgalt="Módulo 1"/></span>
                <span><Lista courses="Módulo 2" imgurl="./img/cover_page/modulo-2.png" imgalt="Módulo 2"/></span>
                {/* <span><Lista courses="Módulo 3" imgurl="./img/cover_page/modulo-3.png" imgalt="Módulo 3"/></span>
                <span><Lista courses="Módulo 4" imgurl="./img/cover_page/modulo-4.png" imgalt="Módulo 4"/></span>
                <span><Lista courses="Módulo 5" imgurl="./img/cover_page/modulo-5.png" imgalt="Módulo 5"/></span> */}
              </div>
            </div>
          </div>
        );
    }
}

export default ModuloList3;
