import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/frontBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCaretRight,faPencilRuler, faImages, faFileDownload, faLink} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

class Guia2Md2  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);

        var elmModal = document.querySelectorAll('.modal');
        var instModal = M.Modal;
        instModal.init(elmModal);
    }

    flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo = document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.add('movFlipEfectNext');

        if (!elemenVideo){
            /* null */
        }else{
            let vpause= document.querySelectorAll('video')[indexVideo];
            vpause.pause();
        }

        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
        }else{
            setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                setTimeout(()=> (e.classList.add('eVisibility')), 800);
            }
        });

        let aUrl ='./media/paperflip.mp3';
        let audioUrl = new Audio(aUrl);
        audioUrl.play();

    }

    flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.remove('movFlipEfectNext');
        page.style.transform ='translateX(0%)';

        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
        }else{
            setTimeout(() => (page.style.zIndex = index), 500);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                e.classList.remove('eVisibility');
            }
        });

        let aUrl ='./media/paperflip.mp3';
        let audioUrl = new Audio(aUrl);
        audioUrl.play();
    }

    pausarVideo(idvideo){
      let vpause= document.getElementById(idvideo);
      vpause.remove();
    }

    obIframe(vUrl, mVideo){
      let obModal = document.getElementById(mVideo);
      let obIfram = document.createElement('iframe');
      obIfram.id ='vdo';
      obIfram.width ='450';
      obIfram.height ='250';
      obIfram.title ='Roles en Front-End';
      obIfram.src = vUrl;
      obIfram.frameBorder = '0';
      obIfram.allowFullScreen = 'true';
      obModal.appendChild(obIfram);
    }

    render(){
      return(
        <div className="flip-container">
          {/* paper 1 */}
          <div id="movPage1" className="page">
            <div className="front">
              <div className="front__content background-portada6">
                <div className="goBack"></div>
                <div className="front__content-text">
                  <div style={{padding:'10px 0px 10px 10px', margin:0}}>
                    <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-2-Estilos-y-Diseño-con-CSS/Guía-2-Manejo-de-Texto-con-CSS/Guía 2- Manejo de Texto con CSS.pdf" download="Guía 2- Manejo de Texto con CSS" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFileDownload} style={{color:'#2D6CB0'}}/>
                    </a>
                  </div>
                  <div className="front__portada">
                    {/* --*/}
                  </div>
                </div>
                <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 2 */}
          <div id="movPage2" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{width:"100%", display:'flex', alignItems:'center', color:'#06386B'}}>
                      <img style={{maxWidth:"30%", height:'auto'}} src="./img/front-end-pacifico.png" alt=""/>
                      <div>
                        <h4 style={{color:'#06386B', textAlign:'center'}}><b>Módulo II</b></h4>
                        <h5 style={{color:'#2D6CB0', textAlign:'center'}}><b>Estilos y Diseños con CSS</b></h5>
                      </div>
                    </div>
                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4><b><span style={{color:'#06386B'}}>Guía II:</span><span style={{color:'#2D6CB0'}}> Manejo de Texto con CSS</span></b></h4></div>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Objetivos</b></h5></div>
                    <div className="container" style={{overflowY:'auto', height:'50%', width:'100%'}}>
                      <ul className="collection" style={{opacity:'0.8', margin:0}}>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Analizar los fundamentos del estilo de texto, incluida la configuración de la fuente, la negrita, la cursiva, el espaciado entre líneas y letras, las sombras paralelas y otras características del texto. <a href="./material/front-end-pacifico/guia1/Introducción al Frontend.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Comprender como manejar el desbordamiento cuando hay demasiado contenido en una caja, como también conocer los diferentes tipos de valores y unidades que admiten las propiedades CSS. <a href="./material/front-end-pacifico/guia1/La Web.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 3 */}
          <div id="movPage3" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 1: Estilos CSS para Texto</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        En esta actividad practicaremos con las tablas html para enmarcar los estilos CSS utilizados en texto y describir el resultado de su aplicación.
                      </p>
                      <p className="light grey-text">
                        A partir de esto podremos reconocer como mayor facilidad los estilos que necesitaremos para nuestros proyectos.
                      </p>
                      <p className="light grey-text">
                        Utilizaremos el procesador de texto para estructurar una página básica, que contenga un &lt;header&gt; y luego de este la tabla con el contenido solicitado.
                      </p>
                      <p className="light grey-text" style={{paddingTop:'10px'}}>
                          <span style={{fontWeight:'bold', color:'#2D6CB0'}}><b>Requisitos:</b></span>
                      </p>
                      <ul style={{opacity:'0.8', margin:0}}>
                        <li className="light" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Aplicar la semántica ya trabajada en HTML y CSS.</p></li>
                        <li className="light" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>El &lt;header&gt; debe contener un logo simple y un título con la información solicitada.</p></li>
                        <li className="light" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Hacer que el &lt;header&gt; solo tome el 30% de su contenedor</p></li>
                      </ul>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px'}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Estilos CSS Utilizados en Texto</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/32.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 4 */}
          <div id="movPage4" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'5px'}}>
                      <div className="container" style={{height:'auto', width:'100%'}}>
                        <table className="highlight" style={{marginBottom:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Estilos CSS para el texto</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/guia1/La Web.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Despliega el formulario de envíos y adjunta los resultados obtenidos en la actividad.
                        </p>
                        <table className="highlight">
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de envíos</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-1" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 2: Aplicando propiedades</b></h5></div>
                    <p className="light grey-text" style={{paddingTop:'10px'}}>
                      Utilizaremos el procesador de texto para estructurar una página básica, que contenga texto e imágenes. En los elementos de texto aplicaremos, las propiedades CSS que estudiamos en la actividad anterior con la idea de observar su efecto en una página real.
                    </p>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%'}}>
                      <table className="highlight" style={{marginBottom:'30px'}}>
                        <tbody>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Dimensionar elementos en CSS</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/guia1/La Web.pdf" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*form*/}
            <div id="form-1" className="modal">
              <div className="modal-content">
                <h4><b>Actividad-1</b></h4>
                <div id="">
                  <form className="" id="actividad1" name="actividad1" encType="multipart/form-data">
                    <input type="text" name="cod1" id="cod1" placeholder="Código" autoComplete='off' required/>
                    <datalist id="tutor1">
                      <option value='Tutor1' label='Buenaventura'/>
                      <option value='Tutor1' label='Timbiqui'/>
                      <option value='Tutor1' label='Tumaco'/>
                    </datalist>
                    <input type="text" name="tutor1" id="tutor1" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor1'/>
                    <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                    <input type="file" accept=".pdf, .jpg, .png" multiple/>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
              </div>
            </div>
        </div>
        {/* paper 5 */}
        <div id="movPage5" className="page">
          <div className="front">
            <div className="front__content background-page1">
              <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                  <div className="container" style={{height:'auto', width:'100%'}}>
                    <div className="container" style={{height:'auto', width:'100%'}}>
                      <table className="highlight" style={{marginBottom:'10px'}}>
                        <tbody>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Desbordamiento</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/guia1/La Web.pdf" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Propiedades CSS en texto</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/33.png" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="light grey-text">
                        Envía un <span style={{fontWeight:'bold', color:'#2D6CB0'}}>ScreenShot</span>, a tu tutor con los resultados alcanzados en la actividad.
                      </p>
                      <table className="highlight">
                        <tbody>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de enviós</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-2" rel="noreferrer">
                                <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 3: Paleta de colores</b></h5></div>
                  <p className="light grey-text" style={{paddingTop:'5px'}}>
                    Una paleta de colores es un conjunto de colores que combinan bien. Las paletas de colores a menudo se usan para diseñar algo con una apariencia consistente.
                  </p>
                  <p className="light grey-text">
                    Una buena paleta de colores tiene un buen contraste, es visualmente agradable y contiene colores perceptualmente diferentes.
                  </p>
                  <div className="container" style={{height:'auto', width:'100%'}}>
                    <table className="highlight">
                      <tbody>
                        <tr>
                          <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Generador de paleta de colores</p></td>
                          <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                            <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/34.png" target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
            </div>
          </div>
          {/*form*/}
          <div id="form-2" className="modal">
            <div className="modal-content">
              <h4><b>Actividad-2</b></h4>
              <div id="">
                <form className="" id="actividad2" name="actividad2" encType="multipart/form-data">
                  <input type="text" name="cod2" id="cod2" placeholder="Código" autoComplete='off' required/>
                  <datalist id="tutor2">
                    <option value='Tutor1' label='Buenaventura'/>
                    <option value='Tutor1' label='Timbiqui'/>
                    <option value='Tutor1' label='Tumaco'/>
                  </datalist>
                  <input type="text" name="tutor2" id="tutor2" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor2'/>
                  <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                  <input type="file" accept=".pdf, .jpg, .png" multiple/>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
            </div>
          </div>
        </div>
        {/* paper 6 */}
          <div id="movPage6" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 3: Paleta de colores</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%'}}>
                      <p className="light grey-text" style={{paddingTop:'10px'}}>
                          <span style={{fontWeight:'bold', color:'#2D6CB0'}}><b>Requisitos:</b></span>
                      </p>
                      <ul style={{opacity:'0.8', margin:0}}>
                        <li className="light" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>A partir de un color de nuestra preferencia, iremos a la herramienta <span style={{fontWeight:'bold', color:'#2D6CB0'}}>Canva</span>, para generar una paleta de colores con las cuales trabajar nuestros proyectos y nuestro <span style={{fontWeight:'bold', color:'#2D6CB0'}}>branding</span>.</p></li>
                        <li className="light" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Luego de generar la paleta, crearemos un archivo CSS para almacenar nuestras clases de colores.</p></li>
                        <li className="light" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Enlazar el documento CSS a nuestro proyecto y aplicar las clases a las áreas que correspondan.</p></li>
                      </ul>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Paleta de colores.</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/guia1/Estructura de Archivos.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Generador de Paletas.</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center modal-trigger" href="https://www.canva.com/colors/" target="_blank" rel="noreferrer" >
                                  <FontAwesomeIcon icon={faLink} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p className="light grey-text">
                      Envía un <span style={{fontWeight:'bold', color:'#2D6CB0'}}>ScreenShot</span>, a tu tutor con los resultados alcanzados en la actividad.
                    </p>
                  </div>
                </div>
                <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        {/* paper 7 */}
        <div id="movPage7" className="page">
          <div className="front">
            <div className="front__content background-page1">
              <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%', width:'100%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 3: Paleta de colores</b></h5></div>
                  <div className="container" style={{height:'auto', width:'100%'}}>
                    <div className="container" style={{height:'auto', width:'100%'}}>
                      <table className="highlight" style={{width:'100%'}}>
                        <tbody>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de enviós</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'end'}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-3" rel="noreferrer">
                                <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', marginTop:'20px'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Entregables</b></h5></div>
                  <div className="container" style={{overflowY:'auto', height:'25%', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      Para completar la guia debes haber enviado a tu tutor las diferentes actividades propuestas, a continuación un resumen de estas:
                    </p>
                    <ol style={{marginTop:'10px'}}>
                      <li><p>1.Actividad 1: Estilos CSS para texto.</p></li>
                      <li><p>2.Actividad 2: Aplicando propiedades.</p></li>
                      <li><p>3.Actividad 3: Crear paleta de coleres para nuestro proyecto.</p></li>
                      <li><p>4.Avance del Proyecto.</p></li>
                    </ol>
                  </div>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', marginTop:'20px'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Bibliografía</b></h5></div>
                  <div className="container" style={{overflowY:'auto', height:'25%', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      MDN Web Docs. (2022). Aprende sobre desarrollo web: MDN contributors. Recuperado de <a href="https://developer.mozilla.org/es/docs/Learn"> https://developer.mozilla.org/es/docs/Learn </a>
                    </p>
                  </div>
                </div>
              </div>
              <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
            </div>
          </div>
          {/*form*/}
          <div id="form-3" className="modal">
            <div className="modal-content">
              <h4><b>Actividad-3</b></h4>
              <div id="">
                <form className="" id="actividad3" name="actividad3" encType="multipart/form-data">
                  <input type="text" name="cod3" id="cod3" placeholder="Código" autoComplete='off' required/>
                  <datalist id="tutor3">
                    <option value='Tutor1' label='Buenaventura'/>
                    <option value='Tutor1' label='Timbiqui'/>
                    <option value='Tutor1' label='Tumaco'/>
                  </datalist>
                  <input type="text" name="tutor3" id="tutor3" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor3'/>
                  <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                  <input type="file" accept=".pdf, .jpg, .png" multiple/>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
            </div>
          </div>
        </div>
        {/* paper 8 */}
        <div id="movPage8" className="page">
          <div className="front" >
            <div className="front__content background-page2">
              <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  {/*fin*/}
                </div>
              </div>
              <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                {/* <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Guia2Md2;
