import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import nguia10 from "../../svg/portadas-naidi/naidi-1-3.svg";
import nguia11 from "../../svg/portadas-naidi/naidi-2-3.svg";

class GuiaNaidi3  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'nguia10')}><Lista courses="Guía I" imgurl={nguia10} imgalt="ESTRUCTURAS DE CONTROL"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia11')}><Lista courses="Guía II" imgurl={nguia11} imgalt="CICLOS"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaNaidi3;
