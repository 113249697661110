import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia11 */
class Guia1Naidi3  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
         {/* paper 1 */}
         <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 3</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Conceptos basicos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p> 
                                          Estimados lectores, ya aprendieron a crear variables y a hacer 
                                          un buen algoritmo funcional, también han construido 
                                          circuitos excelentes y los han programado a la perfección.
                                          </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}>
                                          En esta guía conocerán el ABC de la programación, por eso es 
                                          muy importante que interioricen qué es cada cosa para que a 
                                          la hora de pensar un algoritmo no tengan ningún problema 
                                          en sacarlo de tu cabeza.
                                          </p>
                                            
                                                          
                                            </div>

                                            </div>
                                              
                                     

                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       Les mentiría si les dijera que muy 
                                       rápidamente vas a ser capaces de hablar algorítmicamente, 
                                       pero creemos que si siguen el camino adecuado, convertirán 
                                       todas estas estructuras sin sentido en arte excepcional.
                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                    
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Control y bucles: Condicionales</h4> <h4 style={{ fontWeight:'bold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de dominar el concepto de 
                                    condicional y aplicarlo a la programación de diferentes circuitos electrónicos. De igual manera 
                                    aprenderán a hacer un circuito de resistencias Pull Up y Pull Down.

                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Aprender conceptos específicos sobre condicional Si.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Aprender el concepto de Pull Up y Pull Down.

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Construir prototipo de dado electrónico con arduino. 
                                     </p>


                        
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                    
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: ¿Qué es un condicional?</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Este tipo de estructura, en lugar de seguir un orden específico de eventos, realiza una pregunta para 
                                    determinar el camino a seguir.

                                   </p>

                                   <p style={{ padding:'5px 0px 0px 0px'}}>  
                                   Para entender mejor este concepto, veamos el siguiente caso: digamos que vas a cepillarte los 
                                   dientes y notas que se acabó la crema dental. Ante este hecho te preguntarías: "¿tengo más crema 
                                   dental?
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/crema1.png" alt="" />

                                     </div>

                                     <p>
                                     Si la respuesta es negativa, agregarías este artículo a tu lista de mercado.
                                      Pero, si la respuesta es afirmativa, simplemente la usarías.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     Esta es la función básica de los condicionales: responder preguntas a partir de lo que descubren. 
                                     <span style={{color:'red'}}>Amplía información aquí</span>
                                     <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://edu.gcfglobal.org/es/informatica-avanzada/secuencias-condicionales-y-ciclos/1/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                     </p>

                         

                        
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>

                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p>
                                    Por lo tanto, siempre vamos a tener dos caminos a escoger, según el cumplimiento o no de una condición.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    En programación los tipos más conocidos de sentencias condicionales son el SI..ENTONCES y el 
                                    SI..ENTONCES..SI NO,.éste último, nos sirve para tener más opciones para escoger, en donde si la 
                                    condición no es verdadera en la primera instancia, seguirá preguntando en otras instancias hasta 
                                    encontrar un verdadero o simplemente no lo haya.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Las sentencias condicionales constituyen, junto con los bucles, los pilares de la programación 
                                    estructurada, y su uso es una evolución de una sentencia en lenguaje ensamblador que ejecutaba la 
                                    siguiente línea o no en función del valor de una condición. 
                                   <span style={{color:'red'}}>Amplía información aquí</span> 
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://es.wikipedia.org/wiki/Sentencia_condicional#:~:text=En%20programaci%C3%B3n%2C%20una%20sentencia%20condicional,then)%2C%20el%20SI..">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/bucle1.png" alt="" />

                                      </div>
                      
                                 
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>


                                  <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 10px 0px 0px'}}>
  
                                  <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                    </div>

                                <div className="container" style={{width:'95%'}}>

                                    
                      <p> Para que entiendan con más facilidad los conceptos anteriores reproduce el 
                        siguiente video<span style={{color:'red'}}>: Estructuras de programación (qué es secuencia, condicional,ciclo) | Computación y programación</span>
                     <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=rNY5eWogl18">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                      </p>
        
                      </div>

                      </div>

                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> Práctica mBlock</h5></div>

                      <p>
                      Conociendo los conceptos de condicionales y entendiendo los ejemplos es hora 
                      de programar en nuestra plataforma mBlock.

                      </p>

                      <p>
                      Antes que nada debemos conocer los bloques con los que vamos a trabajar, los 
                      cuales los encontraremos en el grupo de bloques control y es el siguiente:
                      </p>

                      <div style={{padding:'10px 0px 0px 0px'}}>

<img style={{width:'40%', display:'block', margin:'auto'}} src="./img/bucle2.png" alt="" />

                </div>

                <p>
                El bloque SI, lo usamos para hacer la pregunta que en teoría ya conocemos, el cual tiene la opción 
                de agregar el criterio a evaluar y de ser verdadero, entonces ejecutará el grupo de bloque que 
                agreguemos en el interior de él.
                </p>


                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                               
                               <p>
                               Cuando tenemos el caso de que al evaluar podríamos tener que ejecutar un grupo de bloque para 
                               el evento que sea verdadero o falso el realizar la evaluación del criterio, entonces usaremos el 
                               siguiente bloque:
                               </p>

                               <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/bucle3.png" alt="" />

                                               </div>

                                               <p>
                                               En caso de de ser falsa la afirmación o el criterio a evaluar, y nos veamos obligados a seguir evaluando 
                                               otros criterios, podemos usar condicionales anidados de la siguiente manera:
                                               </p>

                                               <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/bucle4.png" alt="" />

                                                        </div> 

                                         

                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  y si es necesario seguir una cadena de varias validaciones podemos seguir la siguiente estructura:
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/bucle5.png" alt="" />

                                             </div> 


                                             <div style={{display:'flex', justifyContent:'space-between',padding:'20px 0px 10px 0px'}} >
  
                                                      <div style={{padding:'0px 10px 0px 0px'}}>

                                          <img style={{width:'100%'}} src="./img/valle4.png" alt=""/> 

                                                        </div>

                                        <div className="container" style={{width:'95%'}}>


                                <p> Para que entiendan con más facilidad  los conceptos anteriores reproduce el 
                        siguiente video :<span style={{color:'red'}}>  Condicionales con mBlock</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=mWnZWcolol4">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                                  </p>

                                    </div>

                                           </div>

                                             
                                     
                                    
                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Lectura de un pulsador</h5></div>

                                  <h5>Leer un pulsador con arduino</h5>


                                  <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino11.png" alt="" />

                                          </div> 

                                          <p style={{padding:'5px 0px 0px 0px'}}>
                                          En el tutorial entradas digitales en Arduino vimos como leer un sensor que proporcione una señal digital con dos niveles de tensión LOW y HIGH. 
                                          Nos quedó pendiente aprovechar las entradas digitales para leer el estado de un interruptor o pulsador con Arduino.
                                          </p>

                                          <p style={{padding:'5px 0px 0px 0px'}}>
                                          Para ello necesitaremos realizar correctamente un montaje específico y la ayuda de dos nuevas 
                                          amigas las resistencias de Pull Down y Pull Up. Aunque ambos casos son muy similares, el 
                                          montaje y el tipo de resistencia a usar dependerá de si queremos que al accionar el pulsador o 
                                          interruptor leamos un valor LOW o HIGH.
                                          </p>

                                                               
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                          las resistencias de Pull Down y Pull Up son un mecanismo básico, muy habitual dentro del 
                                          mundo de la electrónica y automatización.
                                          </p>

                                          <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/arduino12.png" alt="" />

                                                       </div> 

                                                       <p>
                                                       Las resistencias de Pull-Down y Pull-Up se conectan entre el PIN digital y una de las tensiones de 
                                                       referencia (0V o 5V) y "fuerzan" (de ahí su nombre) el valor de la tensión a LOW o HIGH, respectivamente
                                                       </p>

                                                       <p style={{padding:'5px 0px 0px 0px'}}>
                                                       ● La resistencia de Pull-Up fuerza HIGH cuando el pulsador está abierto. Cuando está 
                                                       cerrado el PIN se pone a LOW, la intensidad que circula se ve limitada por esta resistencia
                                                       </p>



                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                              <p>
                              ● La resistencia de Pull-Down fuerza LOW cuando el pulsador está abierto. Cuando está 
                              cerrado el PIN se pone a HIGH, y la intensidad que circula se ve limitada por esta resistencia 
                              Así es como quedaría el montaje final en vista esquemática (la conexión de puede realizar en 
                              empleando cualquiera de los PIN digital).
                              </p>

                              
                              <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/arduino13.png" alt="" />

                                            </div> 

                                            <p>
                                            Y así el cableado en una placa de prototipos. <span style={{color:'red'}}>Amplía información aquí</span>
                                            <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.luisllamas.es/leer-un-pulsador-con-arduino/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                            </p>


                                            <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/arduino14.png" alt="" />

                                             </div> 

                                

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>

                                  <p> 
                                  ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  En la siguiente actividad construiremos un dado electrónico, la 
                                  cual consiste en generar número al azar con programación en 
                                  arduino, un pulsador y 6 diodos LED. 
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  El objetivo principal es que al presionar en el pulsador, se genere 
                                  un número del uno al 6 y se pueda encender la cantidad de 
                                  diodos LED, según el número que corresponda.
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  El formador de su territorio, les suministrará los siguientes 
                                  componentes:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 Placa Arduino
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 placa de prototipos (Protoboard)
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 6 Diodos LED de diferentes colores
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 6 Resistencias de 220 Ω
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 Resistencia de 10 kΩ

                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 15 Cables Jumpers M-M

                                  </p>

                                 

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    <p>
                                    El esquema de circuito que van a construir es el siguiente:
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'75%', display:'block', margin:'auto'}} src="./img/arduino15.png" alt="" />

                                             </div> 

                                             <p>
                                             A continuación les mostraré solo una parte del algoritmo, para 
                                             que el reto de ésta actividad consista en que lo puedan completar.
                                             </p>


                                             <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                                 </div> 

                                           



                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{padding:'5px 0px 0px 0px'}}>

                      <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino16.png" alt="" />

                                                     </div> 

                                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                                     Para ver todo el proceso de ensamble y programación paso a paso en video, Presiona clic en el siguiente enlace: 
                                                     <span style={{color:'red'}}> Dado electrónico con mBlock</span>
                                                     <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=6Y3Jy_3c0E0">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.1rem', color:'red'}}/> </a></span>
                                                     </p>

                                     
                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Video y archivo.mblock de cada grupo con el reto completado. 
                                  </p>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● Informática Avanzada: Secuencias, condicionales y ciclos. (s. f.). GCFGlobal.org. 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://edu.gcfglobal.org/es/informatica-avanzada/secuencias-condicionales-y-ciclos/1/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● colaboradores de Wikipedia. (2022, 4 febrero). Sentencia condicional. Wikipedia, la enciclopedia.
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://es.wikipedia.org/wiki/Sentencia_condicional#:%7E:text=En%20programaci%C3%B3n%2C%20una%20sentencia%20condicional,then">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● Leer un pulsador con Arduino. (2017, 30 octubre). Luis Llamas.
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.luisllamas.es/leer-un-pulsador-con-arduino">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                  </p>                    

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>




                {/* paper 17 */}
                <div id="movPage17" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia1Naidi3;
