import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/frontBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCaretRight, faPencilRuler, faImages, faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

class Guia3Md3  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);

        var elmModal = document.querySelectorAll('.modal');
        var instModal = M.Modal;
        instModal.init(elmModal);
    }

    flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo = document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.add('movFlipEfectNext');

        if (!elemenVideo){
            /* null */
        }else{
            let vpause= document.querySelectorAll('video')[indexVideo];
            vpause.pause();
        }

        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
        }else{
            setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                setTimeout(()=> (e.classList.add('eVisibility')), 800);
            }
        });

        let aUrl ='./media/paperflip.mp3';
        let audioUrl = new Audio(aUrl);
        audioUrl.play();

    }

    flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.remove('movFlipEfectNext');
        page.style.transform ='translateX(0%)';

        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
        }else{
            setTimeout(() => (page.style.zIndex = index), 500);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                e.classList.remove('eVisibility');
            }
        });

        let aUrl ='./media/paperflip.mp3';
        let audioUrl = new Audio(aUrl);
        audioUrl.play();
    }

    pausarVideo(idvideo){
      let vpause= document.getElementById(idvideo);
      vpause.remove();
    }

    obIframe(vUrl, mVideo){
      let obModal = document.getElementById(mVideo);
      let obIfram = document.createElement('iframe');
      obIfram.id ='vdo';
      obIfram.width ='450';
      obIfram.height ='250';
      obIfram.title ='Roles en Front-End';
      obIfram.src = vUrl;
      obIfram.frameBorder = '0';
      obIfram.allowFullScreen = 'true';
      obModal.appendChild(obIfram);
    }

    render(){
      return(
        <div className="flip-container">
          {/* paper 1 */}
          <div id="movPage1" className="page">
            <div className="front">
              <div className="front__content background-portada11">
                <div className="goBack"></div>
                <div className="front__content-text">
                  <div style={{padding:'10px 0px 10px 10px', margin:0}}>
                    <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Guía-1-Begining-whit-HTML.pdf" download="Guía-1-Begining-whit-HTML" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFileDownload} style={{color:'#06386B'}}/>
                    </a>
                  </div>
                  <div className="front__portada">
                    {/* --*/}
                  </div>
                </div>
                <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 2 */}
          <div id="movPage2" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{width:"100%", display:'flex', alignItems:'center', color:'#06386B'}}>
                      <img style={{maxWidth:"30%", height:'auto'}} src="./img/front-end-pacifico.png" alt=""/>
                      <div>
                        <h4 style={{color:'#06386B', textAlign:'center'}}><b>Módulo I</b></h4>
                        <h5 style={{color:'#2D6CB0', textAlign:'center'}}><b>Semántica y estructura HTML5</b></h5>
                      </div>
                    </div>
                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4><b><span style={{color:'#06386B'}}>Guía I:</span><span style={{color:'#2D6CB0'}}>Begining whit &#60;HTML&#62;</span></b></h4></div>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Objetivos</b></h5></div>
                    <div className="container" style={{height:'50%', width:'100%'}}>
                      <ul className="collection" style={{opacity:'0.8', margin:0}}>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Conocer los elementos <span style={{fontWeight:'bold', color:'#2D6CB0'}}>HTML</span> y la sintaxis de estos. <a href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/El Lenguaje de Marcado de Hipertexto.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Comprender la estructura global utilizada como estándar en la construcción HTML. <a href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/Estructura Global HTML.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Aprender la semántica del <span style={{fontWeight:'bold', color:'#2D6CB0'}}>&#60;body&#62;</span> en la construcción de una página web. <a href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/Semántica y Estructura del Body.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Estudiar los elementos <span style={{fontWeight:'bold', color:'#2D6CB0'}}>block e inline</span>, para su correcta manipulación. <a href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/Elementos Block e Inline.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 3 */}
          <div id="movPage3" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 1: Elementos HTML</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Según la <span style={{fontWeight:'bold', color:'#2D6CB0'}}>World Wide Web Consortium (W3C)</span>, un elemento <span style={{fontWeight:'bold', color:'#2D6CB0'}}>HTML</span> está formado por pares de etiquetas <span style={{fontWeight:'bold', color:'#2D6CB0'}}>(tag)</span>, una etiqueta de inicio y una de cierre, delimitadas por caracteres especiales: <span style={{fontWeight:'bold', color:'#2D6CB0'}}>&#60;, &#62;, &#47;</span> 
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px'}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>El Lenguaje de Marcado de Hipertexto (HTML)</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico//Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/El Lenguaje de Marcado de Hipertexto.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Descomposición de elementos HTML</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./img/2.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Identificación de atributos en un elemento HTML</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./img/1.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Describe la composición de los elementos a continuación:
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de preguntas</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-1" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*form*/}
            <div id="form-1" className="modal">
              <div className="modal-content">
                <h4><b>Actividad-1</b></h4>
                <div id="">
                  <form className="" id="actividad1" name="actividad1" encType="multipart/form-data">
                    <input type="text" name="cod1" id="cod1" placeholder="Código" autoComplete='off' required/>
                    <datalist id="tutor">
                      <option value='Tutor1' label='Buenaventura'/>
                      <option value='Tutor1' label='Timbiqui'/>
                      <option value='Tutor1' label='Tumaco'/>
                    </datalist>
                    <input type="text" name="tut1" id="tut1" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor'/>
                    <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Preguntas</b></h5>
                    <label htmlFor="pregunta1"><b>Elemento: &#60;p&#62;Me gusta ser autodidacta&#60;&#47;p&#62;</b></label>
                    <textarea id="res1" name="res1" className="materialize-textarea" placeholder="Describe el elemento"></textarea>
                    <label htmlFor="pregunta2"><b>Elemento: &#60;h1 class="titulo"&#62;El Front-End es una Especialidad &#60;&#47;h1&#62;</b></label>
                    <textarea id="res2" name="res2" className="materialize-textarea" placeholder="Describe el elemento"></textarea>
                    <label htmlFor="pregunta3"><b>Elemento: &#60;a href="https://html.spec.whatwg.org/" class="btn" &#62;Estándar HTML&#60;&#47;a&#62;</b></label>
                    <textarea id="res3" name="res3" className="materialize-textarea" placeholder="Describe el elemento"></textarea>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
              </div>
            </div>
          </div>
          {/* paper 4 */}
          <div id="movPage4" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 2: Estructura Global</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Los documentos HTML se encuentran estrictamente organizados. Cada parte del documento está diferenciada, declarada y determinada por etiquetas específicas.
                      </p>
                      <p className="light grey-text">
                        A estas etiquetas se les conoce como elementos semánticos, lo que permite darle significado a nuestro contenido, a de más de cumplir con el estándar estructural, demandado por la industria web.
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Estructura Global HTML</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/Estructura Global HTML.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Despliega el formulario de preguntas y selecciona tus respuestas.
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de preguntas</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-2" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*form*/}
            <div id="form-2" className="modal">
              <div className="modal-content">
              <h4><b>Actividad-2</b></h4>
              <div id="">
                <form className="" id="actividad2" name="actividad2" encType="multipart/form-data">
                  <input type="text" name="cod2" id="cod2" placeholder="Código" autoComplete='off' required/>
                  <datalist id="tutor">
                    <option value='Tutor1' label='Buenaventura'/>
                    <option value='Tutor1' label='Timbiqui'/>
                    <option value='Tutor1' label='Tumaco'/>
                  </datalist>
                  <input type="text" name="tut2" id="tut2" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor'/>
                  <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Preguntas</b></h5>
                  <label htmlFor="pregunta5"><b>¿Cómo es representado el elemento DTD de un documento HTML?</b></label>
                  <datalist id="pregunta5">
                    <option value='Opción 1' label='<html></html>'/>
                    <option value='Opción 2' label='<body></body>'/>
                    <option value='Opción 3' label='<!DOCTYPE xml>'/>
                    <option value='Opción 4' label='<!DOCTYPE html>'/>
                  </datalist>
                  <input type="text" name="res5" id="res5" placeholder="Seleccione" autoComplete='off' required list='pregunta5'/>
                  <label htmlFor="pregunta6"><b>¿Selecciona la etiqueta root de un documento HTML?</b></label>
                  <datalist id="pregunta6">
                    <option value='Opción 1' label='lang'/>
                    <option value='Opción 2' label='<title></title>'/>
                    <option value='Opción 3' label='<html></html>'/>
                    <option value='Opción 4' label='<head></head>'/>
                  </datalist>
                  <input type="text" name="res6" id="res6" placeholder="Seleccione" autoComplete='off' required list='pregunta6'/>
                  <label htmlFor="pregunta7"><b>¿Qué etiqueta se utiliza para contener los metadatos de un documento?</b></label>
                  <datalist id="pregunta7">
                    <option value='Opción 1' label='<head></head>'/>
                    <option value='Opción 2' label='<meta/>'/>
                    <option value='Opción 3' label='<script></script>'/>
                    <option value='Opción 4' label='<link></link>'/>
                  </datalist>
                  <input type="text" name="res7" id="res7" placeholder="Seleccione" autoComplete='off' required list='pregunta7'/>
                  <label htmlFor="pregunta8"><b>¿Seleccione de la lista el elemento que representa el cuerpo del documento?</b></label>
                  <datalist id="pregunta8">
                    <option value='Opción 1' label='<noscript></noscript>'/>
                    <option value='Opción 2' label='<base/>'/>
                    <option value='Opción 3' label='<style></style>'/>
                    <option value='Opción 4' label='<body></body>'/>
                  </datalist>
                  <input type="text" name="res8" id="res8" placeholder="Seleccione" autoComplete='off' required list='pregunta8'/>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
            </div>
          </div>
        </div>
        {/* paper 5 */}
        <div id="movPage5" className="page">
          <div className="front">
            <div className="front__content background-page1">
              <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 3: Semántica del &#60;body&#62;</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        La semántica no propone nuevas funcionalidades, pero si establece estructura y significado concreto a nuestro sitio web. <cite>“Un elemento semántico describe claramente su significado tanto para el navegador web como para el desarrollador”</cite>
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Semántica y Estructura del body</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/Semántica y Estructura del Body.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Semántica y Estructura del body</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/g163149.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Señala en tu boceto, la estructura semántica de tu sitio web identificando en primer lugar tu &#60;body&#62; y envíalo a tu tutor.
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de enviós</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-3" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*form*/}
            <div id="form-3" className="modal">
              <div className="modal-content">
                <h4><b>Actividad-3</b></h4>
                <div id="">
                  <form className="" id="actividad3" name="actividad3" encType="multipart/form-data">
                    <input type="text" name="cod3" id="cod3" placeholder="Código" autoComplete='off' required/>
                    <datalist id="tutor">
                      <option value='Tutor1' label='Buenaventura'/>
                      <option value='Tutor1' label='Timbiqui'/>
                      <option value='Tutor1' label='Tumaco'/>
                    </datalist>
                    <input type="text" name="tutor" id="tutor" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor'/>
                    <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                    <input type="file" accept=".pdf, .jpg, .png" multiple/>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
              </div>
            </div>
          </div>
          {/* paper 6 */}
          <div id="movPage6" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 4: Elementos Block e Inline</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Un elemento en bloque ocupa todo el espacio de su elemento padre (contenedor), creando así un "bloque", y Un elemento en línea ocupa sólo el espacio delimitado por las etiquetas que definen el elemento en línea.
                      </p>
                      <p className="light grey-text">
                        Entender estos dos conceptos nos evitara el colapso de elementos dentro de la pagina y una visulaización no esperada.
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Elementos Block e Inline</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-1-Semántica-y-Estructura-con-HTML/Guía-1-Begining-whit-HTML/Material/Elementos Block e Inline.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Elementos Block e Inline en un boceto</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/g56340.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Identifica cuales son los elementos block e inline en la imagen junto al formulario y en tu boceto señala y coloca el tag name que corresponde a cada elemento, al terminar envialo a tu tutor.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        {/* {/* paper 7 */}
        <div id="movPage7" className="page">
          <div className="front">
            <div className="front__content background-page1">
              <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 4: Elementos Block e Inline</b></h5></div>
                  <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Envía un <span style={{fontWeight:'bold', color:'#2D6CB0'}}>ScreenShot</span>, o un PDF con la información solicitada.
                      </p>
                      <table className="highlight" style={{marginTop:'10px'}}>
                        <tbody>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de envios</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-4" rel="noreferrer">
                                <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Identifica los elementos Block e Inline</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/11.png" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', marginTop:'20px'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Entregables</b></h5></div>
                  <div className="container" style={{overflowY:'auto', height:'25%', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      Para completar la guia debes haber enviado a tu tutor las diferentes actividades propuestas, a continuación un resumen de estas:
                    </p>
                    <ol style={{margin:'10px 0px 0px 20px'}}>
                      <li>Estructura Global.</li>
                      <li>Semántica del &#60;body&#62;.</li>
                      <li>Elementos Block y Inline.</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*form*/}
        <div id="form-4" className="modal">
          <div className="modal-content">
            <h4><b>Actividad-4</b></h4>
            <div id="">
              <form className="" id="actividad4" name="actividad4" encType="multipart/form-data">
                <input type="text" name="cod4" id="cod4" placeholder="Código" autoComplete='off' required/>
                <datalist id="tutor4">
                  <option value='Tutor1' label='Buenaventura'/>
                  <option value='Tutor1' label='Timbiqui'/>
                  <option value='Tutor1' label='Tumaco'/>
                </datalist>
                <input type="text" name="tutor4" id="tutor4" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor4'/>
                <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                <input type="file" accept=".pdf, .jpg, .png" multiple/>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
            <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
          </div>
        </div>
        {/* paper 8 */}
        <div id="movPage8" className="page">
          <div className="front" >
            <div className="front__content background-page1">
              <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%', width:'100%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Bibliografía</b></h5></div>
                  <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        MDN Web Docs. (2022). Aprende sobre desarrollo web: MDN contributors. Recuperado de <a href="https://developer.mozilla.org/es/docs/Learn"> https://developer.mozilla.org/es/docs/Learn.</a>
                      </p>
                      <p className="light grey-text" style={{padding:'10px 0px 10px 0px'}}>
                        HTML/Training/Tag syntax. Recuperado de <a href="https://www.w3.org/community/webed/wiki/HTML/Training/Tag_syntax"> https://www.w3.org</a>
                      </p>
                      <p className="light grey-text">
                        Semántica HTML5. Recuperado de <a href="https://www.exasoluciones.com/inicio/tutoriales/html/semantica-html5"> https://www.exasoluciones.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* paper 9 */}
        <div id="movPage9" className="page">
          <div className="front">
            <div className="front__content background-page2">
              <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  {/*fin*/}
                </div>
              </div>
              <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                {/*<a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Guia3Md3;
