import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import fguia0 from "../../svg/portadas-front-end/guia-1-0.svg";

class GuiaList  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

  render(){
    return(
      <div className="App-book">
        <div className="breadcrbs">
          <h5>Inicio</h5>
          <FontAwesomeIcon icon={faAngleRight}/>
          <h5>Módulos</h5>
          <FontAwesomeIcon icon={faAngleRight}/>
          <h5>Guías</h5>
        </div>
        <div className="modulos">
          <div className="Lista-modulos">
            <span onClick={(e)=>this.someMethod('', '', false,'fguia0')}><Lista courses="Guía I" imgurl={fguia0} imgalt="El ¿Qué? y el ¿Cómo?"/></span>
          </div>
        </div>
      </div>
    );
  }
}

export default GuiaList;
