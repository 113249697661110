import React, { Component } from "react";
/* components */
/* Frontend */
import Guia1Md0 from './frontend/Guia1Md0.jsx';
/* -- */
import Guia1Md1 from './frontend/Guia1Md1.jsx';
import Guia2Md1 from './frontend/Guia2Md1.jsx';
import Guia3Md1 from './frontend/Guia3Md1.jsx';
import Guia4Md1 from './frontend/Guia4Md1.jsx';
/* -- */
import Guia1Md2 from './frontend/Guia1Md2.jsx';
import Guia2Md2 from './frontend/Guia2Md2.jsx';
import Guia3Md2 from './frontend/Guia3Md2.jsx';
import Guia4Md2 from './frontend/Guia4Md2.jsx';
/* -- */
import Guia1Md3 from './frontend/Guia1Md3.jsx';
import Guia2Md3 from './frontend/Guia2Md3.jsx';
import Guia3Md3 from './frontend/Guia3Md3.jsx';
import Guia4Md3 from './frontend/Guia4Md3.jsx';
import Guia5Md3 from './frontend/Guia5Md3.jsx';
import Guia6Md3 from './frontend/Guia6Md3.jsx';
/* -- */
import Guia1Md4 from './frontend/Guia1Md4.jsx';
import Guia2Md4 from './frontend/Guia2Md4.jsx';
import Guia3Md4 from './frontend/Guia3Md4.jsx';
import Guia4Md4 from './frontend/Guia4Md4.jsx';
import Guia1Md5 from './frontend/Guia1Md5.jsx';

/* naidi */
import Guia1Naidi1 from './naidi/Guia1Naidi1.jsx';
import Guia2Naidi1 from './naidi/Guia2Naidi1.jsx';
import Guia3Naidi1 from './naidi/Guia3Naidi1.jsx';
import Guia4Naidi1 from './naidi/Guia4Naidi1.jsx';
/* -- */
import Guia1Naidi2 from './naidi/Guia1Naidi2.jsx';
import Guia2Naidi2 from './naidi/Guia2Naidi2.jsx';
import Guia3Naidi2 from './naidi/Guia3Naidi2.jsx';
import Guia4Naidi2 from './naidi/Guia4Naidi2.jsx';
import Guia5Naidi2 from './naidi/Guia5Naidi2.jsx';
import Guia6Naidi2 from './naidi/Guia6Naidi2.jsx';
/* -- */
import Guia1Naidi3 from './naidi/Guia1Naidi3.jsx';
import Guia2Naidi3 from './naidi/Guia2Naidi3.jsx';
/* -- */
import Guia1Naidi4 from './naidi/Guia1Naidi4.jsx';
import Guia2Naidi4 from './naidi/Guia2Naidi4.jsx';
import Guia3Naidi4 from './naidi/Guia3Naidi4.jsx';
/* -- */
import Guia1Naidi5 from './naidi/Guia1Naidi5.jsx';
import Guia2Naidi5 from './naidi/Guia2Naidi5.jsx';

/* svg */
import isotipo from '../svg/isotipo.svg';

class Contents  extends Component{

    constructor(props){
        super(props)
        this.state={
            /* front-end */
            front1Md0:false,
            front1Md1:false,
            front2Md1:false,
            front3Md1:false,
            front4Md1:false,
            front1Md2:false,
            front2Md2:false,
            front3Md2:false,
            front4Md2:false,
            front1Md3:false,
            front2Md3:false,
            front3Md3:false,
            front4Md3:false,
            front5Md3:false,
            front6Md3:false,
            front1Md4:false,
            fornt2Md4:false,
            front3Md4:false,
            front4Md4:false,
            front1Md5:false,
            /* naidi */
            naidi1Md1:false,
            naidi2Md1:false,
            naidi3Md1:false,
            naidi4Md1:false,
            /* -- */
            naidi1Md2:false,
            naidi2Md2:false,
            naidi3Md2:false,
            naidi4Md2:false,
            naidi5Md2:false,
            naidi6Md2:false,
            /* -- */
            naidi1Md3:false,
            naidi2Md3:false,
            /* -- */
            naidi1Md4:false,
            naidi2Md4:false,
            naidi3Md4:false,
            /* -- */
            naidi1Md5:false,
            naidi2Md5:false,
            /* -- */
            InitVw: true,
        }
    } 

    display() {
        switch (sessionStorage.getItem('libro')){
            /* front-end */
            case 'fguia0':
                setTimeout(()=>
                    this.setState({
                        front1Md0:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia1':
                setTimeout(()=>
                    this.setState({
                        front1Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia2':
                setTimeout(()=>
                    this.setState({
                        front2Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia3':
                setTimeout(()=>
                    this.setState({
                        front3Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia4':
                setTimeout(()=>
                    this.setState({
                        front4Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia5':
                setTimeout(()=>
                    this.setState({
                        front1Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia6':
                setTimeout(()=>
                    this.setState({
                        front2Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia7':
                setTimeout(()=>
                    this.setState({
                        front3Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia8':
                setTimeout(()=>
                    this.setState({
                        front4Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia9':
                setTimeout(()=>
                    this.setState({
                        front1Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia10':
                setTimeout(()=>
                    this.setState({
                        front2Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia11':
                setTimeout(()=>
                    this.setState({
                        front3Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia12':
                setTimeout(()=>
                    this.setState({
                        front4Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia13':
                setTimeout(()=>
                    this.setState({
                        front5Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia14':
                setTimeout(()=>
                    this.setState({
                        front6Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia15':
                setTimeout(()=>
                    this.setState({
                        front1Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia16':
                setTimeout(()=>
                    this.setState({
                        front2Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia17':
                setTimeout(()=>
                    this.setState({
                        front3Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia18':
                setTimeout(()=>
                    this.setState({
                        front4Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'fguia19':
                setTimeout(()=>
                    this.setState({
                        front1Md5:true,
                        InitVw: false
                    }),
                3000);
            break;
            /* naidi */
            case 'nguia0':
                setTimeout(()=>
                    this.setState({
                        naidi1Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia1':
                setTimeout(()=>
                    this.setState({
                        naidi2Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia2':
                setTimeout(()=>
                    this.setState({
                        naidi3Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia3':
                setTimeout(()=>
                    this.setState({
                        naidi4Md1:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia4':
                setTimeout(()=>
                    this.setState({
                        naidi1Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia5':
                setTimeout(()=>
                    this.setState({
                        naidi2Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia6':
                setTimeout(()=>
                    this.setState({
                        naidi3Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia7':
                setTimeout(()=>
                    this.setState({
                        naidi4Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia8':
                setTimeout(()=>
                    this.setState({
                        naidi5Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia9':
                setTimeout(()=>
                    this.setState({
                        naidi6Md2:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia10':
                setTimeout(()=>
                    this.setState({
                        naidi1Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia11':
                setTimeout(()=>
                    this.setState({
                        naidi2Md3:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia12':
                setTimeout(()=>
                    this.setState({
                        naidi1Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia13':
                setTimeout(()=>
                    this.setState({
                        naidi2Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia14':
                setTimeout(()=>
                    this.setState({
                        naidi3Md4:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia15':
                setTimeout(()=>
                    this.setState({
                        naidi1Md5:true,
                        InitVw: false
                    }),
                3000);
            break;
            case 'nguia16':
                setTimeout(()=>
                    this.setState({
                        naidi2Md5:true,
                        InitVw: false
                    }),
                3000);
            break;
            default:
                this.setState({
                    /* front-end */
                    front1Md0:false,
                    front1Md1:false,
                    front2Md1:false,
                    front3Md1:false,
                    front4Md1:false,
                    front1Md2:false,
                    fornt2Md2:false,
                    front3Md2:false,
                    front4Md2:false,
                    front1Md3:false,
                    fornt2Md3:false,
                    front3Md3:false,
                    front4Md3:false,
                    front5Md3:false,
                    front6Md3:false,
                    front1Md4:false,
                    fornt2Md4:false,
                    front3Md4:false,
                    front4Md4:false,
                    front1Md5:false,
                    /* naidi */
                    naidi1Md1:false,
                    naidi2Md1:false,
                    naidi3Md1:false,
                    naidi4Md1:false,
                    /* -- */
                    naidi1Md2:false,
                    naidi2Md2:false,
                    naidi3Md2:false,
                    naidi4Md2:false,
                    naidi5Md2:false,
                    naidi6Md2:false,
                    /* -- */
                    naidi1Md3:false,
                    naidi2Md3:false,
                    /* -- */
                    naidi1Md4:false,
                    naidi2Md4:false,
                    naidi3Md4:false,
                    /* -- */
                    naidi1Md5:false,
                    naidi2Md5:false,
                    /* -- */
                    InitVw: true,
                });
            break;
        }
    }

    render(){
        return(
            <div id="page-flip" onLoad={(e) => this.display()}>
                {
                    this.state.InitVw?
                    <div>
                        <img className="logo__init" width="130px" src={isotipo} alt="logo" />
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    </div>
                    :null
                }
                {/* libros Front-end */}
                {
                    this.state.front1Md0?
                        <Guia1Md0/>
                    :null
                }
                {
                    this.state.front1Md1?
                        <Guia1Md1/>
                    :null
                }
                {
                    this.state.front2Md1?
                        <Guia2Md1/>
                    :null
                }
                {
                    this.state.front3Md1?
                        <Guia3Md1/>
                    :null
                }
                {
                    this.state.front4Md1?
                        <Guia4Md1/>
                    :null
                }
                {
                    this.state.front1Md2?
                        <Guia1Md2/>
                    :null
                }
                {
                    this.state.front2Md2?
                        <Guia2Md2/>
                    :null
                }
                {
                    this.state.front3Md2?
                        <Guia3Md2/>
                    :null
                }
                {
                    this.state.front4Md2?
                        <Guia4Md2/>
                    :null
                }
                {
                    this.state.front1Md3?
                        <Guia1Md3/>
                    :null
                }
                {
                    this.state.front2Md3?
                        <Guia2Md3/>
                    :null
                }
                {
                    this.state.front3Md3?
                        <Guia3Md3/>
                    :null
                }
                {
                    this.state.front4Md3?
                        <Guia4Md3/>
                    :null
                }
                {
                    this.state.front5Md3?
                        <Guia5Md3/>
                    :null
                }
                {
                    this.state.front6Md3?
                        <Guia6Md3/>
                    :null
                }
                {
                    this.state.front1Md4?
                        <Guia1Md4/>
                    :null
                }
                {
                    this.state.front2Md4?
                        <Guia2Md4/>
                    :null
                }
                {
                    this.state.front3Md4?
                        <Guia3Md4/>
                    :null
                }
                {
                    this.state.front4Md4?
                        <Guia4Md4/>
                    :null
                }
                {
                    this.state.front1Md5?
                        <Guia1Md5/>
                    :null
                }
                {/* Libros naidi */}
                {
                    this.state.naidi1Md1?
                        <Guia1Naidi1/>
                    :null
                }
                {
                    this.state.naidi2Md1?
                        <Guia2Naidi1/>
                    :null
                }
                {
                    this.state.naidi3Md1?
                        <Guia3Naidi1/>
                    :null
                }
                {
                    this.state.naidi4Md1?
                        <Guia4Naidi1/>
                    :null
                }
                {
                    this.state.naidi1Md2?
                        <Guia1Naidi2/>
                    :null
                }
                {
                    this.state.naidi2Md2?
                        <Guia2Naidi2/>
                    :null
                }
                {
                    this.state.naidi3Md2?
                        <Guia3Naidi2/>
                    :null
                }
                {
                    this.state.naidi4Md2?
                        <Guia4Naidi2/>
                    :null
                }
                {
                    this.state.naidi5Md2?
                        <Guia5Naidi2/>
                    :null
                }
                {
                    this.state.naidi6Md2?
                        <Guia6Naidi2/>
                    :null
                }
                {
                    this.state.naidi1Md3?
                        <Guia1Naidi3/>
                    :null
                }
                {
                    this.state.naidi2Md3?
                        <Guia2Naidi3/>
                    :null
                }
                {
                    this.state.naidi1Md4?
                        <Guia1Naidi4/>
                    :null
                }
                {
                    this.state.naidi2Md4?
                        <Guia2Naidi4/>
                    :null
                }
                {
                    this.state.naidi3Md4?
                        <Guia3Naidi4/>
                    :null
                }
                {
                    this.state.naidi1Md5?
                        <Guia1Naidi5/>
                    :null
                }
                {
                    this.state.naidi2Md5?
                        <Guia2Naidi5/>
                    :null
                }
            </div>
        );
    }
}

export default Contents;
