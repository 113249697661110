import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import fguia1 from "../../svg/portadas-front-end/guia-1-1.svg";
import fguia2 from "../../svg/portadas-front-end/guia-2-1.svg";
import fguia3 from "../../svg/portadas-front-end/guia-3-1.svg";
import fguia4 from "../../svg/portadas-front-end/guia-4-1.svg";

class GuiaMd1  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'fguia1')}><Lista courses="Guía I" imgurl={fguia1} imgalt="Begining whit <HTML>"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia2')}><Lista courses="Guía II" imgurl={fguia2} imgalt="Fundamentos del texto <HTML>"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia3')}><Lista courses="Guía III" imgurl={fguia3} imgalt="Multimedia e Incrustación"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia4')}><Lista courses="Guía IV" imgurl={fguia4} imgalt="Trabajando con tablas en <HTML5>"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaMd1;
