import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia10 */
class Guia6Naidi2  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
         {/* paper 1 */}
         <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 2</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Primeros pasos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p> 
                                          Estimados lectores, las temáticas que hemos estudiado en
                                          éste valle de conocimientos, nos han servido mucho para ir
                                          puliendo nuestras habilidades en pro de ser constructores y
                                          programadores de robots y dispositivos de automatización.

                                          </p>
                                            
                                                          
                                            </div>

                                            </div>
                                              
                                   


                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       En esta guía empezaremos a darle aún más forma a toda esta
                                      travesía que estamos atravesando en el valle del naidí,
                                      haremos construcciones un poco más avanzadas, dando
                                      órdenes a nuestros componentes electrónicos para que
                                      ejecuten las tareas que les solicitamos.
                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Uso de entradas y salidas digitales</h4><h4 style={{ fontWeight:'bold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'3px 0px 5px 0px'}}>
                                        
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de conectar un componente en los
                                    pines digitales de la placa arduino, y configurarlos ya sea como entrada o salida, de igual manera
                                    aprenderán construir un circuito electrónico con diodos LED a partir de un esquema y programar
                                    el encendido y apagado de los mismos, realizando diferentes patrones mediante el encendido y
                                    apagado con condicional de tiempo.
                                        

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                    ● Aprender conceptos básicos y configuración de entradas y salidas en los pines digitales de la placa arduino.

                                     </p>

                                     <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Construir circuito electrónico con diodos LED y resistencias a partir de un esquema.
                                     </p>

                                     <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● realizar programación de encendido y apagado de diodos LED mediante la plataforma mBlock .
                                     </p>


                          
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                   
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2.5em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: Entradas Digitales en Arduino</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'0px 0px 5px 0px'}}>
                                    Las entradas digitales en arduino son los pines en los cuales podemos conectar sensores o
                                    dispositivos que detecten algún cambio o una variación del mundo real.
                                   </p>

                                   <p>  
                                   En las entradas digitales de arduino vamos a recibir una señal digital, que está compuesta de dos
                                  valores 0V (GND) y 5V, que como ya lo dijimos puede ser nombrados como:
                                    </p>

                                 <p>
                                 ● 0V – Falso – Cero Lógico – LOW - Apagado - Bajo
                                 </p>

                                 <p>
                                 ● 5V (3.3V) – Verdadero – Uno Lógico – HIGH - Encendido - Alto
                                 </p>


                                 <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'85%', display:'block', margin:'auto'}} src="./img/arduino3.png" alt="" />

                                      </div>

                                      <p>
                                      Sin embargo, en el mundo real cuando una entrada digital hace la lectura de una señal, esta lo hace
                                     a través de un umbral de referencia de 2.5V aproximadamente, donde si el valor leído por la entrada
                                     digital de arduino es superior a ese umbral, arduino lo considera como Alto y si es inferior lo considera
                                        como Bajo
                                      </p>
                      

                         

                                   
                               
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
               
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                                
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <strong>Pines Digitales de Arduino UNO</strong>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Como ejemplo vamos a ver donde se ubican los pines digitales de arduino uno como ilustración, pero
                                  esto es aplicable para cualquier otra placa de arduino que poseas:
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/arduino4.png" alt="" />

                                              </div>

                                              <strong>Conexiones de una Entrada Digital en Arduino</strong>

                                              <p style={{padding:'5px 0px 5px 0px'}}>
                                              La tensión que pueden recibir las entradas digitales es de 5V (para la mayoría de los arduinos) y hay
                                              excepciones de algunos arduinos que trabajan a 3.3V como el Mini o el Nano. Entonces asegúrate
                                              primero en conocer cuál es el voltaje de tensión de tu placa de arduino.
                                              </p>

                                              <strong>NUNCA vayas a entregar un voltaje mayor al de alimentación (5V o 3.3V) porque vas a quemar tu placa.</strong>
                      
                                      
                                   
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p>
                                    Si por alguna razón, la señal que deseas conectar a tu arduino es mayor a 5 V debes hacer algún
                                    circuito para disminuir el valor de tensión que le está entrando al Arduino. Un circuito práctico es
                                    hacer un divisor de voltaje con dos resistencias.
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/arduino5.png" alt="" />

                                               </div>

                                               <p>
                                               Donde el voltaje de salida va a depender de los valores de resistencia y 
                                               el valor de alimentación de entrada.
                                               </p>

                                               <p>
                                                <span style={{color:'red'}}>Amplía información aquí</span>
                                                <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://controlautomaticoeducacion.com/arduino/entradas-digitales/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                               </p>

                                               <strong>Cómo leer las Entradas Digitales de Arduino</strong>

                                               <p>
                                               Para leer las entradas digitales de Arduino vamos a utilizar los bloques de nuestro programa mBlock 
                                               </p>

                                               <p>
                                               que están en el grupo Pin, y escogemos el bloque "lee pin digital" , donde el número hace 
                                               referencia al número del pin de nuestra placa arduino, donde tenemos conectado nuestro sensor a leer.
                                               </p>

                                               <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino6.png" alt="" />

                                             </div>

                               
                        
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <p>
                                  Esta lectura la podemos hacer directamente en una estructura de control, en una variable o en el puerto serial.
                                  </p>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Salidas Digitales en Arduino</h5></div>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                Los puertos digitales del Arduino UNO van desde el PIN 0 hasta el PIN 13, como en las entradas, esto
                               quiere decir que los mismos pines se pueden configurar tanto para entrada como para salida.
                              La única recomendación es que si usas los PINES 0 y 1 como salidas o entradas digitales, deberás
                               desconectar el circuito que estés haciendo en el momento de hacer la programación del Arduino, ya
                              que estos dos pines también son usado para la comunicación con el computador, por lo tanto si se
                              usan como entradas o salidas digitales debe desconectarse antes el circuito para que permita ser
                             programado.
                                  </p>


                                  
                                <div style={{display:'flex', justifyContent:'space-between',padding:'20px 0px 10px 0px'}} >

                                              <div style={{padding:'0px 10px 0px 0px'}}>

                                  <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                    </div>

                                    <div className="container" style={{width:'95%'}}>


                  <p> 
                  Normalmente estos dos pines los usamos cuando estamos configurando un 
                  módulo bluetooth, el cual nos sirve para controlar los pines del arduino 
                  remotamente, ya sea por computador, tablet o celular a través de un aplicativo.
                  </p>

                    </div>

                    </div>



                               
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <strong>Para qué Sirven las Salidas Digitales</strong>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Con las salidas digitales Arduino Mega, Uno, Leonardo o el que sea, podremos interactuar con el 
                                  mundo físico de miles de formas, porque como yá lo vimos, podemos mandar una señal de dos 
                                  estados (1 o 0) es decir, prender o apagar.

                                  </p>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  Entonces con esto podremos encender y apagar luces, leds, motores, relés, actuadores, etc.
                                  </p>

                                  <strong> Utilizar las Salidas Digitales en Arduino</strong>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Utilizar las Salidas Digitales en Arduino 
                                  Es hora de utilizar estas salidas digitales de Arduino vamos a utilizar los bloques de nuestro programa 
                                  mBlock que están en el grupo Pin, y escogemos el bloque "pon el pin digital",donde el número hace referencia al número del pin de nuestra placa arduino, donde tenemos 
                                  conectado nuestro actuador para ser dominado, y tiene dos opciones de configuración las cuales son 
                                  Alto y Bajo, para indicar el encendido o apagado del puerto digital.
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/arduino7.png" alt="" />

                           </div>

                                    
                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Retardos en Arduino </h5></div>

                                
                                        <p style={{padding:'5px 0px 5px 0px'}}>
                                        Para poder dominar mediante entrada o salida los componentes periféricos en robótica, debemos 
                                        de tener en cuenta una variable natural que es de suma importancia, y es que todo comportamiento 
                                        se da en base al tiempo en que se ejecuta, por ejemplo el tiempo que dura encendido o apagado 
                                        una luz un semáforo, el tiempo en que demore en llegar la señal de retorno de un sensor ultrasonido, 
                                        el tiempo que dure encendido uno o varios motores para un recorrido específico según la distancia 
                                        etc.

                                        </p>

                                        <p>
                                        En programación podemos controlar todas estas actividades haciendo pequeños o grandes retrasos 
                                        en las actividades que estén ejecutando nuestros componentes, y lo podemos hacer a través del 
                                        bloque "espera segundos" , que se encuentra en el grupo de bloques control y que sirve para
                                        ordenar el tiempo de ejecución de una acción, la cual se encuentra configurada en una escala de 
                                        segundos.
                                        </p>

                                        <div style={{padding:'10px 0px 0px 0px'}}>

            <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino8.png" alt="" />

                                                   </div>
 
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                          
                                <div style={{display:'flex', justifyContent:'space-between',padding:'20px 0px 10px 0px'}} >
  
                                            <div style={{padding:'0px 10px 0px 0px'}}>

                                        <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                    </div>

                                    <div className="container" style={{width:'95%'}}>


                                <p> 
                                Para tener los conceptos un poco más claros, les invito a ver el siguiente video, 
                                donde se amplía un poco los conceptos y se hace una pequeña demostración en el 
                                programa mBlock: <span style={{color:'red'}}> Entradas y salidas digitales en mBlock</span>
                                <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=MOWLIrx9zAQ">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.1rem', color:'red'}}/> </a></span>

                              </p>

                                  </div>

                                  </div>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad 1</h5></div>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Ya conocimos los conceptos de entradas, salidas y retardos en arduino con mBlock, y en las guías iniciales 
                                  conocimos el diodo LED con su configuración, entonces es hora de hacer una pequeña práctica guiada por el formador para ir 
                                  familiarizándonos un poco más con las conexiones y componentes estudiados hasta el momento.
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  El formador les suministrará los siguientes componentes:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 3 Diodos LED de 3 colores diferentes
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 3 resistencias de 220 Ohmios
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 Placa de prototipos o Protoboard
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 Placa Arduino Uno
                                  </p>

                                 



                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                 <p>
                                 Vamos a guiarnos del siguiente diagrama de conexión para 
                                 hacer las respectivas conexiones de los componentes a usar:
                                 </p>

                                 <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/arduino9.png" alt="" />

                                             </div>


                                             <p>
                                             El reto es hacer encender los tres diodos LED, programados de la 
                                             siguiente manera:
                                             </p>

                                             <p style={{padding:'5px 0px 0px 0px'}}>
                                             ● Encender el primer LED por 2 segundos y los demás deben estar apagados.
                                             </p>
                                             
                                             <p style={{padding:'5px 0px 0px 0px'}}>
                                             ● Encender el tercer LED por 1 segundo y los demás deben estar apagados
                                             </p>
                                             
                                             <p style={{padding:'5px 0px 0px 0px'}}>
                                             ● Encender el segundo LED por 3 segundos, pero a la vez el
                                             primer LED debe encender por 1 segundo y el tercero por dos segundos.
                                             </p>

                                             <p style={{padding:'5px 0px 0px 0px'}}>
                                             ● Todos los LEDs están apagados por 5 segundos.
                                             </p>

                                             <p style={{padding:'5px 0px 0px 0px'}}>
                                             ● Todos los LEDs están encendidos por 5 segundos.
                                             </p>

                                             <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                                       </div>

                                             
                                

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    <p>
                                    A continuación en la imagen observarán la programación de los dos primeros incisos.
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/arduino10.png" alt="" />

                                                   </div>

                                                   <p>
                                                   Para ver todo el proceso de ensamble y programación paso a paso en video
                                                   <span style={{color:'red'}}> Presiona clic en el siguiente enlace:</span>
                                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=VbApLU3j6uk">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.1rem', color:'red'}}/> </a></span>
                                                   </p>



                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                               

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>
                               
                                      <p style={{padding:'10px 0px 10px 0px'}}>
                                      ● Video y archivo.mblock de cada grupo con el reto completado. 
                                      </p>

                                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                        <p style={{padding:'10px 0px 10px 0px'}}>
                                        ● C, S. (2020, 6 octubre). Como usar las Entradas Digitales en ARDUINO. Control Automático 
                                        Educación.  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://controlautomaticoeducacion.com/arduino/entradas-digitales/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                        </p>

                                        <p style={{padding:'10px 0px 10px 0px'}}>
                                        ● C, S. (2020b, octubre 6). Salidas Digitales en Arduino. Control Automático Educación.
                                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://controlautomaticoeducacion.com/arduino/salidas-digitales/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                        </p>



                                        <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/gifar.gif" alt="" />

                                                 </div>


                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia6Naidi2;
