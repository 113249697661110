import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/frontBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCaretRight, faVideo, faPencilRuler, faImages, faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

class Guia1Md0  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);

        var elmModal = document.querySelectorAll('.modal');
        var instModal = M.Modal;
        instModal.init(elmModal);
    }

    flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo = document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.add('movFlipEfectNext');

        if (!elemenVideo){
            /* null */
        }else{
            let vpause= document.querySelectorAll('video')[indexVideo];
            vpause.pause();
        }

        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
        }else{
            setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                setTimeout(()=> (e.classList.add('eVisibility')), 800);
            }
        });

        let aUrl ='./media/paperflip.mp3';
        let audioUrl = new Audio(aUrl);
        audioUrl.play();

    }

    flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.remove('movFlipEfectNext');
        page.style.transform ='translateX(0%)';

        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
        }else{
            setTimeout(() => (page.style.zIndex = index), 500);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                e.classList.remove('eVisibility');
            }
        });

        let aUrl ='./media/paperflip.mp3';
        let audioUrl = new Audio(aUrl);
        audioUrl.play();
    }

    pausarVideo(idvideo){
      let vpause= document.getElementById(idvideo);
      vpause.remove();
    }

    obIframe(vUrl, mVideo){
      let obModal = document.getElementById(mVideo);
      let obIfram = document.createElement('iframe');
      obIfram.id ='vdo';
      obIfram.width ='450';
      obIfram.height ='250';
      obIfram.title ='Roles en Front-End';
      obIfram.src = vUrl;
      obIfram.frameBorder = '0';
      obIfram.allowFullScreen = 'true';
      obModal.appendChild(obIfram);
    }

    render(){
      return(
        <div className="flip-container">
          {/* paper 1 */}
          <div id="movPage1" className="page">
            <div className="front">
              <div className="front__content background-portada">
                <div className="goBack"></div>
                <div className="front__content-text">
                  <div style={{padding:'10px 0px 10px 10px', margin:0}}>
                    <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Guía-1-El-Que-y-el-Como.pdf" download="Guía 1- El Que y el Como" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFileDownload} style={{color:'#06386B'}}/>
                    </a>
                  </div>
                  <div className="front__portada">
                    {/* --*/}
                  </div>
                </div>
                <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 2 */}
          <div id="movPage2" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{width:"100%", display:'flex', alignItems:'center', color:'#06386B'}}>
                      <img style={{maxWidth:"30%", height:'auto'}} src="./img/front-end-pacifico.png" alt=""/>
                      <h4><b>Módulo Introductorio</b></h4>
                    </div>
                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4><b><span style={{color:'#06386B'}}>Guía I:</span><span style={{color:'#2D6CB0'}}>El ¿Qué? y el ¿Como?</span></b></h4></div>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Objetivos</b></h5></div>
                    <div className="container" style={{overflowY:'auto', height:'50%', width:'100%'}}>
                      <ul className="collection" style={{opacity:'0.8', margin:0}}>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Conocer ¿qué es Front-End? ¿quién es el desarrollador web Front-End? y que se pide de este profesional en la industria web. <a href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Introducción al Frontend.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Comprender ¿qué es la web?, sus conceptos básicos y generales y aprender los estándares y especificaciones técnicas que definen y describen los aspectos de la Web. <a href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/La Web.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Establecer pre-requisitos básicos de hardware y software para el desarrollo web. <a href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Prerequisitos.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Estructurar los archivos necesarios para la creación de un entorno de desarrollo web. <a href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Estructura de Archivos.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Aprender cómo hacer un boceto de una página web. <a href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Boceto.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                        <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'justify'}}><span style={{color:'#2D6CB0', paddingRight:'15px'}}><FontAwesomeIcon icon={faCaretRight}/></span>Conocer la estructura del proyecto final y construir un primer boceto basado en la estructura dada. <a href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/El Proyecto.pdf" target="_blank" rel="noreferrer">Ver más</a></p></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* paper 3 */}
          <div id="movPage3" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 1: ¿Qué es Front-End?</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Comprender lo que significa <span style={{fontWeight:'bold', color:'#2D6CB0'}}>Front-End</span>, es fundamental para continuar con nuestro proceso de aprendizaje. Esta profesión es muy demandada en la industria web. Empresas, emprendedores y micro empresas requieren de este especialista.
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px'}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Introducción al Front-End</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Introducción al Frontend.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Los roles en Front-End explicados</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center modal-trigger" href="#video-1" rel="noreferrer" onClick={(e) => this.obIframe("https://www.youtube.com/embed/N8mFHB7JDkw", "mvideo-1")}>
                                  <FontAwesomeIcon icon={faVideo} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Según la lectura del material <span style={{fontWeight:'bold', color:'#2D6CB0'}}>Introducción al Front-End</span>, marca las respuestas correctas en las siguientes preguntas:
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de preguntas</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-1" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*video*/}
            <div id="video-1" className="modal">
              <div className="modal-content">
                <h4 className="gbold">Video</h4>
                <div id="mvideo-1">
                  {/*video*/}
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat" onClick={(e) => this.pausarVideo('vdo')}>Salir</a>
              </div>
            </div>
            {/*form*/}
            <div id="form-1" className="modal">
              <div className="modal-content">
                <h4><b>Actividad-1</b></h4>
                <div id="">
                  <form className="" id="actividad1" name="actividad1" encType="multipart/form-data" action="./php/contact.php" method="post">
                    <input type="text" name="cod1" id="cod1" placeholder="Código" autoComplete='off' required/>
                    <datalist id="tutor">
                      <option value='Tutor1' label='Buenaventura'/>
                      <option value='Tutor1' label='Timbiqui'/>
                      <option value='Tutor1' label='Tumaco'/>
                    </datalist>
                    <input type="text" name="tut1" id="tut1" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor'/>
                    <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Preguntas</b></h5>
                    <label htmlFor="pregunta1"><b>¿Qué es el Front-End?</b></label>
                    <datalist id="pregunta1">
                      <option value='Opción 1' label='Ingeniería de software que se ejecuta del lado del servidor.'/>
                      <option value='Opción 2' label='Mantenimiento y optimización de sitios web e interfaces de usuario.'/>
                      <option value='Opción 3' label='Asegura la accesibilidad de la web.'/>
                      <option value='Opción 4' label='Especialidad del desarrollo web, que trabaja la interfaz web y hace que el usuario pueda interactuar con la web.'/>
                    </datalist>
                    <input type="text" name="res1" id="res1" placeholder="Seleccione" autoComplete='off' required list='pregunta1'/>
                    <label htmlFor="pregunta2"><b>¿Partes que interactúan en el Front-End, según el aspecto técnico?</b></label>
                    <datalist id="pregunta2">
                      <option value='Opción 1' label='La capa de abstracción.'/>
                      <option value='Opción 2' label='Dispositivo del Usuario, Navegador, Pagina web.'/>
                      <option value='Opción 3' label='Cliente, Navegador, Back-End.'/>
                      <option value='Opción 4' label='La lógica de diseño y la lógica de negocio.'/>
                    </datalist>
                    <input type="text" name="res2" id="res2" placeholder="Seleccione" autoComplete='off' required list='pregunta2'/>
                    <label htmlFor="pregunta3"><b>¿Cuáles son los lenguajes propios de la tecnología web que debe manejar el desarrollador Front-End?</b></label>
                    <datalist id="pregunta3">
                      <option value='Opción 1' label='CSS, HTML, JS.'/>
                      <option value='Opción 2' label='HTML, CSS, JS, PHP.'/>
                      <option value='Opción 3' label='JS, HTML, CSS, C#.'/>
                      <option value='Opción 4' label='HTML.'/>
                    </datalist>
                    <input type="text" name="res3" id="res3" placeholder="Seleccione" autoComplete='off' required list='pregunta3'/>
                    <label htmlFor="pregunta4"><b>Seleccione de la lista una de las funciones del desarrollador Front-End</b></label>
                    <datalist id="pregunta4">
                      <option value='Opción 1' label='Dominio HTML5, CSS3 y JavaScript.'/>
                      <option value='Opción 2' label='Asegurar la accesibilidad de la web.'/>
                      <option value='Opción 3' label='Dominio de algún Frameworks o Biblioteca para desarrollo Front-End.'/>
                      <option value='Opción 4' label='Conocimientos de SEO On Page.'/>
                    </datalist>
                    <input type="text" name="res4" id="res4" placeholder="Seleccione" autoComplete='off' required list='pregunta4'/>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
              </div>
            </div>
          </div>
          {/* paper 4 */}
          <div id="movPage4" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 2: Diferenciar Https y URL</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Observa la imagen ubicada junto al formulario e identifica según el punto señalado las partes que contiene un enlace.
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>¿Qué es la Web?</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/La Web.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>¿Que es una URL?</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center modal-trigger" href="#video-2" rel="noreferrer" onClick={(e) => this.obIframe("https://www.youtube.com/embed/54ONA5zBKHk", "mvideo-2")}>
                                  <FontAwesomeIcon icon={faVideo} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Despliega el formulario de preguntas y selecciona tus respuestas.
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de preguntas</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-2" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Partes de una URL</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./img/4.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*video*/}
            <div id="video-2" className="modal">
              <div className="modal-content">
                <h4 className="gbold">Video</h4>
                <div id="mvideo-2">
                  {/*video*/}
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat" onClick={(e) => this.pausarVideo('vdo')}>Salir</a>
              </div>
            </div>
            {/*form*/}
            <div id="form-2" className="modal">
              <div className="modal-content">
              <h4><b>Actividad-2</b></h4>
              <div id="">
                <form className="" id="actividad2" name="actividad2" encType="multipart/form-data">
                  <input type="text" name="cod2" id="cod2" placeholder="Código" autoComplete='off' required/>
                  <datalist id="tutor">
                    <option value='Tutor1' label='Buenaventura'/>
                    <option value='Tutor1' label='Timbiqui'/>
                    <option value='Tutor1' label='Tumaco'/>
                  </datalist>
                  <input type="text" name="tut2" id="tut2" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor'/>
                  <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Preguntas</b></h5>
                  <label htmlFor="pregunta5"><b>Opción 1</b></label>
                  <datalist id="pregunta5">
                    <option value='Opción 1' label='Protocolo.'/>
                    <option value='Opción 2' label='Subdominio.'/>
                    <option value='Opción 3' label='Dominio.'/>
                    <option value='Opción 4' label='Top level domain (TLD).'/>
                    <option value='Opción 5' label='Path to the file (Sub-carpeta o directorio).'/>
                    <option value='Opción 6' label='Recurso.'/>
                  </datalist>
                  <input type="text" name="res5" id="res5" placeholder="Seleccione" autoComplete='off' required list='pregunta5'/>
                  <label htmlFor="pregunta6"><b>Opción 2</b></label>
                  <datalist id="pregunta6">
                    <option value='Opción 1' label='Protocolo.'/>
                    <option value='Opción 2' label='Subdominio.'/>
                    <option value='Opción 3' label='Dominio.'/>
                    <option value='Opción 4' label='Top level domain (TLD).'/>
                    <option value='Opción 5' label='Path to the file (Sub-carpeta o directorio).'/>
                    <option value='Opción 6' label='Recurso.'/>
                  </datalist>
                  <input type="text" name="res6" id="res6" placeholder="Seleccione" autoComplete='off' required list='pregunta6'/>
                  <label htmlFor="pregunta7"><b>Opción 3</b></label>
                  <datalist id="pregunta7">
                    <option value='Opción 1' label='Protocolo.'/>
                    <option value='Opción 2' label='Subdominio.'/>
                    <option value='Opción 3' label='Dominio.'/>
                    <option value='Opción 4' label='Top level domain (TLD).'/>
                    <option value='Opción 5' label='Path to the file (Sub-carpeta o directorio).'/>
                    <option value='Opción 6' label='Recurso.'/>
                  </datalist>
                  <input type="text" name="res7" id="res7" placeholder="Seleccione" autoComplete='off' required list='pregunta7'/>
                  <label htmlFor="pregunta8"><b>Opción 4</b></label>
                  <datalist id="pregunta8">
                    <option value='Opción 1' label='Protocolo.'/>
                    <option value='Opción 2' label='Subdominio.'/>
                    <option value='Opción 3' label='Dominio.'/>
                    <option value='Opción 4' label='Top level domain (TLD).'/>
                    <option value='Opción 5' label='Path to the file (Sub-carpeta o directorio).'/>
                    <option value='Opción 6' label='Recurso.'/>
                  </datalist>
                  <input type="text" name="res8" id="res8" placeholder="Seleccione" autoComplete='off' required list='pregunta8'/>
                  <label htmlFor="pregunta9"><b>Opción 5</b></label>
                  <datalist id="pregunta9">
                    <option value='Opción 1' label='Protocolo.'/>
                    <option value='Opción 2' label='Subdominio.'/>
                    <option value='Opción 3' label='Dominio.'/>
                    <option value='Opción 4' label='Top level domain (TLD).'/>
                    <option value='Opción 5' label='Path to the file (Sub-carpeta o directorio).'/>
                    <option value='Opción 6' label='Recurso.'/>
                  </datalist>
                  <input type="text" name="res9" id="res9" placeholder="Seleccione" autoComplete='off' required list='pregunta9'/>
                  <label htmlFor="pregunta10"><b>Opción 6</b></label>
                  <datalist id="pregunta10">
                    <option value='Opción 1' label='Protocolo.'/>
                    <option value='Opción 2' label='Subdominio.'/>
                    <option value='Opción 3' label='Dominio.'/>
                    <option value='Opción 4' label='Top level domain (TLD).'/>
                    <option value='Opción 5' label='Path to the file (Sub-carpeta o directorio).'/>
                    <option value='Opción 6' label='Recurso.'/>
                  </datalist>
                  <input type="text" name="res10" id="res10" placeholder="Seleccione" autoComplete='off' required list='pregunta10'/>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
            </div>
          </div>
        </div>
        {/* paper 5 */}
        <div id="movPage5" className="page">
          <div className="front">
            <div className="front__content background-page1">
              <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 3: Software Básico</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Efectuar la instalación y configuración del software básico, para iniciar el desarrollo web. ¡Crea tu entorno de trabajo!.
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Prerrequisitos Básicos de Hardware y Software</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Prerequisitos.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Envía un <span style={{fontWeight:'bold', color:'#2D6CB0'}}>ScreenShot</span>, a tu tutor con tu entorno de trabajo instalado, junto con el listado presentado en la imagen que se encuentra bajo del formulario de envió.
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de enviós</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-3" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Identificación del Entorno de trabajo</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./img/10.png" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*form*/}
            <div id="form-3" className="modal">
              <div className="modal-content">
                <h4><b>Actividad-3</b></h4>
                <div id="">
                  <form className="" id="actividad3" name="actividad3" encType="multipart/form-data">
                    <input type="text" name="cod3" id="cod3" placeholder="Código" autoComplete='off' required/>
                    <datalist id="tutor">
                      <option value='Tutor1' label='Buenaventura'/>
                      <option value='Tutor1' label='Timbiqui'/>
                      <option value='Tutor1' label='Tumaco'/>
                    </datalist>
                    <input type="text" name="tutor" id="tutor" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor'/>
                    <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                    <input type="file" accept=".pdf, .jpg, .png" multiple/>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
              </div>
            </div>
          </div>
          {/* paper 6 */}
          <div id="movPage6" className="page">
            <div className="front">
              <div className="front__content background-page1">
                <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
                <div className="front__content-text">
                  <div style={{height:'97%'}}>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 4: Manejo de Archivos</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <p className="light grey-text">
                        Crea en tu computador según lo aprendido, la estructura de archivos y directorios manejados en una web y muéstrale a tu tutor el trabajo realizado. Toma en cuenta la imagen de ejemplo ubicada en el área de materiales para mostrar tu distribución de archivos.
                      </p>
                      <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                      <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                        <table className="highlight" style={{marginBottom:'30px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Estructura de Archivos</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Estructura de Archivos.pdf" target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen: Estructura de directorios básica</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center modal-trigger" href="./img/5.png" target="_blank" rel="noreferrer" >
                                  <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="light grey-text">
                          Para enviar el <span style={{fontWeight:'bold', color:'#2D6CB0'}}>ScreenShop</span>, a tu tutor debes adjuntarlo a continuación:
                        </p>
                        <table className="highlight" style={{marginTop:'10px'}}>
                          <tbody>
                            <tr>
                              <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de envios</p></td>
                              <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                                <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-4" rel="noreferrer">
                                  <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                  <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                  </a>
                </div>
              </div>
            </div>
            {/*form*/}
            <div id="form-4" className="modal">
              <div className="modal-content">
                <h4><b>Actividad-4</b></h4>
                <div id="">
                  <form className="" id="actividad4" name="actividad4" encType="multipart/form-data">
                    <input type="text" name="cod4" id="cod4" placeholder="Código" autoComplete='off' required/>
                      <datalist id="tutor4">
                        <option value='Tutor1' label='Buenaventura'/>
                        <option value='Tutor1' label='Timbiqui'/>
                        <option value='Tutor1' label='Tumaco'/>
                      </datalist>
                    <input type="text" name="tut4" id="tut4" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor4'/>
                    <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                    <input type="file" accept=".pdf, .jpg, .png" multiple/>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
                <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
              </div>
            </div>
        </div>
        {/* paper 7 */}
        <div id="movPage7" className="page">
          <div className="front">
            <div className="front__content background-page1">
              <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 5: Creando un boceto</b></h5></div>
                  <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      Diseñar el boceto para nuestro proyecto, según las técnicas aprendidas. El boceto debe contener la estructura básica de nuestro sitio web.
                    </p>
                    <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', justifyContent:'flex-end', marginTop:'10px'}}><h5 className="white-text" style={{paddingRight:'5px', textAlign:''}}><b>Materiales</b></h5></div>
                    <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                      <table className="highlight" style={{marginBottom:'30px'}}>
                        <tbody>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Boceto Web o Wireframe</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Boceto.pdf" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Fases de Desarrollo Web</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Fases del Desarrollo Web.pdf" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Interfaz Web Básica</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center" href="./material/front-end-pacifico/Módulo-0-Introductorio-El-Que- y-el-Cómo/Materiales/Estructura Web Basica.pdf" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFilePdf} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Imagen:¿Cómo se ve un Boceto?</p></td>
                            <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                              <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'medium'}} className="btn-floating pulse waves-effect center modal-trigger" href="./img/9.png" target="_blank" rel="noreferrer" >
                                <FontAwesomeIcon icon={faImages} style={{color:'#2D6CB0'}}/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* paper 8 */}
        <div id="movPage8" className="page">
          <div className="front" >
            <div className="front__content background-page1">
              <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Actividad 5: Creación boceto</b></h5></div>
                  <div className="container" style={{height:'auto', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      Para enviar el <strong style={{fontWeight:'bold', color:'#2D6CB0'}}>ScreenShop</strong>, a tu tutor debes adjuntarlo a continuación:
                    </p>
                    <table className="highlight">
                      <tbody>
                        <tr>
                          <td style={{padding:'10px 0px 10px 0px', margin:0}}><p>Formulario de envios</p></td>
                          <td style={{padding:'10px 0px 10px 10px', margin:0, display:'flex', justifyContent:'center'}}>
                            <a style={{backgroundColor:'#ffffff', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#form-5" rel="noreferrer">
                              <FontAwesomeIcon icon={faPencilRuler} style={{color:'#2D6CB0'}}/>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', marginTop:'20px'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Entregables</b></h5></div>
                  <div className="container" style={{overflowY:'auto', height:'25%', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      Para completar la guia debes haber enviado a tu tutor las diferentes actividades propuestas, a continuación un resumen de estas:
                    </p>
                    <ol style={{marginTop:'10px'}}>
                      <li><p>1.Instalación del Software Básico.</p></li>
                      <li><p>2.Estructura de Archivos.</p></li>
                      <li><p>3.Boceto web.</p></li>
                    </ol>
                  </div>
                  <div style={{backgroundColor:'#2D6CB0', height:'3em', display:'flex', alignItems:'center', marginTop:'20px'}}><h5 className="white-text" style={{paddingLeft:'5px'}}><b>Bibliografía</b></h5></div>
                  <div className="container" style={{overflowY:'auto', height:'25%', width:'100%', paddingTop:'10px'}}>
                    <p className="light grey-text">
                      MDN Web Docs. (2022). Aprende sobre desarrollo web: MDN contributors. Recuperado de <a href="https://developer.mozilla.org/es/docs/Learn"> https://developer.mozilla.org/es/docs/Learn </a>
                    </p>
                  </div>
                </div>
              </div>
              <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
            </div>
          </div>
          {/*form*/}
          <div id="form-5" className="modal">
            <div className="modal-content">
              <h4><b>Actividad-5</b></h4>
              <div id="">
                <form className="" id="actividad5" name="actividad5" encType="multipart/form-data">
                  <input type="text" name="cod5" id="cod5" placeholder="Código" autoComplete='off' required/>
                  <datalist id="tutor5">
                    <option value='Tutor1' label='Buenaventura'/>
                    <option value='Tutor1' label='Timbiqui'/>
                    <option value='Tutor1' label='Tumaco'/>
                  </datalist>
                  <input type="text" name="tutor" id="tutor" placeholder="Seleccione el Tutor" autoComplete='off' required list='tutor5'/>
                  <h5 style={{paddingTop:'10px', paddingBottom:'10px'}}><b>Enviar</b></h5>
                  <input type="file" accept=".pdf, .jpg, .png" multiple/>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Cancelar</b></a>
              <a href="#!" className="modal-close waves-effect waves-green btn-flat"><b>Enviar</b></a>
            </div>
          </div>
        </div>
        {/* paper 9 */}
        <div id="movPage9" className="page">
          <div className="front">
            <div className="front__content background-page2">
              <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                <a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>
              </div>
              <div className="front__content-text">
                <div style={{height:'97%'}}>
                  {/*fin*/}
                </div>
              </div>
              <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                {/*<a style={{opacity:'0.9', backgroundColor:'#2D6CB0', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Guia1Md0;
