import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import nguia15 from "../../svg/portadas-naidi/naidi-1-5.svg";
import nguia16 from "../../svg/portadas-naidi/naidi-2-5.svg";

class GuiaNaidi5  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'nguia15')}><Lista courses="Guía I" imgurl={nguia15} imgalt="CONEXIONES INALÁMBRICAS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia16')}><Lista courses="Guía II" imgurl={nguia16} imgalt="CONEXIÓN BLUETOOTH"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaNaidi5;
