import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import fguia5 from "../../svg/portadas-front-end/guia-1-2.svg";
import fguia6 from "../../svg/portadas-front-end/guia-2-2.svg";
import fguia7 from "../../svg/portadas-front-end/guia-3-2.svg";
import fguia8 from "../../svg/portadas-front-end/guia-4-2.svg";

class GuiaMd2  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'fguia5')}><Lista courses="Guía I" imgurl={fguia5} imgalt="¿Qué es CSS?"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia6')}><Lista courses="Guía II" imgurl={fguia6} imgalt="Manejo de texto con CSS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia7')}><Lista courses="Guía III" imgurl={fguia7} imgalt="Tipografía y Animación con CSS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia8')}><Lista courses="Guía IV" imgurl={fguia8} imgalt="Métodos de distribución de elementos"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaMd2;
