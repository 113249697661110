import React, { Component } from "react";

class Lista extends Component{
    render(){
        return(
            <div className="App-lista">
                <div className="cont-lista">
                    <img src={this.props.imgurl} alt={this.props.imgalt}></img>
                    <span><h5>{this.props.courses}</h5></span>
                </div>
            </div>
        );
    }
}

export default Lista;
