import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft,faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube} from "@fortawesome/free-brands-svg-icons";
/* Guia3*/
class Guia3Naidi1  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
     
      <div className="flip-container">
      {/* paper 1 */}
      <div id="movPage1" className="page">
          <div className="front">
              <div className="front__content nbackground-portada1">
                  <div className="goBack"></div>
                  <div className="front__content-text">
                      <div className="nfront__portada1">
                          {/* --*/}
                      </div>
                  </div>
                  <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
      </div>
      {/* paper 2 */}
      <div id="movPage2" className="page">
          <div className="front">
              <div className="front__content background-page1">
                  <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>

                        <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                          <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                          <h4>Módulo 1</h4>
                          <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                        </div>

                          <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4  style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>Conceptos basicos</h4></div>

                          <div style={{padding:'0px 0px 10px 0px'}}>
                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                          </div>
                          <div>
                          

                          </div>

                          <div style={{display:'flex'}} >

                          <div>
                            <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                          <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                          </div>
                          
                          <div className="container" style={{width:'190%'}}>
                            
                         
                                
                                <p> 

                                Estimados lectores, en la guía anterior aprendimos reconocer 
                                la placa Arduino como el cerebro de las automatizaciones y la 
                                robótica, conociendo las diferentes placas existentes y en qué
                                caso usarlas, de igual manera, identificaron en qué campos se
                                puede usar el arduino y qué aportes hace a diferentes áreas en
                                la vida del ser humano. 
                                </p>

                               <p style={{ padding:'5px 0px 0px 0px'}}>
                               Todo esto fue a nivel de hardware, pero sabemos que el
                              arduino no trabaja solo, necesita de otros componentes que
                              con un trabajo en conjunto logran grandes cosas.
                               </p>
                                  
                                                
                                  </div>

                                  </div>
                                    
                           


                          
                      </div>
                  </div>
                  <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
      </div>
      {/* paper 3 */}
      <div id="movPage3" className="page">
          <div className="front">
              <div className="front__content background-page1">
                  <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                          <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                             
                             <p>
                             Pero tanto el arduino como los demás componentes necesitan de
                              nuestra ayuda para poder trabajar, por lo cual necesita un
                              aliado más, a nivel de software, ya que las tareas que hacen los
                              componentes es gracias a las instrucciones que nosotros les
                              damos, pero necesitamos este actor muy importante para
                              poder dar esas instrucciones, y es un lenguaje de
                              programación, para arduino. Por lo tanto, existen muchos
                              programas que nos permiten escribir ese lenguaje; ya sea por
                              código o armando rompecabezas con bloques.
                             </p>

                              <p  style={{padding:'3px 0px 0px 0px'}}>
                              En ésta guía vamos a conocer uno de esos programas, indispensable para dar instrucciones 
                              a nuestra placa arduino y demás componentes.

                              </p>

                                <p style={{padding:'3px 0px 0px 0px'}}> 

                                No olviden que a medida que vayan avanzando encontrarán 
                                enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la
                                palabra<span style={{color:'red'}}>“aquí”</span>  los llevará al origen del escrito y les ampliaran o
                                complementaran la información aquí plasmada.
                                También encontrarán enlaces a videos de Youtube, en donde
                                podrán aclarar dudas acerca de lo estudiado y les ayudará a
                                entender con más facilidad lo que se está plasmando.
                               </p>
                        
                          </div>
                      </div>
                  </div>
                  <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
          
        
      </div>
      {/* paper 4 */}
      <div id="movPage4" className="page">
          <div className="front">
              <div className="front__content background-page1">
                  <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">

                  
                    <div style={{height:'97%'}}>
                    

                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontFamily:'Montserrat-SemiBold' }}> Introduccion a MBLOCK </h4> <h4 style={{fontFamily:'Montserrat-SemiBold' }}> Objetivos de la guia </h4></div>
                              
                      <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                          <p style={{ padding:'10px 0px 10px 0px'}}>
                          Al culminar esta guía, los estudiantes estarán en la capacidad de reconocer el software de
                          programación por bloques MBLOCK necesario para escribir la lógica necesaria para el control y
                          actuar de los componentes electrónicos a estudiar en éste proyecto. 
                          </p>
                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                          
                         
                          <p style={{ padding:'10px 0px 0px 0px'}}>
                           ● Aprender conceptos básicos del software de programación por bloques mBlock.

                           </p>

                           <p style={{ padding:'5px 0px 0px 0px'}}>
                           ● Conocer la interfaz de usuario del software de programación por bloques mBlock, e indagar 
                           en sus diferentes herramientas necesarias para su uso.
                           </p>
                          
                        
                      </div>
                    </div>
                  </div>
                  <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
         
      </div>
      {/* paper 5 */}
      <div id="movPage5" className="page">
          <div className="front">
              <div className="front__content background-page1">
                  <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: ¿Qué es mBlock? </h5></div>
                      <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                          <p style={{ padding:'0px 0px 5px 0px'}}>
                          MBlock es un software de programación integral basado en Scratch 3.0 y diseñado para la educación.
                          MBlock integra lenguajes de programación como Arduino, C++ y Python, de tal forma que cualquier
                          estudiante puede crear programas para el control de robot o establecer una comunicación entre
                          robot y computadora de una forma relativamente sencilla.
                         </p>

                         <p>  
                         Al estar basada en Scratch, los niños, por lo tanto, pueden empezar a programar con relativa facilidad,
                        dada la simplicidad de este lenguaje de programación basado en bloques, como ya se ha visto en
                        artículos precedentes.
                          </p>

                          <div style={{padding:'10px 0px 0px 0px'}}>

        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/panda.png" alt="" />

                           </div>

               

                     
                      </div>
                    </div>
                  </div>
                  <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
         
      </div>
      {/* paper 6 */}
      <div id="movPage6" className="page">
          <div className="front">
              <div className="front__content background-page1">
                  <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                    <div style={{height:'97%'}}>
                     
                      <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>


                          <p>
                          La aplicación se puede descargar de forma gratuita y para la práctica totalidad de los sistemas
                          operativos, incluidos teléfonos móviles y tabletas, desde su página oficial  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://mblock.makeblock.com/en-us/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span> 
                          Haciendo clic en la pestaña Download el proceso de instalación es sencillo y una vez finalizado, si se
                          echa un vistazo general a la plataforma mBlock, se puede observar que salvo pequeñas diferencias
                          en colores, o el personaje inicial que aparece en pantalla, la estructura de bloques es similar a la
                          estructura del programa Scracth, salvo por la aparición de un nuevo bloque de programas “robot”,
                          desde el que se podrán dar las instrucciones necesarias a los autómatas programables.
                          </p>


                          <div style={{padding:'10px 0px 0px 0px'}}>

            <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/codigo1.png" alt="" />

                                            </div>

                      </div>
                    </div>
                  </div>
                  <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
        
      </div>
      {/* paper 7 */}
      <div id="movPage7" className="page">
          <div className="front">
              <div className="front__content background-page1">
                  <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                        
                        <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>



                          <p> Otra novedad con respecto a Scratch, o a S4A, es que la programación va más allá de la placa
                              Arduino, y permite el uso de una gran variedad de dispositivos programables, Arduino, mBot,
                              megaPi, etc. cada uno de ellos con sus respectivas extensiones y bloques de instrucciones
                              para programar la placa seleccionada. <span style={{color:'red'}}> Amplía información aquí</span>
                              <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="http://revistas.educa.jcyl.es/revista_digital/index.php?option=com_content&view=article&id=3981&catid=84&Itemid=87">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                               </p>

                               <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'85%', display:'block', margin:'auto'}} src="./img/mbot1.png" alt="" />

                                          </div>

                                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> Interfaz y Herramientas</h5></div>

                                          <p  style={{padding:'5px 0px 0px 0px',fontFamily:'Montserrat-SemiBold'}}>
                                            Grupo de instrucciones clasificadas por colores en las siguientes categorías:
                                          </p>

                                          <p style={{padding:'5px 0px 0px 0px'}}>
                                          ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Movimiento: </span> Conjunto de instrucciones relacionadas con el control de los pines de la
                                          tarjeta de Arduino, así como el control del movimiento de cualquier personaje del
                                          escenario.
                                          </p>

                        </div>
                      </div>
                  </div>
                  <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
      </div>
      {/* paper 8 */}
      <div id="movPage8" className="page">
          <div className="front" >
              <div className="front__content background-page1">
                  <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                        

                    

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                          
                          ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Apariencia: </span> Instrucciones orientadas a modificar el aspecto de los personajes de nuestra
                            aplicación. Para el caso de Arduino, es un conjunto de instrucciones que apenas se utiliza.
                        </p>

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Sonido: </span> Conjunto de instrucciones relacionadas con la elaboración de aplicaciones
                        musicales, emitiendo sonidos y notas musicales.

                        </p>

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Lápiz: </span> Scratch nos ofrece la posibilidad de que los personajes dejan un rastro durante 
                         sus movimientos por el escenario como si arrastrase un lápiz durante su trayectoria.

                        </p>

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Control: </span> Las instrucciones incluídas en esta sección son impresindibles para crear la
                          lógica de nuestros programas. Incluyen condicionales, bucles y llamadas de
                          procedimientos.
                        </p>

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Sensores: </span> Instrucciones de iteración con el ratón, el teclado, sonidos y los personajes.
                        </p>

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Operadores:</span> operaciones matemáticas, lógicas y con cadenas de texto.
                        </p>

                        <p style={{ padding:'5px 0px 0px 0px'}}>
                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Variables:</span> Instrucciones para el almacenamiento y gestión de datos.
                        </p>
                               

                    
                      </div>
                  </div>
                  <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
       
      </div>
      
       {/* paper 9 */}
       <div id="movPage9" className="page">
          <div className="front" >
              <div className="front__content background-page1">
                  <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>


                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              - <span style={{fontFamily:'Montserrat-SemiBold'}}>Instrucciones de programación:</span> Las instrucciones de cada grupo corresponden a instrucciones de programación
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              - <span style={{fontFamily:'Montserrat-SemiBold'}}> Editor:</span> Es la parte principal donde estructuramos y programamos nuestro programa.
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Programas:</span>  Se compone de todas las instrucciones que hace funcionar el código que programemos
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Disfraces:</span>  Cada objeto puede tener diferentes apariencias o disfraces para utilizar a lo largo de nuestro programa.
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Sonido:</span> También es posible añadir o grabar sonidos y guardarlos para futuros usos.
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              - <span style={{fontFamily:'Montserrat-SemiBold'}} >Escenario o ventana principal:</span> Es el resultado de nuestro programa
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              - <span style={{fontFamily:'Montserrat-SemiBold'}}>Objetos y sprites:</span> Distinguimos principalmente los objetos de tipo Arduino y Sprites.
                              </p>
                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              ● Los sprites son similares al entorno de scratch y no interactúan con Arduino. 
                              </p>

                              <p style={{ padding:'5px 0px 0px 0px'}}> 
                              <span style={{color:'red'}}>Amplía información aquí</span>   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/ElCableAmarillo/Introduccion-a-Arduino#mBlock">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                              </p>



                              

                              

                      </div>
                  </div>
                  <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
         
      </div>

         {/* paper 10 */}
         <div id="movPage10" className="page">
          <div className="front" >
              <div className="front__content background-page1">
                  <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                        
                            
                              <div style={{padding:'7px 0px 5px 0px'}}>

                            <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/grafico1.png" alt="" />

                            </div>


                            <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

<                                                 div style={{padding:'0px 10px 0px 0px'}}>

                          <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                            </div>

                          <div className="container" style={{width:'95%'}}>


                          <p> Para que entiendan con más facilidad los conceptos anteriores reproduce el siguiente video :<span style={{color:'red'}}>Cómo programar en mBlock 1: Interfaz de mBlock</span>
                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=lCzVG2komic">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                          </p>

                              </div>

                              </div>

                              <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                               </div>


                              
                      </div>
                  </div>
                  <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
         
      </div>


       {/* paper 11 */}
       <div id="movPage11" className="page">
          <div className="front" >
              <div className="front__content background-page1">
                  <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">

                  
                      <div style={{height:'97%'}}>

                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>

                        
                        <p style={{padding:'5px 0px 0px 0px',fontFamily:'Montserrat-SemiBold'}}>
                      ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p>

                        <p style={{padding:'5px 0px 10px 0px'}}>
                        Para verificar que los conocimientos se hayan comprendido
                        correctamente, el formador hará una práctica de reconocimiento
                        en de herramientas mBlock, que consiste en pasar por cada uno
                        de los grupos y solicitarles que identifiquen y ubiquen las
                        herramientas y las áreas que les pregunte. 
                        </p>
                        

                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>
                     
             
                            <p  style={{ padding:'5px 0px 0px 0px'}}> 
                            
                            ●  1 video por grupo, donde se evidencie que identifican o no, las áreas y herramientas que el formador pregunte. 

                            </p>

                            <div style={{padding:'10px 0px 0px 0px'}}>

          <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/mblock.png" alt="" />

                                 </div>


                      </div>
                  </div>
                  <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
         
      </div>

        {/* paper 12 */}
        <div id="movPage12" className="page">
          <div className="front" >
              <div className="front__content background-page1">
                  <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                        <p style={{padding:'10px 0px 0px 0px'}}>
                        ● de la Fuente., G. R. R. G. A. M. (s. f.). PRIMEROS PASOS CON mBLOCK - I (makeblock education)
                        . www.revistas.educa.jcyl.es. Recuperado 9 de marzo de 2022, de <span style={{color:'red'}}>Amplía información aquí</span>   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="http://revistas.educa.jcyl.es/revista_digital/index.php?option=com_content&view=article&id=3981&catid=84&Itemid=87">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                        </p>


                        <p style={{padding:'10px 0px 0px 0px'}}>
                        ● GitHub - ElCableAmarillo/Introduccion-a-Arduino: Breve introducción a la electrónica 
                        con Arduino. (s. f.-b). GitHub. Recuperado 9 de marzo de 2022, de <span style={{color:'red'}}>Amplía información aquí</span>   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://github.com/ElCableAmarillo/Introduccion-a-Arduino#mBlock">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                        </p>


                        <div style={{padding:'10px 0px 0px 0px'}}>

          <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/mblock2.png" alt="" />

                                 </div>
                      
                      </div>
                  </div>
                  <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
              </div>
          </div>
         
      </div>


     


      {/* paper 13 */}
      <div id="movPage13" className="page">
          <div className="front">
              <div className="front__content background-page2">
                  <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                      <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>
                  </div>
                  <div className="front__content-text">
                      <div style={{height:'97%'}}>
                      {/*fin*/}
                      </div>
                  </div>
                  <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                      {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                      </a>*/}
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
  }
}

export default Guia3Naidi1;
