import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia13 */
class Guia1Naidi4  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
       {/* paper 1 */}
       <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                        <h4>Módulo 4</h4>
                                        <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>
                                    </div>
                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Sensores y actuadores</h4></div>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div style={{display:'flex'}} >
                                        <div>
                                            <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>
                                            <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>
                                        </div>
                                        <div className="container" style={{width:'190%'}}>
                                            <p> 
                                                Estimados lectores, en el nivel que estamos, ya somos capaces 
                                                de hacer muchísimas cosas, con la lógica, los conocimientos adquiridos hasta el momento y una buena imaginación 
                                                podemos ser testigos de grandes inventos que pueden aportar al crecimiento tanto personal como cada uno de sus 
                                                territorios. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       ¡Pero aún falta!! Por lo tanto, a partir de ésta guía empezarás a 
                                       dominar los demás componentes que hacen de la robótica un 
                                       mundo maravilloso.

                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>                              
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
               
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Conociendo los sensores y actuadores</h4> <h4 style={{ fontWeight:'bold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>

                                    Al culminar esta guía, los estudiantes estarán en la capacidad de dominar el concepto de sensores, 
                                    actuadores al igual que las entradas y salidas analógicas, de la misma forma, aprenderán a dominar 
                                    una resistencia variable o potenciómetro y las configuración del mismo en el programa mBlock
                                        

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Aprender conceptos de sensores y actuadores.

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px', wordSpacing:'0.3em', textAlign:'left'}}>
                                     ● Aprender conceptos de entradas y salidas analógicas y formas de usarlas en el programa mBlock
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px', wordSpacing:'0.3em', textAlign:'left'}}>
                                     ● Aprender conceptos de potenciómetro y configuración en el programa mBlock.
                                     </p>

                                    
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: Sensores y Actuadores</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    
                                     <b>Sensores</b>
                                    
                                    
                                    <p style={{ padding:'0px 0px 5px 0px',wordSpacing:'0.1em'}}>
                                    Un sensor es un dispositivo capaz de detectar magnitudes físicas o químicas, llamadas variables de 
                                    instrumentación, y transformarlas en variables eléctricas. Las variables de instrumentación pueden 
                                    ser por ejemplo: temperatura, intensidad lumínica, distancia, aceleración, inclinación, 
                                    desplazamiento, presión, fuerza, torsión, humedad, movimiento, pH, etc. Una magnitud eléctrica 
                                    puede ser una resistencia eléctrica (como en una RTD), una capacidad eléctrica (como en un sensor 
                                    de humedad o un sensor capacitivo), una tensión eléctrica (como en un termopar), una corriente 
                                    eléctrica (como en un fototransistor), etc.
                                   </p>

                                   <p>
                                   Los sensores se pueden clasificar en función de los datos de salida en:

                                   </p>

                                   <p>
                                   ● Digitales
                                   </p>

                                   <p>
                                   ● Analógicos
                                   </p>

                                   <p>
                                   ● Comunicación por Bus
                                   </p>

                                 

                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                                
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                <p>
                                   Los sensores van conectados a las entradas de Arduino.
                                   </p>

                                   <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'65%', display:'block', margin:'auto'}} src="./img/sensor1.png" alt="" />

                                      </div>

                                      <p>
                                      A la hora de elegir un sensor, debemos leer detenidamente las características y elegir uno que sea 
                                      compatible con nuestro sistema (tensión y voltaje) y que sea sencillo de usar o nos faciliten una 
                                      librería sencilla y potente.
                                      </p>

                                      <b>Actuadores y Periféricos</b>

                                      <p>
                                      Un actuador es un dispositivo capaz de transformar energía hidráulica, neumática o eléctrica en la 
                                      activación de un proceso con la finalidad de generar un efecto sobre un elemento externo. 
                                      </p>


                                      
                                  
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> ¿Para que sirve un arduino?</h5></div>
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  
                                      <p>
                                      Este recibe la orden de un regulador, controlador o en nuestro caso un Arduino y en función a ella genera 
                                      la orden para activar un elemento final de control como, por ejemplo, una válvula.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Existen varios tipos de actuadores como son:
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Electrónicos
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Hidráulicos
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Neumáticos

                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Eléctricos
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Motores
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Bombas
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Los actuadores van conectados a las salidas de Arduino.
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'55%', display:'block', margin:'auto'}} src="./img/actuadores1.png" alt="" />

                                      </div>


                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 

                                        <p>
                                        Periférico es la denominación genérica para designar al aparato o dispositivo auxiliar e 
                                        independiente conectado a la unidad central de procesamiento o en este caso a Arduino. Se 
                                        consideran periféricos a las unidades o dispositivos de hardware a través de los cuales Arduino se 
                                        comunica con el exterior, y también a los sistemas que almacenan o archivan la información, 
                                        sirviendo de memoria auxiliar de la memoria principal.
                                        </p>

                                        <p>
                                        Ejemplos de periféricos:
                                        </p>
                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Pantallas LCD
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Teclados
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Memorias externas

                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Cámaras
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Micrófonos
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Impresoras
                                        </p>
                                        
                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Pantalla táctil
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Displays numéricos
                                        </p>

                                      

                                         
 
                                
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Zumbadores
                                        </p>
                                        

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Indicadores luminosos, etc…
                                        </p>

                                        <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'55%', display:'block', margin:'auto'}} src="./img/periferico1.png" alt="" />

                                      </div>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Para cada actuador o periférico, necesitamos un “driver” o manejador para poder mandar órdenes desde Arduino.

                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Recordar que los pines de Arduino solo pueden manejar un máximo de 40mA y recomendable usar 20mA de forma continua.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Recordar que Arduino solo puede manejar un total de 200 mA de salida. Es decir que la 
                                      corriente máxima que admite Vcc y GND son 200 mA.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Recordar que los pines Arduino solo pueden tener los valores de 5V (3.3V en algunos modelos) 
                                      y 0V. No es posible cualquier otro valor de tensión.
                                      </p>

                                      <p>
                                      <span style={{color:'red'}}>Amplía información aquí</span>
                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2016/12/18/sensores-y-actuadores/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                      </p>
                                     
                                    
                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                <div style={{display:'flex', justifyContent:'space-between',padding:'0px 0px 5px 0px'}} >

                                      <div style={{padding:'0px 10px 0px 0px'}}>

                            <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                              </div>

                            <div className="container" style={{width:'95%'}}>


                  <p> Para que entiendan con más facilidad  los conceptos anteriores reproduce el 
                siguiente video :<span style={{color:'red'}}> : ¡Qué Voltaje! - Actuadores y Sensores</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=KciHoz9QC-Y">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                            </p>

                              </div>

                              </div>

                              <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> Actividad</h5></div>



                          <p style={{fontFamily:'Montserrat-SemiBold'}}>   
                        ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p> 

                        <p style={{padding:'5px 0px 5px 0px'}}>
                        Para verificar que los conocimientos se hayan comprendido 
                        correctamente, el formador hará una divertida competencia en 
                        vivo, la cual consiste en proyectar unas imágenes por medio de la 
                        aplicación  <span style={{color:'red'}}>Kahoot</span><span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 2px'}}><a rel="noreferrer" target='_blank' href="https://kahoot.com/"><FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/></a></span>, y los grupos conectados a la misma aplicación 
                        por medio de las tablets, computadores o celulares deberán 
                        rápidamente seleccionar si son sensores, actuadores o periféricos.


                        </p> 

                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> Entradas y Salidas Analógicas</h5></div>

                                        <p style={{padding:'5px 0px 5px 0px'}}>
                                        Una señal eléctrica analógica es aquella en la que los valores de la tensión o voltaje varían 
                                        constantemente y pueden tomar cualquier valor. En el caso de la corriente alterna, la señal analógica
                                         incrementa su valor con signo eléctrico positivo (+) durante medio ciclo y disminuye a continuación 
                                        con signo eléctrico negativo (–) en el medio ciclo siguiente.
                                        </p>

                             
 
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <h5>Entradas Analógicas en Arduino</h5>

                                  <p>
                                  Los microcontroladores de Arduino contienen en la placa un conversor analógico a digital de 6 
                                  canales. El conversor tiene una resolución de 10 bits, devolviendo enteros entre 0 y 1023. Los pines 
                                  analógicos de Arduino también tienen todas las funcionalidades de los pines digitales. Por lo tanto, 
                                  si necesitamos más pines digitales podemos usar los pines analógicos. La nomenclatura para los 
                                  pines analógicos es A0, A1, etc…
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'55%', display:'block', margin:'auto'}} src="./img/arduino19.png" alt="" />

                                      </div>

                                      <h5>Salidas Analógicas. PWM</h5>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Como hemos dicho Arduino Uno tiene entradas analógicas que gracias a los conversores analógico 
                                      digital puede entender ese valor el microcontrolador, pero no tiene salidas analógicas puras y para 
                                      solucionar esto, usa la técnica de PWM.
                                      </p>

                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Algunos pines digitales pueden usarse como salidas analógicas PWM:
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'55%', display:'block', margin:'auto'}} src="./img/arduino20.png" alt="" />

                                    </div>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Las Salidas PWM (Pulse Width Modulation) permiten generar salidas analógicas desde pines 
                                    digitales. Arduino Uno no posee salidas analógicas puras

                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    En la actualidad existen muchos circuitos integrados en los que se implementa la modulación PWM, 
                                    por ejemplo para lograr circuitos funcionales que puedan controlar fuentes conmutadas, controles 
                                    de motores, controles de elementos termoeléctricos, choppers para sensores en ambientes ruidosos 
                                    y algunas otras aplicaciones.
                                    </p>
                                    <p>

                                    <span style={{color:'red'}}>Amplía información aquí</span>
                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2017/10/22/entradas-y-salidas-analogicas-arduino-pwm/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                    </p>

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                              
                                    
                                <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 10px 0px'}} >

                                          <div style={{padding:'0px 10px 0px 0px'}}>

                                <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                          </div>

                                        <div className="container" style={{width:'95%'}}>


                              <p> 
                              Ahora que hemos aprendido a trabajar con las señales analógicas, ya sea para entrada 
                              o salida, es hora de que hagamos algo de práctica, pero antes vamos a conocer un 
                              nuevo componente.
                              </p>

                                        </div>

                                        </div>


                                        <h5>¿Qué es un potenciómetro? </h5>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        Un potenciómetro es un resistor eléctrico con un valor de resistencia variable y generalmente 
                                        ajustable manualmente. Los potenciómetros utilizan tres terminales y se suelen utilizar en circuitos 
                                        de poca corriente, para circuitos de mayor corriente se utilizan los reostatos. En muchos dispositivos 
                                        eléctricos los potenciómetros son los que establecen el nivel de salida. Por ejemplo, en un altavoz el 
                                        potenciómetro ajusta el volumen; en un televisor o un monitor de ordenador se puede utilizar para 
                                        controlar el brillo.

                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        El valor de un potenciómetro viene expresado en ohmios (símbolo Ω) como las resistencias, y el valor 
                                        del potenciómetro siempre es la resistencia máxima que puede llegar a tener. 
                                        </p>

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  El mínimo 
                                  lógicamente es cero. Por ejemplo, un potenciómetro de 10KΩ puede tener una resistencia variable 
                                  con valores entre 0Ω y 10.000Ω.
                                  </p>

                                  <div style={{padding:'7px 0px 5px 0px'}}>

                                <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/potencia1.png" alt="" />

                                  </div>

                                  <p>
                                  El potenciómetro más sencillo es una resistencia variable mecánicamente. Los primeros 
                                  potenciómetros y más sencillos son los reóstatos.
                                  </p>


                                  <div style={{padding:'7px 0px 5px 0px'}}>

                          <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/potencia2.png" alt="" />

                                               </div>

                                               <p  style={{padding:'5px 0px 5px 0px'}}>
                                             <span style={{fontFamily:'Montserrat-SemiBold'}}> En Arduino la frecuencia de PWM</span>  es de 500Hz. Pero es un valor que puede modificarse en caso 
                                               que lo necesitemos.En la actualidad existen muchos circuitos integrados en los q+yla modulación 
                                               </p>


                                          


                               

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <b>Construcción de un potenciómetro</b>  

                                <p>
                                Existen dos tipos de construcción de un potenciómetro
                                </p>

                                <p>
                                  <span style={{fontFamily:'Montserrat-SemiBold'}}>Impresos: </span> 
                                  Realizadas con una pista de carbón o de cermet sobre un soporte duro como papel baquelizado 
                                  (cartón prespan), fibra de vidrio, baquelita, etc. La pista tiene sendos contactos en sus extremos y un 
                                  cursor conectado a un patín que se desliza por la pista resistiva.
                                </p>


                                <div style={{padding:'5px 0px 5px 0px'}}>

                   <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/potencia3.png" alt="" />

                                                       </div> 

                                   <p>
                                  <span style={{fontFamily:'Montserrat-SemiBold'}}>Bobinados: </span> 
                                  Consiste en un arrollamiento toroidal de un hilo resistivo con un cursor que mueve un patín sobre el mismo.
                                </p>


                                      <div style={{padding:'5px 0px 5px 0px'}}>

                                <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/potencia4.png" alt="" />

                                    </div> 


                         
                                     
                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tipos de potenciómetros</h5></div>

                                                           
                                     <p  style={{ padding:'5px 0px 5px 0px'}}> 
                                     Los potenciómetros los podemos clasificar de dos maneras según su aplicación.
 
                                      </p>

                                     <p>
                                      <span style={{fontFamily:'Montserrat-SemiBold'}}>Potenciómetros de Mando: </span> Son adecuados para su uso como elemento de control de la tensión en los aparatos electrónicos. El 
                                      usuario acciona sobre ellos para variar los parámetros normales de funcionamiento. Por ejemplo, el volumen de un aparato de audio.
                                     </p>

                                     <p>
                                     Dentro de los mando podemos encontrar. 
                                     Se controlan girando su eje, son los más usados por que son de larga duración y usan poco espacio.
                                     </p>

                                     <div style={{padding:'5px 0px 5px 0px'}}>

                  <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/potencia5.png" alt="" />

                                      </div> 

                                      <p  style={{padding:'5px 0px 5px 0px'}}>
                                      <span style={{fontFamily:'Montserrat-SemiBold'}}>Potenciómetros de Mando: </span> El recorrido del cursor es de forma recta,
                                       se utilizan en ecualizadores gráficos
                                      </p>


                                      <div style={{padding:'5px 0px 5px 0px'}}>

                          <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/deslizante.png" alt="" />

                                          </div> 



                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Aplicación</h5></div>

                                      <p style={{padding:'3px 0px 5px 0px'}}>                                    
                                  <span style={{fontFamily:'Montserrat-SemiBold'}}>Potenciómetros de ajuste: </span> Controlan la tensión presentándola, normalmente en fábrica. El usuario no suele tener que retocar,
                                  por lo que no suelen ser accesibles desde el exterior. Existen tanto encapsulados en plástico como
                                  sin cápsula, y se suelen distinguir potenciómetros de ajuste vertical, cuyo eje de giro es vertical, y
                                  potenciómetros de ajuste horizontal, con el eje de giro paralelo al circuito impreso.
                                  </p>

                                  


                                  <div style={{padding:'3px 0px 5px 0px'}}>

                      <img style={{width:'20%', display:'block', margin:'auto'}} src="./img/potencia6.png" alt="" />

                                                    </div> 


                                                    
                                      <p style={{padding:'3px 0px 5px 0px'}}>                                    
                                  <span style={{fontFamily:'Montserrat-SemiBold'}}>También existen los potenciómetros digitales: </span> Se usan para sustituir a los mecánicos simulando
                                su funcionamiento y evitando los problemas mecánicos de estos últimos.                        
                                
                                      </p>
                             

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    <p>
                                    Está formado por un
                              circuito integrado que simula el comportamiento de su equivalente analógico. Tienen un divisor
                            resistivo (divisor de tensión) con n+1 resistencias.
                            <span style={{color:'red'}}>Amplía información aquí</span>
                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ingmecafenix.com/electronica/potenciometro/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       

                                    </p>


                                    <div style={{padding:'3px 0px 5px 0px'}}>

                        <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/potencia7.png" alt="" />

                                                    </div> 




                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Configuración en mBlock</h5></div>

                                  <p>
                                  Para hacer lectura de un sensor que esté conectado por pin analógido, lo que debemos hacer es 
                                  usar el bloque que se encuentra en el grupo pin que se llama "lee pin analogico" y 
                                  configurar en él, el número del pin analógico donde tenemos conectado nuestro sensor en el 
                                  arduino (No es necesario escribir la A, ya que los pines análogos tradicionales sólo actúan como 
                                  pines de entrada en el arduino uno). 
                                  </p>

                                  <div style={{padding:'5px 0px 5px 0px'}}>

                        <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/mblock3.png" alt="" />

                                            </div>

                             

      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <p>
                                  para usar la lectura del sensor, tenemos dos formas de hacerlo: 
                                  La primera es almacenar los valores en una variable previamente creada y luego usarla en donde 
                                  necesitemos que actúen esos valores dentro de nuestro algoritmo.
                                  </p>

                                  <div style={{padding:'5px 0px 5px 0px'}}>

            <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/mblock4.png" alt="" />

                            </div>

                            <p>
                            la otra forma es usarlo directamente dentro de una estructura de control, ya sea un condicional o un ciclo.

                            </p>

                            <div style={{padding:'5px 0px 5px 0px'}}>

        <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/mblock5.png" alt="" />

                                                    </div>

                                                    <p>
                                                    Los pines de salida analógicos o PWM, los usamos igual que los pines digitales, simplemente es escoger el bloque "pon la salida PWM", donde solo es modificar el pin donde esté 
                                                    conectado el actuador que queremos dominar y poner el correspondiente valor o correspondientes 
                                                    valores que queremos propiciar, generalmente se usan los valores del sensor leído ya sea directo, por 
                                                    variable o según sea el caso otros valores cambiantes que los controle alguna estructura dentro del algoritmo que estemos creando.
                                                    </p>

                                                    <div style={{padding:'5px 0px 5px 0px'}}>

                                 <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/mblock7.png" alt="" />

                                                              </div>    


    
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 20 */}
                 <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <b>Usar el potenciómetro en mBlock</b>

                                  <p>
                                  Para configurar el potenciómetro debemos tener en cuenta que los valores del mismo van en un 
                                  rango de 0 a 1023, por lo cual usar con exactitud esos datos para hacer pequeños rangos con 
                                  exactitud requiere de hacer ensayos a prueba y error. Por lo tanto, si lo convertimos a porcentaje es 
                                  mucho más fácil de manipular, para ello creamos una variable y convertimos esos valores con un 
                                  bloque de mapeo que lo encontramos en el grupo de bloques Datos de la siguiente manera: 
                                  </p>


                                  <div style={{padding:'5px 0px 5px 0px'}}>

                            <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/mblock8.png" alt="" />

                                            </div>    


                                            <p>
                                            como podemos observar, creamos una variable potenciómetro y en ella guardamos los datos 
                                            convertidos a porcentajes usando el bloque mapear, que en sus características agregamos la 
                                            lectura del potenciómetro conectado en el pin analógico A0, y configuramos el rango que recibe de 
                                            0 a 1023 convirtiéndolo a un nuevo rango de 0 a 100.
                                            </p>

                                            <p style={{padding:'5px 0px 0px 0px'}}>
                                            Ya con los datos en porcentaje podemos usar esos valores en cualquier estructura condicional, 
                                            repetitiva, darle salida por un PWM a un actuador o simplemente mostrarlos por puerto serial.
                                            </p>

                                  
                          </div>

                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 21 */}
                <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>
                                  

                                    
                                


                        <p> 
                        ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        En la siguiente actividad construiremos un prototipo el cual 
                        llamaremos medidor de potencia, el cual consiste en conectar 3 
                        diodos LED, con los cuales crearemos tres rangos según 
                        porcentaje configurado para un potenciómetro haciéndoles 
                        encender y/o apagar según movamos manualmente los niveles 
                        del mismo.

                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● El formador de su territorio, les suministrará los siguientes componentes:
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● 1 Placa Arduino       
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● 1 placa de prototipos (Protoboard)
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ●  3 Diodos LED de diferentes colores
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● 3 Resistencias de 220 Ω
                        </p>
                        
                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● 1 Potenciometro de 10 kΩ
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        ● Cables Jumpers M-M
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        El esquema de circuito que van a construir es el siguiente:
                        </p>

                        <div style={{padding:'5px 0px 5px 0px'}}>

                            <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino21.png" alt="" />

                                            </div> 

                        
                          </div>

                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>
                              

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● 1 Documento en excel generado por la aplicación Kahoo, en donde se muestra el detalle de 
                              las respuestas que realizaron los equipos participantes. 
                              </p>

                              <p style={{padding:'10px 0px 0px 0px'}}>
                              ● Video y archivo.mblock de cada grupo con el reto completado. 
                              </p>

                              <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                              <p style={{padding:'10px 0px 0px 0px'}}>
                              ● Sensores y Actuadores. (2016, 18 diciembre). Aprendiendo Arduino.
                              <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2016/12/18/sensores-y-actuadores/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       

                              </p>

                              <p style={{padding:'10px 0px 0px 0px'}}>
                              ● Entradas y Salidas Analógicas Arduino. PWM. (2021, 17 marzo). Aprendiendo Arduino
                              <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprendiendoarduino.wordpress.com/2017/10/22/entradas-y-salidas-analogicas-arduino-pwm/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       

                              </p>

                               
                                
                          </div>

                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 23 */}
                <div id="movPage23" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    {/*fin*/}
                                </div>
                            </div>
                            <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
  }
}

export default Guia1Naidi4;
