import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia14 */
class Guia2Naidi4  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
       {/* paper 1 */}
      <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 4</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>Sensores y actuadores    </h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p> 
                                          Estimados lectores, con el conocimiento que ya tienen 
                                          pueden y con mucha práctica ya pueden ir creando grandes 
                                          soluciones a problemáticas que se encuentren en su territorio.
                                          </p>

                                          <p>
                                          Pero lo cierto es que aún no hemos terminado, vamos a ir 
                                          conociendo cada vez más componentes que nos ayudarán a 
                                          ir encaminándonos en lograr los objetivos que esperamos.
                                          </p>
                                            
                                                          
                                            </div>

                                            </div>
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       entonces, en esta guía aprenderán a dominar un componente 
                                       que es de mucha importancia tanto para el conocimiento 
                                       como para la construcción de grandes soluciones, puesto que 
                                       es usado en muchas áreas de la industria.

                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          

                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                 
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px', textDecorationLine:'underline', color:'red'}}><h4 style={{fontFamily:'Montserrat-SemiBold' }}>Medicion de distancia con ultrsonido.</h4> <h4 style={{ fontFamily:'Montserrat-SemiBold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                    
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de dominar el sensor ultrasonido y 
                                    construir un circuito electrónico para realizar un sistema de medición de distancia.
                                        

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Aprender conceptos específicos de sensor ultrasonido

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Construir circuito electrónico para medir distancias. 
                                     </p>

                                
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                    
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2.5em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: ¿Qué es un sensor ultrasónico?</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    El sensor ultrasónico realiza mediciones mediante el uso de ondas ultrasónicas. El funcionamiento 
                                    del sensor ultrasónico es simple: cuando el impulso de onda encuentra un objeto, es reflejado y así 
                                    es medido en distancia.
                                    </p>

                                    <div style={{padding:'5px 0px 0px 0px'}}>

                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/ultrasonico1.png" alt="" />

                                                        </div>


                                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                                        Teóricamente parecería complejo comprender qué es un sensor ultrasónico, sin embargo, su uso en 
                                                        la industria es muy amplio. Este dispositivo es utilizado en el sector de materiales y su manejo, la 
                                                        industria de equipos móviles, detección y hasta en llenado de productos.

                                                        </p>


                                                        <div style={{padding:'5px 0px 0px 0px'}}>

                                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                                          </div>
       
                                  
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                               
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <b style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>Funcionamiento del sensor ultrasónico.</b>

                                  <p  style={{padding:'5px 0px 0px 0px'}}>
                                  La tecnología ultrasónica funciona basándose en principios acústicos. El sensor emite pulsaciones 
                                  acústicas cortas a través del aire, y de esta forma detecta objetos que después refleja. Lo que mide el 
                                  sensor es el tiempo entre emitir el pulso y la recepción de este.
                                  </p>


                                  <div style={{padding:'5px 0px 0px 0px'}}>

                    <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/ultrasonico2.png" alt="" />

                                                </div>

                                                <p style={{padding:'5px 0px 0px 0px'}}>
                                                Esta es la razón por la que el sensor ultrasónico es de los instrumentos más confiables en medición. 
                                                Casi todo tipo de material es capaz de reflejar ondas acústicas o sonido, haciendo que las mediciones 
                                                sean limpias.
                                                </p>

                                                <p style={{padding:'5px 0px 0px 0px'}}>
                                                Las inclemencias de ambientes hostiles como el polvo no afectan a los sensores ultrasónicos, por lo 
                                                que sus mediciones son más estables y precisas. El sensor puede incluso hacer mediciones tan breves 
                                                como 0,025 mm.
                                                </p>
                      
                                      
                                   
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                        <p>
                                      <span style={{fontFamily:'Montserrat-SemiBold', padding:'5px 0px 0px 0px'}}> Aplicaciones de amplio espectro:</span> La razón por la que la tecnología del sensor ultrasónico es tan valiosa para la industria de la 
                                      automatización radica en su flexibilidad práctica. Los sensores ultrasónicos pueden ser utilizados en 
                                      muchos tipos de manufactura con resultados destacados.
                                        </p>

                                        <p>
                                      <span style={{fontFamily:'Montserrat-SemiBold', padding:'5px 0px 0px 0px'}}> Producción a granel: </span> Un sector industrial común que exigen mediciones precisas para sus actividades de producción. El 
                                      sensor ultrasónico es utilizado para regular el llenado de contenedores industriales y evitar así su desbordamiento.
                                        </p>

                                        <div style={{padding:'8px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/ultrasonico3.png" alt="" />

                                                </div>


                                          




                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                     <p>
                                      <span style={{fontFamily:'Montserrat-SemiBold', padding:'5px 0px 0px 0px'}}> Seguridad en plataformas: </span> 
                                      La industria de la construcción suele utilizar grúas, eslingas de gran tamaño y plataformas de 
                                      movimiento aéreo. Los sensores ultrasónicos ayudan a mantener un tráfico eficiente entre 
                                      plataformas, manteniendo la seguridad en lugar de trabajo.
                                        </p>

                                            
                                        <div style={{padding:'8px 0px 0px 0px'}}>

                        <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/ultrasonico4.png" alt="" />

                                                </div>


                                        <p>
                                      <span style={{fontFamily:'Montserrat-SemiBold', padding:'5px 0px 0px 0px'}}> Detección de autos: </span> 
                                      Este es tal vez el ejemplo de uso de sensores ultrasónicos más claro y común, no solo para el sector 
                                      industrial. Los estacionamientos públicos utilizan sistemas de pluma para la entrada y salida de 
                                      vehículos. Los sensores ultrasónicos se encargan de evitar que una pluma baje sobre un coche 
                                      mientras este está debajo.
                                        </p>


                                        <div style={{padding:'8px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/ultrasonico5.png" alt="" />

                                                        </div>






                                         
 
                                
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    <p>
                                    El funcionamiento del sensor ultrasónico es ideal para muchos tipos de industrias, y existen muchos 
                                    más ejemplos además de los mencionados. Esta tecnología, sin duda, cuenta con las características 
                                    ideales para asegurar la calidad, seguridad y flexibilidad de proyectos industriales a gran escala
                                    <span style={{color:'red'}}> Amplía información aquí</span>
                                 <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.autycom.com/que-es-un-sensor-ultrasonico-y-para-que-sirve/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                    </p>


                                    <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

<                                                 div style={{padding:'0px 10px 0px 0px'}}>

                                    <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                                      </div>

                                    <div className="container" style={{width:'95%'}}>


                                    <p> Para que entiendan con más facilidad los conceptos anteriores reproduce el siguiente video :<span style={{color:'red'}}> Como (funciona / se usa) un sensor ultrasónico | Arduino</span>
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=tdRCGb5sFdI&list=PLHsWxcSr3WR4BTj_Wp6QDKJY3Qa7CV1Vn&index=2&t=671s">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                                    </p>

                                        </div>

                                        </div>

                                        <div style={{backgroundColor:'#ef7a51ff', height:'2.5em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conexión y Programación de un sensor ultrasonido</h5></div>

                                            <p style={{padding:'5px 0px 0px 0px'}}>
                                            El sensor ultrasonido, cuenta con 4 pines para su conexión, los cuales son en su orden de de izquierda 
                                            a derecha y mirándolo de frente tenemos como primero el Vcc que es el pin de alimentación, en el 
                                            cual vamos a conectar el 5 voltios o positivo de nuestro arduino, seguido esta el pin Trig que es el pin 
                                            de entrada de señal y lo vamos a conectar en alguno de nuestros pines digitales del arduino, sin 
                                            importar si es normal o PWM.
                                            </p>

                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  luego tenemos el pin Echo que es aquel pin con función de generar 
                                  una salida ultrasónica y lo podemos conectar en cualquier pin digital o PWM de nuestro arduino, por 
                                  ultimo tenemo el pin GND con el que vamos a cerrar nuestro circuito a tierra, y es un pin que va a 
                                  estar presente en todos los componentes que usamos en robótica el cual debe ir directamente al 
                                  GND de nuestro arduino, ya sea por medio de la protoboard o directo..

                                        </p>

                                        <div style={{padding:'8px 0px 0px 0px'}}>

                <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/ultrasonico6.png" alt="" />

                                               </div>

                                               <p>
                                               el diagrama de conexión de nuestro sensor ultrasonido es el siguiente:
                                               </p>

                                               <div style={{padding:'8px 0px 0px 0px'}}>

                        <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/ultrasonico7.png" alt="" />

                                              </div>



                                      
                                     
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    

                               <p>
                               Para usarlo en nuestro programa mBlock, lo podemos hacer almacenando en una variable o usando 
                               directamente cualquier estructura de control el bloque
                               </p>

                               <div style={{padding:'5px 0px 5px 0px'}}>

                    < img style={{width:'100%', display:'block', margin:'auto'}} src="./img/mblock9.png" alt="" />

                                                  </div>

                                                  <p>
                                                  Digitando los números de pines donde tenemos conectado nuestro sensor, teniendo en cuenta que 
                                                  pin de activación corresponde al pin donde esta conectado el Trig del ultrasonido, y el pin de eco 
                                                  donde tenemos conectado el pin Echo.
                                                  </p>

                   <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>

                        
                   <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 5px 0px'}} >

                            <div style={{padding:'0px 10px 0px 0px'}}>

                            <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                    </div>

                        <div className="container" style={{width:'95%'}}>


                        <p> 
                        ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p>

                        <p>
                        En la siguiente actividad construiremos un prototipo que lo
                        llamaremos metro ultrasónico, el cual va a consistir en hacer un
                        arreglo de 5 diodos LED en la protoboard, y configurar el
                        ultrasonido para que cada 10 centímetro vaya encendiendo un
                        led sucesivamente cuando detecte la presencia de un objeto a esas distancias
                        </p>

                </div>

            </div>



                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                   Esto quiere decir que si hay un objeto entre 0 y 10 
                                  centímetros deben estar encendido el LED número 1, si el objeto está entre 10 y 20 centímetros, deben estar encendidos los dos 
                                  primeros y así sucesivamente hasta que cuando el objeto esté entre 40 y 50 centímetro estén encendidos todos. si el objeto está 
                                  a más de 50 centímetros que se genere un sonido con un buzzer 
                                  y por último si no se detecta ningún objeto pues todos los LEDs deben estar apagados y el buzzer en silencio.
 
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  El formador de su territorio, les suministrará los siguientes componentes:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 Placa Arduino
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 placa de prototipos (Protoboard)

                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 5 Diodos LED de diferentes colores
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 5 Resistencias de 220 Ω
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● 1 Buzzer o zumbador
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Cables Jumpers M-M
                                  </p>

                                  <div style={{padding:'8px 0px 0px 0px'}}>

            <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                             </div>


                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 13 */}
                  <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p>
                                El esquema de circuito que van a construir es el siguiente:
                                </p>


 
                                  
                                  <div style={{padding:'8px 0px 0px 0px'}}>

            <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino22.png" alt="" />

                                             </div>

                                             <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>

                                             <p style={{fontFamily:'Montserrat-SemiBold', padding:'10px 0px 10px 0px' }}>
                                             ● Video y archivo.mblock de cada grupo con el reto completado
                                             </p>

                                             <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                             <p style={{padding:'10px 0px 0px 0px'}}>
                                             ● Gandhi, M. (2019, 19 diciembre). Qué es un sensor ultrasónico y para qué sirve. AUTYCOM. 
                                             <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.autycom.com/que-es-un-sensor-ultrasonico-y-para-que-sirve/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                             </p>






                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



             

                {/* paper 14 */}
                <div id="movPage14" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia2Naidi4;
