import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* guia 4 */
class Guia4Naidi1  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
          {/* paper 1 */}
          <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada1">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 1</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Conceptos basicos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p style={{textAlign:'left'}}> 
                                          Estimados lectores, en las guías anteriores hemos venido
                                          introduciéndonos en conceptos esenciales que debemos
                                          saber para poder iniciar a desarrollar proyectos con robótica.
                                          Hemos aprendido conceptos desde lo más mínimo que es la
                                          electricidad hasta conocer el software que usaremos para
                                          poder realizar las respectivas programaciones de nuestros
                                          proyectos en éste Valle del Naidí.
                                          </p>

                                            
                                                          
                                            </div>

                                            </div>
                                              
                                        
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       En ésta guía ya entraremos a conocer en sí lo que es el mundo
                                      de la robótica, iniciando desde lo más básico, y a medida que
                                      vamos avanzando en el proyecto, el grado de complejidad será
                                      mayor hasta lograr los objetivos principales.

                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                   
                   
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Introduccion la robotica </h4> <h4 style={{ fontWeight:'bold'}}> Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                    
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de entender el concepto de Robot, 
                                    Robótica, y reconocer en qué áreas del diario vivir en el mundo se puede aplicar.
                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'10px 0px 0px 0px'}}>
                                     ● Aprender conceptos básicos de Robot y Robótica

                                     </p>

                                     <p style={{ padding:'10px 0px 0px 0px'}}>
                                     ● Identificar las áreas en las que se puede aplicar la robótica según sectores profesionales.
                                     </p>

                                     
                                    <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/valle4.png" alt="" />

                                        </div>

                                    

                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
               
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: ¿Qué es un Robot? </h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  

                                    <p style={{ padding:'0px 0px 5px 0px'}}>
                                   <span style={{fontFamily:'Montserrat-SemiBold'}}>Según la NASA:</span> "Los robots son máquinas que se pueden utilizar para hacer trabajos."
                                   </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      La RAE (real academia de la lengua) tiene 2 definiciones para robot:
                                      </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      1. Máquina o ingenio electrónico programable, capaz de manipular objetos y 
                                        realizar operaciones antes reservadas solo a las personas.

                                      </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      2. Programa que explora automáticamente la red para encontrar información.
                                      </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      Según Oxforddictionaries.com: Un robot es una máquina capaz de llevar a cabo una serie compleja 
                                      de acciones programadas automáticamente
                                      </p>

                                      <p  style={{ padding:'5px 0px 0px 0px'}}>
                                      Por lo tanto, "Un robot es un dispositivo mecánico que es capaz de realizar una variedad de 
                                      tareas, de acuerdo a unas instrucciones programadas por adelantado."
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                                   </div>


                               
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                            
                               
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                              <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/robot1.png" alt="" />

                               </div>

                               <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tipos de robots</h5></div>

                               <p style={{ padding:'5px 0px 0px 0px'}}>
                               Habitualmente se solían clasificar en dos tipos, de servicio e industriales:
                             </p>

                             <p style={{ padding:'5px 0px 0px 0px'}}>
                             ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Los robots industriales:</span> Los robots industriales son robots que se utilizan en un entorno de fabricación industrial
                             </p>

                             <p style={{ padding:'5px 0px 0px 0px'}}>
                             Por lo general, éstas suelen ser articulaciones y brazos desarrollados específicamente para
                             aplicaciones tales como la soldadura, manejo de materiales, unión de piezas, pintura y otros.
                            También podríamos incluir algunos vehículos guiados automáticamente.

                             </p>


                                 
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p> 
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Los robots de servicio:</span> Los japoneses están a la vanguardia en este tipo de robots.
                                    En esencia, esta categoría se compone de cualquier robot que se utiliza fuera de una instalación
                                    industrial, aunque pueden ser subdivididos en dos tipos principales: robots utilizados para trabajos
                                    profesionales, y la segunda, robots que se utilizan para uso personal.  
                                     </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      La creación del humanoide caminando llamado Asimo dio el impulso para varios otros.
                                      Hoy en día tenemos robots para el cuidado de ancianos, de uso militar, levantar personas, incluso
                                      robots que son capaces de jugar al fútbol
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/robots2.png" alt="" />

                                      </div>
                        
                                 
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                        <p>
                                        Dada la gran evolución y desarrollo de la robótica es necesario hacer una clasifi-cación más concreta, 
                                        ya que estos son dos grupos muy amplios donde se po-drían incluir otros subgrupos mucho más 
                                        pequeños.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                          Veamos algunos:
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots domésticos o del hogar:</span> Robots que se utilizan en el hogar.
                                        Este tipo de robots incluye muchos dispositivos diferentes, tales como aspirado-ras robóticas,
                                        limpiadores de piscinas robótica, barrenderos, limpiadores de ca-nalones y otros robots que pueden
                                        hacer diferentes tareas.
                                        </p>
                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Además, algunos robots de vigilancia y telepresencia podrían ser considerados como robots 
                                        domésticos si se usan en ese entorno.

                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}} >

                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots médicos:</span> Robots que se utilizan en la medicina y las instituciones médicas. 
                                        En primer lugar tenemos los robots de cirugía.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Además, algunos vehículos guiados automatizados y algunas máquinas para levantar personas 
                                        también se pueden incluir dentro de este tipo.
                                        </p>

                                        <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/robot3.gif" alt="" />

                                           </div>

                                         
 
                                
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                              
                                        <p>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots militares:</span> Los robots utilizados en aplicaciones militares.
                                        Este tipo de robots incluye robots de desactivación de bombas, diferentes tipos de robots de
                                        transporte, aviones de reconocimiento.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        A menudo, los robots creados inicialmente para fines militares pueden ser utilizados en la búsqueda 
                                        y rescate de personas y otros campos relacionados.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots de Entretenimiento:</span> Estos son robots que se utilizan para el entretenimiento. 
                                        Esta es una categoría muy amplia
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Comienza con robots de juguete con simples movimientos y termina con auténticos pesos pesados
                                        tales como brazos robóticos articulados usados como simuladores de movimientos.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots espaciales:</span> Son los utilizados en el espacio
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Este tipo incluiría robots utilizados en la Estación Espacial Internacional, así como vehículos de Marte 
                                        y otros robots que se utilizan en el espacio.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots Educacionales:</span> Son especiales para enseñar robótica utilizado en escuelas de todo el mundo.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        Seguidores de línea, Lego, sumo-bots y todos aquellos robots que son sólo para el aprendizaje. 
                                        Incluso hay robots que te enseñan a dibujar.

                                        </p>

                                     
                                    
                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                       
                                  ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots Humanoides:</span> Robots con aspecto parecido al humano y que realizan tareas propias de un 
                                  ser humano, incluso expresando emociones.

                                        </p>

                                        <h5 style={{padding:'10px 0px 5px 0px'}}>Otra Clasificación puede ser en función del entorno de trabajo del robot:</h5>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Robots Estacionarios:</span> Estos robots son fijos en un lugar y no se pueden mover.
                                        Esta categoría incluye brazos robóticos, máquinas-herramientas informáticas, y la mayoría de los
                                        robots industriales.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots De Suelo:</span> Estos robots están diseñados para operar en la superficie de la tierra o de otro
                                        planeta, y por lo general están subclasificados por su forma de transmisión del movimiento: ruedas,
                                        pistas o piernas.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Robots Submarinos:</span> También conocidos como vehículos submarinos autónomos, estos están
                                        diseñados para operar bajo el agua, y muchos de ellos a gran profundidad.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Robots Aéreos:</span> Son vehículos aéreos no tripulados e incluyen diversos tipos de máquinas voladoras
                                        robóticas, incluyendo aviones y helicópteros.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Robots de microgravedad:</span> Los robots que han sido diseñados para funcionar en entornos de baja
                                        gravedad, como la órbita terrestre
                                        </p>

                                      
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>


                                  <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/robots4.png" alt="" />

                                </div>

                                <p style={{padding:'10px 0px 0px 0px'}}>
                                Por último hay otros llamados de trabajo en entornos peligrosos, que como su propio nombre indica
                                se desarrollan para trabajar en sitio peligrosos, como un robot para desactivar bombas o también
                                llamado robot de artificieros, o los robots de guerra.
                                </p>


                                <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/robots5.gif" alt="" />

                                </div>



                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <h5>También se pueden clasificar en función de su autonomía:</h5>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Teleoperados:</span> Robots controlados a distancia y que necesitan ser controlados todo el tiempo por un ser humano.
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>

                                  Su control puede ser con cable o sin él. Un ejemplo serían los drones o los robots para hacer operaciones.
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Semi-automáticos:</span> Tiene cierto grado de autonomía, pero siguen siendo controlados por un ser humano.
                              
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  Un robot clasificador de paquetes podría ser semiautomático o incluso una barrera para abrirse 
                                  cuando se le introduce una tarjeta (parking de coches).
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Automáticos:</span> Estos robots pueden tomar sus propias decisiones sin la necesidad de un ser humano.
                                  El más conocido últimamente podría ser el coche sin conductor
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Por último otra clasificación podría ser incluso en función de su tamaño:</span> Robots, Microrobots 
                                  (tamaño de micras) y NanoRobots o NanoBots (tamaño manométrico, el más pequeño).
                                  Amplía información aquí
                                  </p>

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Por último otra clasificación podría ser incluso en función de su tamaño: Robots, Microrobots
                                  (tamaño de micras) y NanoRobots o NanoBots (tamaño manométrico, el más pequeño).
                                 <span style={{color:'red'}}>Amplía información aquí</span>  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.areatecnologia.com/electronica/tipos-de-robots.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  </p>

                                  <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 10px 0px 0px'}}>

                              <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>         

                                    </div>

                              <div className="container" style={{width:'95%'}}>


                        <p> Para que entiendan con más facilidad los conceptos anteriores reproduce el
                          siguiente video : <span style={{color:'red'}}> Todos los robots Boston Dynamics | Evolución Boston Dynamics</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=dN3ZTCup66Q">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                        </p>

                        </div>

                            </div>





                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Qué es la Robótica? </h5></div>

                                  <p  style={{ padding:'5px 0px 0px 0px'}}>
                                  El origen etimológico de la palabra robótica lo encontramos, ni más ni menos, que en el checo. En
                                  concreto, en la unión de dos términos: robota que puede definirse como “trabajo forzado” y en rabota
                                  que es sinónimo de “servidumbre”. 
                                  </p>


                                  <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                                   </div>
                               


                                  

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  De la misma forma, hay que subrayar que la primera vez que
                                  empieza a hacerse referencia más o menos a ella fue en el año 1920 en la obra del escritor Karel Capek
                                  titulada “Los robots universales de Rossum”
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/robots6.png" alt="" />

                                      </div>

                                      <p  style={{ padding:'5px 0px 0px 0px'}}>
                                      La robótica es la ciencia y la técnica que está involucrada en el diseño, la fabricación y la utilización
                                    de robots. Un robot es, por otra parte, una máquina que puede programarse para que interactúe con
                                    objetos y lograr que imita, en cierta forma, el comportamiento humano o animal.
                                      </p>

                                      <p  style={{ padding:'5px 0px 0px 0px'}}>
                                      La informática, la electrónica, la mecánica y la ingeniería son sólo algunas de las disciplinas que se
                                      combinan en la robótica. El objetivo principal de la robótica es la construcción de dispositivos que
                                      funcionen de manera automática y que realicen trabajos dificultosos o imposibles para los seres
                                      humanos.<span style={{color:'red'}}>Amplía información aquí</span>  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://definicion.de/robotica/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>  
                                      </p>



                                 

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 5px 0px 0px'}}>

                                <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>         

                                  </div>

                            <div className="container" style={{width:'95%'}}>


                          <p> Para que entiendan con más facilidad los conceptos anteriores reproduce el
                          siguiente video : <span style={{color:'red'}}> ¿Qué es la Robótica? Para que sirve y cuales son sus disciplinas?!?</span>
                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=WlQNLSKwM5Q">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                        </p>

                      </div>

                      </div>

                            <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Aplicaciones</h5></div>

                            <h5 style={{ padding:'5px 0px 5px 0px'}}> Aplicaciones de la robótica por sectores profesionales </h5>

                           <b> Industria:</b>

                            <p style={{ padding:'5px 0px 0px 0px'}}>No se puede negar que este es uno de los planos que más beneficiado se ha visto por el uso 
                              de la robótica, y algunas actividades en las que juega un papel fundamental son:
                            </p>

                            <p style={{ padding:'5px 0px 0px 0px'}}>
                            ● Transporte de materiales
                            </p>

                            <p style={{ padding:'5px 0px 0px 0px'}}>
                            ●  Montaje
                            </p>

                            <p style={{ padding:'5px 0px 0px 0px'}}>
                            ● Corte mecánico, rectificado, desbardado y pulido

                            </p>

                            <p style={{ padding:'5px 0px 0px 0px'}}>
                            ● Pintura
                            </p>

                            <p style={{ padding:'5px 0px 0px 0px'}}>
                            ● Manipulación de plásticos y otros materiales
                            </p>
                               
                              

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                               
                                <p>
                               ● Tareas peligrosas como soldaduras, implementación de sustancias inhalantes nocivas, 
                               transporte de materiales pesados
                               </p>

                               <p  style={{ padding:'5px 0px 0px 0px'}}>
                               ● Reciclaje
                               </p>

                               <p  style={{ padding:'5px 0px 0px 0px'}}>
                               ● Medición, inspección, control de calidad
                               </p>

                               <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/robots7.png" alt="" />

                        </div>

                       <b> Fisica:</b>

                        <p  style={{ padding:'5px 0px 0px 0px'}}>
                               ● Una de las aplicaciones de la robótica en el plano de la física está presente dentro de la exploración espacial.

                               </p>

                               
                               <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/fisica.png" alt="" />

                        </div>

                                 
                                     

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                 <b>Medicina:</b> 

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  Por supuesto en el campo medicinal la robótica se ha convertido en imprescindible, y algunas tareas 
                                  en las que está presente son:

                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● Mediciones y tareas repetitivas
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● Terapia y rehabilitación: miembros artificiales, robots de soporte a las terapias de rehabilitación
                                    o robots para proveer asistencia personal en hospitales
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● Mejorar procesos quirúrgicos

                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● Almacenaje y distribución de medicamentos
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/medicina.png" alt="" />

                                    </div>

                               

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                  <b>Militar:</b>  

                                <p>
                                Aquí el uso de la robótica se traduce en aporte vital para labores de supervivencia, por ejemplo:
                                </p>

                                <p>
                                ● Desactivar bombas
                                </p>

                                <p>
                                ● Búsqueda y rescate en catástrofes
                                </p>

                                <div style={{padding:'10px 0px 5px 0px'}}>

                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/militar.png" alt="" />

                                </div>

                                <b>Domestica:</b>

                                <p style={{ padding:'5px 0px 0px 0px'}}>
                                En casa el uso de la robótica también está presente, tanto en el plano de la limpieza como en la
                                mecanización de elementos que nos facilitan la vida diaria:
                                </p>

                      

                                <p style={{ padding:'10px 0px 0px 0px'}}>
                                ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Domótica:</span> automatización de viviendas como las persianas, las luces, la calefacción o el
                                aire acondicionado y también la seguridad. Nos permite detectar gases, incendios, intrusos,
                                ayuda a ahorrar electricidad y calefacción. Y por supuesto, se mejora el confort de la vida diaria.
                                </p>


                               
      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <p tyle={{ padding:'5px 0px 0px 0px'}}>
                                ● Aspirador
                                </p>

                                <div style={{padding:'10px 0px 10px 0px'}}>

                    <img style={{width:'55%', display:'block', margin:'auto'}} src="./img/domestica.png" alt="" />

                               </div>

                               <b>Educacion:</b> 

                               <p style={{ padding:'5px 0px 0px 0px'}}>
                               Y en el plano educativo es fundamental hacer mención al uso de la robótica mediante habilidades 
                               STEM: Science, Technology, Engineering & Maths. <span style={{color:'red'}}>Amplía información aquí</span> 
                               <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.flexbot.es/aplicaciones-de-la-robotica/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span>  

                               </p>

                               <div style={{padding:'20px 0px 0px 0px'}}>

            <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/educacion.png" alt="" />

                                    </div>

    
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 20 */}
                 <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                  <div style={{padding:'0px 5px 0px 0px'}}>

                        <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>         

                                        </div>

                        <div className="container" style={{width:'95%'}}>


                  <p> Para que entiendan con más facilidad los conceptos anteriores reproduce el
            siguiente video : <span style={{color:'red'}}>    Microaprendizaje: ¿Dónde se usan los robots?</span>
    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=QlHnO1tRuYA">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                </p>

              </div>

                  </div>

                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Proyecto</h5></div>

                  <p style={{ padding:'5px 0px 0px 0px'}}>
                  Con los conocimientos adquiridos hasta ahora, hemos sido testigos de las
                  enormes posibilidades que tiene la robótica en la vida de todos, y en el
                  desarrollo de nuestras comunidades
                  </p>

                  <p style={{ padding:'5px 0px 0px 0px'}}>

                  Durante la sesiones aprenderán a dominar todas las herramientas
                  necesarias para poder tener las habilidades y destrezas para poder
                  construir y programar un robot, en donde conocerán diferentes
                  componentes que ejecutan muchas tareas interesantes, con las que
                  podrán interactuar y dejar volar la imaginación. 
                  </p>


                        <div style={{padding:'10px 0px 0px 0px'}}>

            <img style={{width:'35%', display:'block', margin:'auto'}} src="./img/valle3.png" alt="" />

                             </div>


                          </div>

                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 21 */}
                <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                 <p style={{ padding:'10px 0px 0px 0px'}}>
                                 Al finalizar las sesiones de capacitación, vendrá una fase muy interesante, y
                                es la fase de preparación para una competencia, en donde cada grupo de 3 participantes se unirá
                                con otro grupo conformando así un nuevo grupo de 6 personas, que tendrán la misión de diseñar,
                                construir y programar un carro robot con las características exigidas “Ya sea teleoperado o
                                autónomo” según las competencias en que se inscriban.
                                 </p>

                                 <p style={{ padding:'10px 0px 0px 0px'}}>
                                 La competencia será entre todos los grupos del Valle del Naidí en cada territorio, quienes se 
                                 enfrentarán y demostrarán ante el público todo lo aprendido.
                                 </p>


                                 <div style={{padding:'20px 0px 0px 0px'}}>

            <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/robots8.png" alt="" />

                            </div>

                            <div style={{padding:'20px 0px 0px 0px'}}>

            <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                  </div>

                                

                          </div>

                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>
                                  
                                <p style={{padding:'5px 0px 0px 0px'}}>
                        ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p>

                        <p style={{padding:'5px 0px 5px 0px'}}>
                        Para verificar que los conocimientos se hayan comprendido
                        correctamente, el formador hará una divertida competencia en
                        vivo, la cual consiste en proyectar unas preguntas por medio de la
                         aplicación  <span style={{color:'red'}}>Kahoot</span><span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 2px'}}><a rel="noreferrer" target='_blank' href="https://kahoot.com/"><FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/></a></span>, y los equipos conectados a la misma por medio
                         de las tablets deberán rápidamente seleccionar la(s) respuesta(s) correcta(s).

                        </p> 

                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>  

                            <p style={{padding:'5px 0px 0px 0px'}}>
                    1 Documento en excel generado por la aplicación Kahoo, en donde se muestra el detalle de las
                      respuestas que realizaron los equipos participantes. 
                                      </p>   
   

                          </div>

                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 23 */}
                     <div id="movPage23" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>
                                  
                                <p style={{padding:'10px 0px 0px 0px'}}>
                                  
                                  ● Tipos de Robots Clasificacion y Clases. (s. f.). www.areatecnologia.com. Recuperado 9
                                  de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.areatecnologia.com/electronica/tipos-de-robots.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  </p>  


                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                  
                                  ● Definición de robótica — Definicion.de. (s. f.). Definición.de. Recuperado 
                                  9 de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://definicion.de/robotica/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                  
                                  </p>  


                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                  
                                  ● Balón, V. (2020, 20 mayo). ¿Cuáles son las aplicaciones de la robótica? –. Flexbot.  
                                  Recuperado 9 de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.flexbot.es/aplicaciones-de-la-robotica/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>   
                                  </p>  

                          </div>

                         </div>
                     <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

            
                {/* paper 24 */}
                <div id="movPage24" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="19" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="19" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia4Naidi1;
