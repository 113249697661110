import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import nguia4 from "../../svg/portadas-naidi/naidi-1-2.svg";
import nguia5 from "../../svg/portadas-naidi/naidi-2-2.svg";
import nguia6 from "../../svg/portadas-naidi/naidi-3-2.svg";
import nguia7 from "../../svg/portadas-naidi/naidi-4-2.svg";
import nguia8 from "../../svg/portadas-naidi/naidi-5-2.svg";
import nguia9 from "../../svg/portadas-naidi/naidi-6-2.svg";

class GuiaNaidi2  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'nguia4')}><Lista courses="Guía I" imgurl={nguia4} imgalt="ELECTRÓNICA BÁSICA - ACUMULADORES DC"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia5')}><Lista courses="Guía II" imgurl={nguia5} imgalt="ALGORITMOS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia6')}><Lista courses="Guía III" imgurl={nguia6} imgalt="VARIABLES Y CONSTANTES"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia7')}><Lista courses="Guía IV" imgurl={nguia7} imgalt="TIPOS DE DATOS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia8')}><Lista courses="Guía V" imgurl={nguia8} imgalt="OPERADORES ARITMÉTICOS Y LÓGICOS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'nguia9')}><Lista courses="Guía VI" imgurl={nguia9} imgalt="USO DE ENTRADAS Y SALIDAS DIGITALES"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaNaidi2;
