import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia9 */
class Guia5Naidi2  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
                {/* paper 1 */}
      <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 2</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Conceptos basicos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p>   
                                          Estimados lectores, hemos tenido un avance significativo, y
                                          cada vez nos vamos adentrando más y más en la busca de
                                          nuestro objetivo principal, en las guías anteriores hemos
                                          realizado grandes cosas, ya podemos hacer algoritmos
                                          sencillos y funcionales, puesto que con el conocimiento de
                                          variables y los tipos de datos que podemos emplear en ellas es
                                          de mucha importancia.
                                          </p>
                                            
                                                          
                                            </div>

                                            </div>
                                              

                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       En esta guía empezaremos a darle función a esos datos que
                                      almacenamos en nuestras variables, puesto que solo tener los
                                      datos en ellas no es suficiente, con esos datos se debe hacer
                                      procesamientos, y en esta guía aprenderemos los primeros
                                      pasos para transformar esos datos e ir generando
                                      funcionalidad a nuestros algoritmos. 
                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
        
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Objetivos de la Guía</h4> <h4 style={{ fontWeight:'bold'}}>Operadores aritmeticos y logicos</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de conocer las operaciones
                                    aritméticas, lógicas y de relaciones que se usan en todo lenguaje de programación, orientados al
                                    uso de bloques, de igual manera aprenderán a usarlos en la plataforma mBlock.
                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                      ● Aprender conceptos básicos de operadores aritméticos, lógicos y relacionales que se usan en programación con un enfoque de bloque.

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● realizar operaciones mediante la plataforma mBlock
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Indagar los usos y aplicación del arduino en la vida real.
                                     </p>


                          
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Operadores en Programación</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'0px 0px 5px 0px'}}>

                                    Los operadores en programación se dividen en aritméticos, relacionales y lógicos
                                   </p>

                                    <strong>Operadores aritméticos</strong>

                                    <p>
                                    Se usan para realizar operaciones de la matemática básica como la suma, resta, multiplicación, división.
                                    </p>

                                    <p>
                                    Dentro de los archivos existen datos que son paquetes más pequeños de otros datos llamados 
                                    registros (reunidos por características iguales o similares).
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/guia_mblock1.png" alt="" />

                                                 </div>


                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                              
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <strong>Operadores relacionales</strong>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Los operadores relacionales se usan para evaluar expresiones condicionales y se basan en el concepto de verdadero o falso
                                    </p>

                                   
                                    <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/guia_mblock2.png" alt="" />

                                      </div>

                                      <strong>Operadores logicos</strong>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      Los operadores lógicos son usados para concatenar dos o más expresiones con operadores relacionales.
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/guia_mblock3.png" alt="" />

                                      </div>
                      
                                
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                      <h5>Ejemplos:</h5>

                                      
                                      <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'75%', display:'block', margin:'auto'}} src="./img/v1.png" alt="" />

                                      </div>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        VERDADERO: porque ambas expresiones son verdaderas
                                        </p>

                                        <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'75%', display:'block', margin:'auto'}} src="./img/f1.png" alt="" />

                                      </div>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      FALSO: porque hay una expresión falsa
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'75%', display:'block', margin:'auto'}} src="./img/v2.png" alt="" />

                                      </div>
                                      
                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      VERDADERO: Porque hay una expresión verdadera
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'75%', display:'block', margin:'auto'}} src="./img/f2.png" alt="" />

                                              </div>

                                              <p style={{ padding:'5px 0px 0px 0px'}}>
                                              FALSO: Porque ambas expresiones son verdaderas
                                              </p>

                                              <p style={{ padding:'15px 0px 0px 0px'}}>
                                              <span style={{color:'red'}}>Amplia mas informacion aqui</span> 
                                              <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="http://contenidos.sucerman.com/nivel1/programacion/unidad3/leccion1.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span>

                                              </p>


                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 10px 0px'}} >

                                                <div style={{padding:'0px 10px 0px 0px'}}>

                                        <img style={{width:'100%'}} src="./img/valle4.png" alt=""/> 

                                                          </div>

                                          <div className="container" style={{width:'95%'}}>


                                            <p style={{ padding:'5px 0px 0px 0px'}}> 
                                            Normalmente los operadores lógicos y relacionales los usamos cuando vamos a
                                            hacer comparaciones y verificaciones en estructuras de decisiones y repeticiones o
                                            ciclos, las cuales estudiaremos en sesiones posteriores.
                                            </p>

                                          </div>

                                          </div>


                                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Práctica mBlock</h5></div>

                                          <p style={{ padding:'5px 0px 0px 0px'}}>
                                          Ya conocimos los conceptos de operadores en programación, ahora te invito a
                                          que lo llevemos a la práctica, en el siguiente video les enseñaré a hacerlo
                                          directamente en las variables que ya saben declarar, por medio de nuestra
                                          plataforma mBlock.
                                          </p>

                                          <p style={{ padding:'5px 0px 0px 0px'}}>
                                          <span style={{color:'red'}}>presiona clic en el siguiente enlace</span>  
                                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=2p90ktUmUXc">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.1rem', color:'red'}}/> </a></span>
                                          </p>


                                          <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/mblock.png" alt="" />

                                                        </div>


                                      
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad 1</h5></div>

                                  <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                  <div style={{padding:'0px 10px 0px 0px'}}>

                                <img style={{width:'110%'}} src="./img/valle4.png" alt=""/> 

                                              </div>

                            <div className="container" style={{width:'95%'}}>


                              <p style={{ padding:'5px 0px 5px 0px'}}> 
                      ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                  </p>

                           <p style={{ padding:'5px 0px 5px 0px'}}>
                           Para verificar que los conocimientos se hayan comprendido
                           correctamente, el formador hará una divertida competencia en
                           vivo, la cual consiste en proyectar operaciones aritmeticas,logicas
                            y relacionales con bloques del programa mblock, por medio de la aplicacion
                           <span style={{color:'red'}}>Kahoot</span><span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 2px'}}><a rel="noreferrer" target='_blank' href="https://kahoot.com/"><FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/></a></span> y los equipos conectados a la misma por medio
                            de las tablets deberán rápidamente seleccionar la(s) respuesta(s) correcta(s).

                          </p>

                        </div>

                          </div>

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad 2</h5></div>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        El formador escogerá un número al azar de 3 dígitos y lo escribirá
                                        en el tablero o mostrará en pantalla. Luego pedirá a los
                                        participantes de cada grupo que sumen edades en el programa
                                        mblock y lo guarden en una variable llamada edades. El dato
                                        almacenado en dicha variable y el número suministrado por el
                                        formador, serán los números con los que deberán realizar los
                                        siguientes ejercicios:

                                        </p>


                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● Hacer las 4 operaciones aritméticas estudiadas en ésta
                                          sesión y guardar los resultados en diferentes variables
                                          como suma, resta, multiplicación y división. Finalmente
                                          estos resultados deberán ser mostrados por el puerto
                                          serial.
                                      
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ● (opcional) Hacer las 4 operaciones aritméticas pero de la
                                          siguiente manera: realizar la suma con las edades y al resultado de esa operación se le aplica la siguiente 
                                          operación teniendo en cuenta operar siempre con las 
                                          edades por ejemplo: 
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                          - Numdocente + edades = suma
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                          - suma - edades = resta
                                        </p>

                                        <p style={{ padding:'3px 0px 0px 0px'}}>
                                        y así sucesivamente con el resto de las operaciones
                                        </p>

                                    
 
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>


                                    <p style={{ padding:'10px 0px 0px 0px'}}>    
                                    ● 1 Documento en excel generado por la aplicación Kahoo, en donde se muestra el detalle de
                                      las respuestas que realizaron los equipos participantes. 
                                    </p>

                                    <p style={{ padding:'10px 0px 5px 0px'}}>
                                    ● Video y archivo.mblock de cada grupo con el reto completado.
                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                    <p style={{ padding:'10px 0px 0px 0px'}}>

                                    Cadavid, S. R. (s. f.). Manejo de operadores en programación. www.sucerman.com. Recuperado 17 de marzo de 2022, de 
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="http://contenidos.sucerman.com/nivel1/programacion/unidad3/leccion1.html">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span>

                                    </p>

                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

              

                {/* paper 12 */}
                <div id="movPage12" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia5Naidi2;
