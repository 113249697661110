import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import nguia12 from "../../svg/portadas-naidi/naidi-1-4.svg";
import nguia13 from "../../svg/portadas-naidi/naidi-2-4.svg";
import nguia14 from "../../svg/portadas-naidi/naidi-3-4.svg";

class GuiaNaidi4  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

  render(){
    return(
      <div className="App-book">
        <div className="breadcrbs">
          <h5>Inicio</h5>
          <FontAwesomeIcon icon={faAngleRight}/>
          <h5>Módulos</h5>
          <FontAwesomeIcon icon={faAngleRight}/>
          <h5>Guías</h5>
        </div>
        <div className="modulos">
          <div className="Lista-modulos">
            <span onClick={(e)=>this.someMethod('', '', false,'nguia12')}><Lista courses="Guía I" imgurl={nguia12} imgalt="CONOCIENDO LOS SENSORES Y ACTUADORES"/></span>
            <span onClick={(e)=>this.someMethod('', '', false,'nguia13')}><Lista courses="Guía II" imgurl={nguia13} imgalt="MEDICIÓN DE DISTANCIA CON ULTRASONIDO"/></span>
            <span onClick={(e)=>this.someMethod('', '', false,'nguia14')}><Lista courses="Guía III" imgurl={nguia14} imgalt="MOVIMIENTOS CON MOTORES"/></span>
          </div>
        </div>
      </div>
    );
  }
}

export default GuiaNaidi4;
