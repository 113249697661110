import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import fguia15 from "../../svg/portadas-front-end/guia-1-4.svg";
import fguia16 from "../../svg/portadas-front-end/guia-2-4.svg";
import fguia17 from "../../svg/portadas-front-end/guia-3-4.svg";
import fguia18 from "../../svg/portadas-front-end/guia-4-4.svg";

class GuiaMd4  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'fguia15')}><Lista courses="Guía I" imgurl={fguia15} imgalt="Introducción a React"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia16')}><Lista courses="Guía II" imgurl={fguia16} imgalt="Ciclo de Vida de los Componentes"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia17')}><Lista courses="Guía III" imgurl={fguia17} imgalt="Elementos de Formularios en React"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia18')}><Lista courses="Guía IV" imgurl={fguia18} imgalt="JSX en Profundidad"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaMd4;
