import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import fguia19 from "../../svg/portadas-front-end/guia-1-5.svg";

class GuiaMd5  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'fguia19')}><Lista courses="Guía I" imgurl={fguia19} imgalt="Donde Publico mi Página Web"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaMd5;
