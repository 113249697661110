import React, { Component } from "react";
import Lista from "../Lista.jsx";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import naidi1 from "../../svg/portadas-naidi/naidi-1.svg";
import naidi2 from "../../svg/portadas-naidi/naidi-2.svg";
import naidi3 from "../../svg/portadas-naidi/naidi-3.svg";
import naidi4 from "../../svg/portadas-naidi/naidi-4.svg";
import naidi5 from "../../svg/portadas-naidi/naidi-5.svg";

class ModuloList2  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', false, 'naidi1', '')}><Lista courses="Módulo I" imgurl={naidi1} imgalt="Módulo 1"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'naidi2', '')}><Lista courses="Módulo II" imgurl={naidi2} imgalt="Módulo 2"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'naidi3', '')}><Lista courses="Módulo III" imgurl={naidi3} imgalt="Módulo 3"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'naidi4', '')}><Lista courses="Módulo IV" imgurl={naidi4} imgalt="Módulo 4"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'naidi5', '')}><Lista courses="Módulo V" imgurl={naidi5} imgalt="Módulo 5"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default ModuloList2;
