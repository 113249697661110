import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft,faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube} from "@fortawesome/free-brands-svg-icons";
/* Guia15 */
class Guia3Naidi4  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
        {/* paper 1 */}
        <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 4</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{color:'red'}}>Sensores y actuadores</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p>

                                          Estimados lectores, cada vez el avance es mucho más 
                                          significativo e importante, por lo cual el compromiso cada vez 
                                          es mayor y debemos poner todo nuestro empeño para avanzar cada vez mucho más.
                                         </p>
                                            
                                             
                                            <p style={{padding:'5px 0px 0px 0px'}}>

                                            En esta guía aprenderán a dominar un componente que es de 
                                            suma importancia para la industria y todas las áreas del vivir y 
                                            convivir de la humanidad. Gracias a este componente es que 
                                            muchas cosas hoy en día son posibles, por lo cual es de mucha 
                                            importancia poder dominarlo e interactuar con él.
                                            </p>
                                            
                                            </div>

                                            </div>
                                              
                                   


                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>


                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'10px 0px 10px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            


                                   
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Movimientos con motores DC</h4> <h4 style={{ fontWeight:'bold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>

                                    Al culminar esta guía, los estudiantes estarán en la capacidad de dominar el concepto motores DC 
                                    y construir un circuito electrónico para dominar los movimientos que se pueden realizar con un motor

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ●  Aprender conceptos específicos de motores DC.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px',textAlign:'left'}}>
                                     ● Construir circuito electrónico para dominar movimientos de un motor
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px',textAlign:'left'}}>
                                     ● Realizar programación de un motor DC para ejecutar acciones.
                                     </p> 


                                     


                                    
                                
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                 
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2.7em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualización: ¿Motor DC?</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'0px 0px 5px 0px'}}>
                                    El motor CC o motor DC es un motor de corriente continua o directa y su propiedad es la conversión 
                                    de energía eléctrica a energía mecánica. Es provocado por un movimiento rotatorio, gracias a la 
                                    acción de un campo magnético.
                                   </p>

                                   <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/motor1.png" alt="" />
                                      </div>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Normalmente un motor se compone de dos partes: el estátor y el rotor.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Estátor. Da soporte mecánico y contiene los polos de la máquina, pueden ser un devanado de 
                                      hilo de cobre sobre un núcleo de hierro, o imanes permanentes. Cubre el rotor.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Rotor. Con forma cilíndrica (normalmente), también devanado y con núcleo es alimentado con 
                                      corriente directa a través de delgas, que están en contacto alternante con escobillas fijas 
                                      (también llamadas carbones).
                                      </p>


                                     


                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>

                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                                
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                <p style={{padding:'5px 0px 5px 0px'}}>
                                      El inconveniente encontrado es el mantenimiento, debido principalmente al desgaste que sufren las 
                                      escobillas al entrar en contacto con las delgas.

                                      </p>

                                            <b>Funcionamiento</b>

                                            <p style={{padding:'5px 0px 5px 0px'}}>
                                            Se basa en la repulsión que ejercen los polos magnéticos de un imán permanente de acuerdo con la 
                                            Ley de Lorentz, interactúan con los polos magnéticos de un electroimán que se encuentra montado 
                                            en el rotor.
                                            </p>
                                            
                                            <p style={{padding:'5px 0px 5px 0px'}}>
                                            Cuando la corriente eléctrica circula por la bobina de este electroimán giratorio, el campo 
                                            electromagnético que se genera interactúa con el campo magnético del imán permanente o 
                                            devanado. Si los polos del imán permanente o devanado y los polos del electroimán giratorio 
                                            coinciden, se produce un rechazo y un torque magnético (par de fuerza que provoca que el rotor 
                                            rompa la inercia y comience a girar sobre su eje) en sentido a la forma que se encuentre conectado 
                                            el motor al circuito o la pila.
                                            </p>

                                            <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/motor2.png" alt="" />
                                      </div>
                                 
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                      <b>Variaciones en el diseño del motor</b>

                                      <p>
                                      Los motores de corriente continua se construyen con rotores bobinados, y con estatores bobinados 
                                      o de imanes permanentes. Además existen muchos tipos de motores especiales, como por ejemplo 
                                      los motores sin escobillas, los servomotores y los motores paso a paso, que se fabrican utilizando un 
                                      motor de corriente continua como base.
                                      </p>

                                      <b>Motores con estátor bobinado</b>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Si el estátor es bobinado, existen distintas configuraciones posibles para conectar los dos bobinados 
                                      de la máquina:
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Motor serie o motor de excitación en serie: el devanado de estátor y el devanado de rotor se 
                                      conectan en serie.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Motor shunt o de excitación en paralelo: el devanado de estátor y de rotor se conectan en paralelo.
                                      </p>

                                      <div style={{padding:'20px 0px 5px 0px'}}>
                                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />
                                      </div>


                                      
                                      

                                 
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Motor de excitación compuesta o motor compound: se utiliza una combinación de ambas configuraciones.       
                                      </p>

                                      <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/motor3.png" alt="" />
                                      </div>


                                      <b>Motores de imán permanente</b>

                                      <p>
                                      Los motores de imán permanente tienen pocas ventajas de rendimiento frente a los motores 
                                      síncronos de corriente continua de tipo excitado y han llegado a ser el predominante en las 
                                      aplicaciones de potencia fraccionaria. Son más pequeños, más ligeros, más eficaces y fiables que 
                                      otras máquinas eléctricas alimentadas individualmente.
                                      </p>

                                      <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'55%', display:'block', margin:'auto'}} src="./img/motor4.png" alt="" />
                                      </div>

                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                                <b>Motor eléctrico compound.</b>

                                                <p style={{padding:'5px 0px 0px 0px'}}>
                                                Rotor de una pequeña máquina de corriente directa de 12 V, con imanes permanentes, de dos polos, 
                                                cinco devanados, cinco delgas y dos escobillas.
                                                </p>

                                                <p style={{padding:'5px 0px 0px 0px'}}>
                                                Conmutación de una bobina (A) de un devanado en anillo. La bobina A está conectada a las delgas 1 
                                                y 2. La escobilla (E) está situada sobre la línea neutra y tiene igual ancho que las escobillas (en la 
                                                realidad una escobilla suele ser más ancha y contacta con varias delgas simultáneamente). Se supone 
                                                que sólo hay dos escobillas por lo que la corriente que circula por una escobilla es la corriente total 
                                                del inducido II. 
                                                </p>

                                                <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/motor5.png" alt="" />
                                      </div>

                                      <b>Motores sin escobillas</b>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Los motores de corriente directa sin escobillas están diseñados para conmutar la tensión en sus 
                                      devanados, sin sufrir desgaste mecánico. 
                                      </p>

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <p>
                                  Para este efecto utilizan controladores digitales y sensores 
                                  de posición. Estos motores son frecuentemente utilizados en aplicaciones de baja potencia, por 
                                  ejemplo en los ventiladores de computadoras.<span style={{color:'red'}}> Amplía información aquí </span> 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.factor.mx/portal/base-de-conocimiento/motor-dc/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span>
                                  </p>
                                        
                                  <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/motor6.png" alt="" />
                                      </div>

                                      
                                      <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 10px 0px 0px'}}>
                                      
                                    <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'95%'}}>
                                                                        
                                    <p>Para que entiendan con más facilidad los conceptos anteriores reproduzcan el
                                      siguiente video <span style={{color:'red'}}>: Como Funciona un Motor Eléctrico de Corriente Continua ( DC) ⚡ | Animación 3D</span>

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=Zxdc1egljuI">  <FontAwesomeIcon icon={faYoutube} style={{color:'red', cursor:'pointer', fontSize:'1.1rem'}}/> </a></span>
                                          </p>
                                            
                                    </div>

                                    </div>

                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                                    <b>La importancia de los motores eléctricos</b>

                                                    <p style={{padding:'5px 0px 10px 0px'}}>
                                                    Los motores eléctricos y bombas son responsables de dar vida a muchos de los equipos que hoy en 
                                                    día consideramos imprescindibles. En el artículo que nos ocupa, nos centraremos en los motores 
                                                    eléctricos, ya que nuestro día a día gira en torno a ellos más de lo que pensamos.
                                                    </p>

                                                    <b>¿Dónde están los motores?</b>

                                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>En casa:</span> En electrodomésticos como el refrigerador, un ventilador, una lavadora, la secadora, 
                                                    el lavaplatos, la licuadora o la batidora, entre otros.
                                                    </p>

                                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                                 <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/motor7.png" alt="" />
                                                    </div>

                                                   




                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                               
                                          <p>
                                          ● <span style={{fontFamily:'Montserrat-SemiBold'}}>En la industria:</span> Compresores, elevadores, bombas centrífugas, cintas transportadoras, 
                                          ventiladores, vehículos eléctricos, mezcladores, agitadores, arados, discos de corte, estaciones 
                                          sanitarias, mesas de rodillos, etc.
                                        </p>

                                        <div style={{padding:'10px 0px 5px 0px'}}>
                                                 <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/motor8.png" alt="" />
                                                    </div>

                                                    <p>
                                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>En el transporte:</span> Bicicletas, canoas y barcos eléctricos ya son una realidad. En cuanto a los 
                                                    vehículos, existen más de 100 prototipos, con autonomía y prestaciones similares a las de los 
                                                    coches propulsados por combustible, con la ventaja de que no contaminan el medio ambiente.<span style={{color:'red'}}> Amplía información aquí </span> 
                                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.viladoms.es/blog/la-importancia-de-los-motores-el%C3%A9ctricos">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span>
                                                    </p>

                                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                                 <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/motor9.png" alt="" />
                                                    </div>


                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Driver L293D</h5></div>

                                  <b>El Driver puente H para motores L293D</b>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Es un circuito integrado de cuatro canales diseñado para manejar cargas inductivas tales como 
                                    relevos, soleniodes, motores DC y motores paso a paso hasta con una salida máxima de corriente 
                                    de 600mA por canal en la configuración conocida como puente H. Es posible configurarlo para 
                                    manejar hasta 2 puentes H. El control de las entradas digitales del L293 puede hacerse desde 
                                    cualquier microcontrolador, Arduino, Raspberry pi, TI Launchpad, BeagleBone, o cualquier otro sistema embebido.
                                    </p>

                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/motor10.png" alt="" />
                                    </div>

                                    <p> <span style={{fontFamily:'Montserrat-SemiBold'}}>Caracteristicas:</span></p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Salida de corriente por canal de forma continua: 600mA.
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Salida de corriente por canal pico (No repetitivo): 1.2A.
                                    </p>
                               
                      
                                   

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 <p>
                                 ● Pin o patilla para la función habilitador o Enable: 2.
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Máximo voltaje soportado por los pines Enable: 7Vdc
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Protección de sobrecalentamiento.
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Rango de voltaje de alimentación: 4.5Vdc a 36Vdc.
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Rango de temperatura de operación: -40°C a +150°C.
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Encapsulado: DIP-16.
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Fabricante: STMicroelectronics
                                 </p>

                                 <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/motor11.png" alt="" />
                                    </div>


                                    <p> <span style={{fontFamily:'Montserrat-SemiBold',padding:'5px 0px 0px 0px'}}>Aplicaciones:</span></p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Control de cargas inductivas: Relevos, Solenoides, Motores DC, Motores PAP
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Puentes H. 
                                    </p>

                                    <p><span style={{color:'red'}}> Amplía información aquí </span> 
                                     <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  
                                      <a rel="noreferrer" target='_blank' href="https://www.electronicaplugandplay.com/circuitos-integrados/product/517-driver-l293d">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/> </a></span></p>
                                  
                                    

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                     
                                <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                        <div style={{padding:'0px 10px 0px 0px'}}>
  
                                <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                                </div>

                                    <div className="container" style={{width:'95%'}}>
                                    
                        <p> Ya conociendo los conceptos de motores y el driver L293D, es hora que los 
                            combinemos y hagamos nuestras creaciones, por lo tanto vamos a practicar haciendo 
                            la conexión de un motor DC, teniendo en cuenta los pines de conexión del driver como 
                            lo vemos a continuación. </p>
        
                            </div>

                            </div>


                            <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/motor12.png" alt="" />
                                    </div>



                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                               
                      
                                     <p>
                                     Ahora vamos a hacer la conexión del motor DC y el driver L293D, a nuestra placa arduino
                                     </p>

                                     <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/motor13.png" alt="" />
                                    </div>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Luego de tener el circuito armado en físico, es hora de darle vida a través de la programación, por lo 
                                    cual usaremos nuestro programa mBlock para armar el algoritmo que necesitamos con las instrucciones lógicas y coherentes.
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Para poder programar nuestro motor por medio de mBlock, debemos agregar unos nuevos bloques 
                                    al programa para poder usarlos, para ello nos vamos al área de grupos de bloques y escogemos la última opción 
                                    </p>

                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'25%', display:'block', margin:'auto'}} src="./img/mblock10.png" alt="" />
                                    </div>




                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                 <p>
                                 la cual nos llevará a una nueva ventana donde podemos observar muchas extensiones de otros 
                                 desarrolladores, quienes los han dispuesto para trabajar en el programa mBlock, en la cual debemos 
                                 hacer una búsqueda del grupo que necesitamos, por lo cual nos dirigimos a la parte superior derecha 
                                 de la ventana y en la opción de búsqueda escribiremos <span style={{fontFamily:'Montserrat-SemiBold'}}>L298N</span> 
                                 </p>

                                 <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/mblock11.png" alt="" />
                                    </div>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Podemos observar que existen varias opciones para escoger, pero vamos a seleccionar la que está 
                                    marcada en la imagen, puesto que ya está ensayada, es sencilla y funcional.
                                    </p>


                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'25%', display:'block', margin:'auto'}} src="./img/panda.png" alt="" />
                                    </div>

                                   
                 

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p>
                                    para agregarla solo debes dar click en el botón <span style={{fontFamily:'Montserrat-SemiBold'}}>añadir</span>, pero normalmente está deshabilitado cuando 
                                    lo vamos a usar por primera vez por lo cual debemos descargarlo a nuestro computador por medio del botón "+"  que en ese momento lo encuentras en la parte 
                                    superior derecha de la opción a escoger.
                                 </p>

                                 <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'10%', display:'block', margin:'auto'}} src="./img/mas.png" alt="" />
                                    </div>

                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/mblock12.png" alt="" />
                                    </div>

                                    <p>
                                    Escogemos esa opción porque el driver L298N trabaja de la misma forma que el driver L293D. por lo 
                                    cual su configuración interna al igual que sus entradas y salidas son exactamente las mismas.
                                    </p>

                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'25%', display:'block', margin:'auto'}} src="./img/valle4.png" alt="" />
                                    </div>




                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                                <p>
                                                Una vez añadida, podemos observar que se nos agregó un nuevo grupo de bloques.
                                                </p>


                                                <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/mblock13.png" alt="" />
                                    </div>

                                    <p  style={{padding:'5px 0px 0px 0px'}}>
                                    Por último armamos nuestro algoritmo, como lo hemos hecho de costumbre teniendo en cuenta 
                                    que debemos escribir los pines que estamos usando en las conexiones de nuestro arduino. 
                                    </p>


                                                
                          </div>
                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 20 */}
                  <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    
                                    <p>
                                    Para el caso del esquema que tenemos planteado, con el bloque de iniciación de motores tenemos en 
                                    cuenta que el pin digital 4 está conectado al pin de activación del driver, y los pines 5 y 6 son PWM, 
                                    para hacer el control de movimiento del motor. Todo esto se hace por fuera del ciclo infinito para que 
                                    se ejecute por una sola vez cada vez que se encienda la placa arduino.
                                    </p>

                                    <div style={{padding:'10px 0px 5px 0px'}}>
                                    <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/mblock14.png" alt="" />
                                    </div>

                                    <p style={{padding:'5px 0px 10px 0px'}}>
                                    Por último usamos los bloques de movimiento, por ejemplo el que dice forward, significa ir hacia 
                                    adelante y speed significa que podemos configurar una velocidad, teniendo en cuenta que el 
                                    rango de velocidad que podemos usar es de 0 a 255.
                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>

                                                    
                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                                </p>



                                          
                                                
                          </div>
                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                 {/* paper 21 */}
                 <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    
                              <p>
                              Ahora que ya sabemos configurar un motor DC, y hemos 
                              adquirido otros conocimientos con anterioridad, se requiere 
                              agregar un motor más y controlar su velocidad por medio de un 
                              potenciómetro. 
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              El formador de su territorio, les suministrará los siguientes componentes:
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● 1 Placa Arduino
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● 1 placa de prototipos (Protoboard)
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● 1 Potenciómetro de 10 kΩ
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● 1 batería 9v con broche
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ●  2 motores DC
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● 1 Driver L293D
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              ● Cables Jumpers M-M
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              El esquema de circuito que van a construir es el siguiente:
                              </p>


                                          
                                                
                          </div>
                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    
                                                        <p style={{padding:'5px 0px 5px 0px'}}>
                                                        El reto también consiste en que sean capaces de agregar los componentes que faltan sin la ayuda de un esquema completo..
                                                        </p>

                                                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>

                                                        <p style={{padding:'5px 0px 5px 0px',fontFamily:'Montserrat-SemiBold'}}>
                                                        ● Video y archivo.mblock de cada grupo con el reto completado. 
                                                        </p>

                                                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>


                                                            <p  style={{padding:'5px 0px 0px 0px'}}>
                                                            ● Motor DC . (2018, 24 octubre). Factor Evolución.<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.factor.mx/portal/base-de-conocimiento/motor-dc/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                                            </p>

                                                            <p style={{padding:'5px 0px 0px 0px'}}>
                                                            ● Electromecánica, S. V. L. (2019, 8 octubre). La importancia de los motores eléctricos. Viladoms Electromecánica, S.L. <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.viladoms.es/blog/la-importancia-de-los-motores-el%C3%A9ctricos">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                                            </p>

                                                            <p style={{padding:'5px 0px 0px 0px'}}>
                                                            ● Driver puente H para motores L293D. (s. f.). <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.electronicaplugandplay.com/circuitos-integrados/product/517-driver-l293d">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                                            </p>
                                                
                          </div>
                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 23 */}
                <div id="movPage23" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia3Naidi4;
