import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia16 */
class Guia1Naidi5  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
               {/* paper 1 */}
               <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 5</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>Control remoto</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>


                                    <div style={{padding:'7px 0px 10px 0px'}}>

                                <p style={{padding:'10px 0px 10px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt="" />

                                                 </div>

                                    

                                          
                                          <p> 

                                          Estimados lectores, ya nos estamos acercando a la meta, pero 
                                          aún necesitamos conocer más cosas para agregarle más valor a nuestras capacidades.
                                          </p>
                                            
                                                          
                                         
                                            
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       En esta guía nos iremos adentrando en el mundo inalámbrico, 
                                       para lo cual necesitamos conocer tecnología de vanguardia y 
                                       que mueven el mundo de hoy haciendo las cosas mucho más 
                                       fáciles para el convivir y crecer de la humanidad.
                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          

                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                 
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px', textDecorationLine:'underline', color:'red'}}><h4 style={{fontFamily:'Montserrat-SemiBold' }}>Conexiones inalambricas</h4> <h4 style={{ fontFamily:'Montserrat-SemiBold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                     
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de conocer los conceptos de 
                                    conexiones inalámbricas, internet e IoT.                                  
                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                    ● Aprender conceptos de Conexiones Inalámbricas
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Aprender conceptos de internet.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Aprender conceptos de Internet de la Cosas (IoT).
                                     </p>


                                     


                               
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                    
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: Redes inalámbricas</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'5px 0px 5px 0px'}}>
                                      
                                    Las redes inalámbricas son conexiones que se dan por medio de ondas electromagnéticas, 
                                    permitiendo la transmisión y recepción de información, prescindiendo de una conexión física 
                                    (cableado). Esto hace posible que los dispositivos remotos se conecten fácilmente mientras se 
                                    encuentren dentro del área de la red. Además, las redes inalámbricas permiten que varios 
                                    terminales puedan establecer comunicación sin necesidad de conexión cableada.
                                   </p>

                                  

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/wifi1.png" alt="" />

                                     </div>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     Las redes inalámbricas facilitan la conexión de computadores que no permanecen en un solo lugar. 
                                     A raíz de esto, existen dos amplias categorías de redes inalámbricas, redes de larga distancia y 
                                     redes de corta distancia.
                                     </p>

                         

                                   
                                  
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                               
                                    
                                      <p>
                                      Este fenómeno ha entrado en auge desde 1997 y se encuentra más vigente 
                                      que nunca, desde conexiones wifi, hasta la transmisión de datos vía Bluetooth.
                                     </p>

                                     <b>Caracteristicas</b>

                                     
                                    <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/wifi2.png" alt="" />

                                      </div>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Dependiendo del medio, las redes inalámbricas tendrán una característica u otra según el rango de frecuencia utilizado para transmitir.
                                         La transmisión puede variar entre las que se nombran a continuación:
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Ondas de radio:</span> esta transmisión utiliza una frecuencia de ondas electromagnéticas 
                                      omnidireccionales. Además, no necesita de antenas parabólicas.
                                      </p>


                                      <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi3.png" alt="" />

                                             </div>


                                   

                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <p>
                                ● Microondas por satélite: Enlaza dos o más estaciones terrestres (estaciones base). En este 
                                caso, el satélite recibe la señal en una banda de frecuencia, la amplifica y la retransmite en otra banda.
                                </p>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi4.png" alt="" />

                                  </div>

                                  <p>
                                  ● Infrarrojos: enlazan transmisores y receptores que modulan la luz infrarroja no coherente. deben estar alineados directamente o con una reflexión en una superficie.
                                  </p>

                                  
                                <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi5.png" alt="" />

                                        </div>







                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                <p>
                                ● Microondas terrestres: utilizada en antenas parabólicas, tiene una cobertura de kilómetros, 
                                pero con el inconveniente de que el emisor y el receptor deben estar perfectamente alineados
                                </p>

                                <div style={{padding:'10px 0px 5px 0px'}}>

                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi6.png" alt="" />

                                        </div>

                                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tipos de redes inalambricas</h5></div>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        Existen diferentes tipos de redes inalámbricas, las cuales varían según su alcance de cobertura. A 
                                        continuación se detallan cuáles son:
                                        </p>


                                        <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'85%', display:'block', margin:'auto'}} src="./img/wifi7.png" alt="" />

                                                   </div>

                                         
 
                                
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>


                                <p>
                               <span style={{fontFamily:'Montserrat-SemiBold'}}>Wireless Personal Area Network (WPAN):</span>  La red inalámbrica de área personal, son redes que cubren distancias de hasta 10 metros. Estas se 
                                emplean comúnmente para que un usuario pueda conectar sus dispositivos de uso personal a una red.
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                              <span style={{fontFamily:'Montserrat-SemiBold'}}>Wireless Local Area Network (WLAN):</span>   
                                Una red inalámbrica de área local es un tipo de red que cubre distancias de hasta 100 metros. Son 
                                implementadas bajo protocolos wifi o bluetooth, y utilizadas para establecer una red de menor coste, 
                                evitando los de una conexión cableada.
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                               <span  style={{fontFamily:'Montserrat-SemiBold'}}>Wireless Metropolitan Area Network (WMAN): </span> 
                                La red inalámbrica del área metropolitana es una red de la que su cobertura comúnmente puede 
                                abarcar unos 50 km. Como puede intuir, estas redes se establecen para dar cobertura dentro de un 
                                área metropolitana —un grupo de edificios céntricos, por ejemplo—, o una cualquier zona extensa 
                                (zonas rurales o un campus universitario) .
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                               <span style={{fontFamily:'Montserrat-SemiBold'}}> Wireless Wide Area Network (WWAN):</span> Una red inalámbrica de área extensa ofrece una cobertura más extensa que todas las redes 
                                inalámbricas. Las empresas de telefonía móvil se sirven de este tipo de redes para poder ofrecer sus 
                                servicios, y establecer conexiones entre sus usuarios.
                                </p>


                                    
                                      

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 

                                     <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tecnologías inalámbricas</h5></div>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                                <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/wifi8.png" alt="" />

                                                   </div>


                                                   <p style={{padding:'5px 0px 0px 0px'}}>
                                                   Existen diferentes tecnologías inalámbricas capaces de generar redes inalámbricas para conectar 
                                                   dispositivos de todo tipo. A continuación se describen algunos de los tipos de tecnologías 
                                                   inalámbricas más importantes:
                                                   </p>

                                                   <p style={{padding:'5px 0px 0px 0px'}}>
                                                 <span style={{fontFamily:'Montserrat-SemiBold'}}> Wi-Fi:</span>  Es una red que permite la interconexión inalámbrica con un adaptador inalámbrico y un router. El 
                                                   Wi-Fi es especialmente popular en áreas domésticas para conectar varios dispositivos a internet.
                                                   </p>


                                                   <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi9.png" alt="" />

                                                  </div>




                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p>
                               <span style={{fontFamily:'Montserrat-SemiBold'}}>Wi-max:</span>  
                                Similar al funcionamiento de la red Wi-fi, con transmisión de ondas microondas capaz de alcanzar 
                                una cobertura de hasta 70km. Además, permite la recepción de datos por microondas y 
                                retransmisión por ondas de radio.

                                </p>


                                
                                <div style={{padding:'10px 0px 0px 0px'}}>

            <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi10.png" alt="" />

                                     </div>

                                     <p>
                                    <span style={{fontFamily:'Montserrat-SemiBold'}}>Bluetooth:</span>  Hace posible transmitir datos y voz entre distintos dispositivos (por ejemplo, de teléfono a teléfono) 
                                     mediante un enlace por radiofrecuencia. Cuenta con poca cobertura de emisión, por lo que sólo se pueden conectar 
                                     dispositivos que se encuentren dentro del rango de alcance de la red Bluetooth.
                                     </p>

                                     
                                <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/bluetooth.png" alt="" />

                                           </div>





                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p>
                              <span style={{fontFamily:'Montserrat-SemiBold'}}>Bluetooth-Le:</span> Esta tecnología está destinada a aplicaciones novedosas, y a diferencia del Bluetooth clásico, esta 
                                consume mucha menos energía. Incluso, permite desplegar redes inalámbricas de área personal, 
                                por lo que se pueden vincular dispositivos sin cables de por medio.

                                </p>


                                          <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/bluetooth2.png" alt="" />

                                                      </div>

                                                      <p>
                                                     <span style={{fontFamily:'Montserrat-SemiBold'}}>Li-Fi:</span> Es una nueva conexión inalámbrica que utiliza bombillas LED insertadas en un decodificador. 
                                                      Mediante estas, se establece una conexión transmitida a través de luz visible. Cabe destacar que 
                                                      puede llegar a ser hasta 100 veces más eficiente que una red Wi-Fi.
                                                      </p>


                                                      <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/lifi.png" alt="" />

                                                               </div>



                                

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tecnologías inalámbricas de telefonía</h5></div>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                Engloba las tecnologías de comunicación inalámbrica utilizadas por los teléfonos celulares 
                                modernos. Las más más utilizadas en la actualidad son:
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                              <span style={{fontFamily:'Montserrat-SemiBold'}}> Red 4G:</span> Es utilizada para transmitir datos y navegar por internet a alta velocidad, desde la comodidad de un dispositivo portátil, o móvil.
                                </p>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/wifi4g.png" alt="" />

                                                               </div>

                                                               <p>
                                                              <span style={{fontFamily:'Montserrat-SemiBold'}}>Red 5G:</span> Esta generación de tecnología inalámbrica precisa tres gamas de frecuencias 
                                                               fundamentales: por debajo de 1 GHz, entre 1 y 6 GHz y por encima de 6 GHz. Además, pretende 
                                                               establecer un estándar de transferencia de datos de hasta 1 Gbps. Tecnología inalámbrica de automatización
                                                               </p>

                                                               <div style={{padding:'10px 0px 0px 0px'}}>

                                        <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/wifi5g.png" alt="" />

                                                                             </div>



                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <b>Las dos tecnologías inalámbricas de automatización más usadas son:</b>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                              <span style={{fontFamily:'Montserrat-SemiBold'}}> Zigbee:</span> Es un sistema de comunicación inalámbrica enfocado en la comunicación de dispositivos 
                                con una baja tasa de datos, lo que ayuda a disminuir el consumo eléctrico.
                                </p>

                                         <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/zigbee.png" alt="" />

                                                </div>

                                                <p>
                                               <span style={{fontFamily:'Montserrat-SemiBold'}}> Z-wave:</span> Se trata de una red que utiliza ondas de radio. Esta permite el controlar inalámbricamente 
                                                electrodomésticos y otros dispositivos.
                                                </p>


                                                
                                         <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/zwave.png" alt="" />

                                                    </div>



                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <b>Ventajas y Desventajas</b>


                                         
                                <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pc01.png" alt="" />

                                           </div>


                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           Gracias a las redes inalámbricas de corto, mediano y gran alcance, se brindan mejores funciones al 
                                           uso de dispositivos inteligentes. No obstante, existen algunos puntos desfavorables respecto a estas 
                                           tecnologías que a continuación se examinan:
                                           </p>

                                           <b>Ventajas</b>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           ● Dado que las redes inalámbricas no utilizan medios cableados o físicos para establecer 
                                           conexión entre dispositivos, se otorga mayor libertad a los equipos conectados a la red.
                                           </p>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           ● Este tipo de redes no necesitan cableado ni grandes remodelaciones físicas de espacio, por lo 
                                           que su instalación resulta mucho más rápida y económica que las típicas redes alámbricas
                                           </p>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           ● La mayoría de las tecnologías inalámbricas para redes como el Wi-Fi, permiten la conexión de 
                                           un gran número de dispositivos móviles como teléfonos, tablets, impresoras y ordenadores.
                                           </p>


                                     
                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <p>
                                ● El mantenimiento de las redes inalámbricas es mucho más fácil y barato que en los casos de 
                                redes cableadas, ya que solo basta con supervisar los aparatos transmisores de señal.            
                               </p>

                                <p  style={{padding:'5px 0px 0px 0px'}}>
                                ● Son ideales para espacios en dónde la instalación de una red alámbrica convencional resulta sumamente complicada.
                                </p>

                                <b>Desventajas</b>

                                <p>
                                ● Algunas redes inalámbricas pueden llegar a ser propensas a interferencias, lo que puede afectar la calidad de conexión
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● Al estar más expuestas a cualquiera que se sitúen dentro de su área de cobertura, existe un 
                                mayor riesgo para la seguridad de las personas que hacen uso de la red.
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● Una de las principales desventajas de las redes inalámbricas vs las redes alámbricas, es que las 
                                primeras no logran superar la velocidad de transferencia de datos de las redes inalámbricas. 
                                En este sentido, mientras que una red inalámbrica alcanza hasta 55 Mbps, las redes cableadas 
                                logran tasas de velocidad de hasta 100 Mbps.
                                </p>

                                    <p>
                                        <span style={{color:'red'}}>Amplia informacion aqui.</span>
                                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://conceptoabc.com/redes-inalambricas/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                    </p>

                                    

                                     

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <div style={{display:'flex', justifyContent:'space-between',padding:'5px 0px 10px 0px'}} >

                                        <div style={{padding:'0px 10px 0px 0px'}}>

                                    <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                    </div>

                                    <div className="container" style={{width:'95%'}}>


                <p> Para que entiendan con más facilidad  los conceptos anteriores reproduce el 
                siguiente video :<span style={{color:'red'}}>Cómo funciona la comunicación inalámbrica (Español)</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=_K9M0A9ukMU">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                        </p>

                            </div>

                        </div>

                        <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>¿Qué es Internet? </h4></div>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        Internet es una red de computadoras que se encuentran interconectadas a nivel mundial para 
                        compartir información. Se trata de una red de equipos de cálculo que se relacionan entre sí a través 
                        de la utilización de un lenguaje universal.
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        El concepto Internet tiene sus raíces en el idioma inglés y se encuentra conformado por el vocablo 
                        inter (que significa entre) y net (proveniente de network que quiere decir red electrónica).
                        </p>


                        < div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                      </div>



                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 <p>
                                 Es un término que siempre debe ser escrito en mayúscula ya que, hace referencia a “La Red” (que conecta 
                                a las computadoras mundialmente mediante el protocolo TCP/IP) y sin un artículo que lo acompañe 
                                (el/la) para hacerle referencia.
                                 </p>

                                < div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pc02.png" alt="" />

                                           </div>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           Existen diferentes tipos de conexión a Internet, es decir, distintos medios por los cuales uno puede 
                                           obtener conexión a la red de redes. El primero de ellos fue la conexión por dial-up, es decir, 
                                           tomando la conectividad de una línea telefónica a través de un cable. Luego surgieron otros tipos 
                                           más modernos como ser el ADSL, la fibra óptica, y la conectividad 3G, 4G (LTE) y 5G para dispositivos móviles.

                                           </p>


                                           <p style={{padding:'5px 0px 0px 0px'}}>

                                           Para acceder a los billones de sitios web disponibles en la gran red de redes, que conocemos como 
                                           la Internet, se utilizan los navegadores web (software), siendo algunos de los más utilizados Google 
                                           Chrome, Internet Explorer, Mozilla Firefox, y Safari, todos desarrollados por distintas compañías tecnológicas.

                                           </p>



      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tipos de conexiones a Internet</h5></div>

                                    <p>Al ser Internet una red, se puede acceder a ella mediante varios canales</p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Líneas telefónicas:</span>  Se puede utilizar una línea telefónica que se subdivide en líneas 
                                    telefónicas convencionales (transmite señales de forma analógica pero actualmente fueron 
                                    desplazadas por otras que ofrecen mayores beneficios) o digitales (mediante el empleo de un 
                                    adaptador de red se traducen las tramas resultantes de la computadora a señales digitales).
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Cable (fibra óptica):</span>  Asimismo, puede ser una conexión por cable (que implementa señales 
                                    luminosas en vez de eléctricas que codifican una mayor cantidad de información y a su vez, 
                                    dicho cable es de un material llamado fibra óptica que le permite transmitir datos entre nodos).
                                    </p>


                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Satelital:</span>  También, existe la conexión vía satélite (normalmente estamos hablando de un 
                                    sistema de conexión híbrido de satélite y teléfono para disminuir la congestión presente en las redes terrestres).
                                    </p>

                                
                                 

    
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 20 */}
                 <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Redes inalámbricas:</span> Líneas eléctricas. Si hablamos de líneas eléctricas usamos PLC (conjunción de tres palabras: 
                                    Power Line Communications). Por último, podemos usar a la telefonía móvil 
                                    (que refiere a comunicaciones disponibles para celulares para acceder a Internet).
                                    </p>


                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Líneas eléctricas:</span> Si hablamos de líneas eléctricas usamos PLC (conjunción de tres palabras: 
                                    Power Line Communications). Por último, podemos usar a la telefonía móvil (que refiere a 
                                    comunicaciones disponibles para celulares para acceder a Internet).
                                    </p>

                                    <b>Servicios y usos de Internet</b>

                                    < div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pc03.png" alt="" />

                                           </div>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           Internet nos abre las puertas a miles y miles de distintos servicios, entre ellos nos permitirá:
                                           </p>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           ● Buscar cualquier tipo de información que necesite (por ejemplo en Google).
                                           </p>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           ● Comprar productos de diversa índole (por ejemplo en Amazon o Mercado Libre).
                                           </p>



                          </div>

                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 21 */}
                <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Comunicarnos con familiares o amigos que estén en otros países o ciudades mediante una vídeo llamada (por ejemplo de Skype o Whatsapp).
                                 </p>
                                    
                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Jugar juegos online (como el League of Legends) con personas de distintas nacionalidades y edades, en tiempo real.
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Claro está, que una palabra que resuma todas estas cualidades que Internet posee puede ser 
                                    conectar o bien relacionar. Internet tiene la capacidad de conectar personas en distintas partes del 
                                    mundo y con distintos intereses entre sí.
                                    </p>

                                    <p>
                                        <span style={{color:'red'}}>Amplia informacion aqui.</span>
                                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://concepto.de/internet/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                    </p>


                                    <div style={{display:'flex', justifyContent:'space-between',padding:'20px 0px 10px 0px'}} >

                                                <div style={{padding:'0px 10px 0px 0px'}}>

                                    <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                        </div>

                                    <div className="container" style={{width:'95%'}}>


                                <p> Para que entiendan con más facilidad los conceptos anteriores reproduzcan los 
                                    siguiente videos : <span style={{color:'red',padding:'10px 0px 0px 0px'}}>1: ¿Qué es Internet y cómo funciona? Dibujos Animados | The Dr Binocs Show Cartoon</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=WeUjbauodxo">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                                        </p>


                                        <p style={{padding:'10px 0px 0px 0px'}}>  <span style={{color:'red'}}>2: Conociendo internet </span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=_-r9gOT2bKM">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                                        </p>

                                            </div>

                                            </div>

                          </div>

                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Qué es el IoT?</h5></div>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                La Internet de las cosas (IoT) describe la red de objetos físicos ("cosas") que llevan incorporados 
                                sensores, software y otras tecnologías con el fin de conectarse e intercambiar datos con otros 
                                dispositivos y sistemas a través de Internet. Estos dispositivos van desde objetos domésticos 
                                comunes hasta herramientas industriales sofisticadas. Con más de 7 mil millones de dispositivos IoT 
                                conectados en la actualidad, los expertos prevén que este número aumentará a 10 mil millones para 
                                el 2020 y 22 mil millones para el 2025.
                                </p>


                                < div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/iot1.png" alt="" />

                                                           </div>
                                  
                                  
                               

                          </div>

                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 23 */}
                     <div id="movPage23" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                              <b>¿Por qué es tan importante el Internet de las cosas (IoT)?</b>

                              <p  >
                              En los últimos años, IoT se ha convertido en una de las tecnologías más importantes del siglo XXI. 
                              Ahora que podemos conectar objetos cotidianos, electrodomésticos, coches, termostatos, monitores 
                              de bebés, a Internet a través de dispositivos integrados, es posible una comunicación fluida entre 
                              personas, procesos y cosas.
                              </p>

                              <p style={{padding:'5px 0px 0px 0px'}}>
                              Mediante la informática de bajo costo, la nube, big data, analítica y tecnologías móviles, las cosas 
                              físicas pueden compartir y recopilar datos con una mínima intervención humana. En este mundo
                              hiperconectado, los sistemas digitales pueden grabar, supervisar y ajustar cada interacción entre las
                             cosas conectadas. El mundo físico y el digital van de la mano y cooperan entre sí.
                              </p>


                              
                              < div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/iot2.png" alt="" />

                                                   </div>

                          </div>

                         </div>
                     <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                
                     {/* paper 24 */}
                     <div id="movPage24" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="19" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                               
                                        <b>¿Qué tecnologías han hecho posible el IoT?</b>

                                        <p>
                                        Si bien la idea de IoT existe desde hace mucho tiempo, una colección de avances recientes en una 
                                        serie de tecnologías diferentes la ha hecho práctica.
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● Accede a la tecnología de sensores de bajo costo y potencia. Los sensores asequibles y 
                                        fiables están haciendo posible la tecnología IoT para más fabricantes.
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Conectividad:</span>  Una gran cantidad de protocolos de red para Internet ha facilitado la conexión 
                                        de sensores a la nube y a otras cosas para lograr una transferencia de datos eficiente
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}} >Plataformas de informática en la nube:</span>  El aumento en la disponibilidad de plataformas en 
                                        la nube permite a las empresas y a los consumidores acceder a la infraestructura que 
                                        necesitan para escalar sin tener que administrarlo todo
                                        </p>


                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Aprendizaje automático y analítica.</span> Con los avances en aprendizaje automático y analítica, 
                                        junto con el acceso a cantidades grandes y variadas de datos almacenados en la nube, las 
                                        empresas pueden recopilar información de forma más rápida y fácil.
                                        </p>


                          </div>

                         </div>
                     <div data-active="19" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage24', 23, '', '', '', "19")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage24', 23, '', '', '', "19")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 25 */}
                     <div id="movPage25" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="20" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage24', 6, '', '', '', "19")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage24', 6, '', '', '', "19")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <p>
                                Por un lado, el surgimiento de estas tecnologías aliadas sigue traspasando los límites de IoT; por otro, los 
                                datos producidos por IoT también alimentan estas tecnologías.
                                </p>

                                <p>
                                ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Inteligencia artificial (IA) conversacional:</span>  Los avances en las redes neuronales han llevado 
                                el procesamiento del lenguaje natural (PLN) a los dispositivos IoT (como los asistentes 
                                personales digitales Alexa, Cortana y Siri) y los han hecho atractivos, asequibles y viables para uso doméstico.
                                </p>

                                <b>¿Qué es el IoT industrial?</b>

                                <p>
                                El IoT industrial (IIoT) hace referencia a la aplicación de la tecnología IoT en entornos industriales, 
                                especialmente con respecto a la instrumentación y control de sensores y dispositivos que utilizan 
                                tecnologías en la nube. 
                                </p>

                                < div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/tablet1.png" alt="" />

                                                   </div>

                                                   <p style={{padding:'5px 0px 0px 0px'}}>
                                                   Las industrias han utilizado la comunicación máquina a máquina (M2M) para hacer posibles la 
                                                   automatización y el control inalámbricos. 
                                                   </p>


                                    
                          </div>

                         </div>
                     <div data-active="20" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage25', 24, '', '', '', "20")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage25', 24, '', '', '', "20")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 26 */}
                  <div id="movPage26" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="21" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage25', 5, '', '', '', "20")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage25', 5, '', '', '', "20")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    
                                <p>
                                    Pero con la aparición de tecnologías en la nube y aliadas 
                                    (como analítica y aprendizaje automático), las industrias pueden lograr una nueva capa de 
                                    automatización y con ella crear nuevos ingresos y modelos de negocio. En ocasiones, IIoT recibe el 
                                    nombre de cuarta ola de la revolución industrial, o bien Industria 4.0. A continuación se muestran 
                                    algunos usos comunes de IIoT:
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Fabricación inteligente
                                    </p>
                                    
                                    <p style={{padding:'5px 0px 0px 0px'}}> 
                                    ● Activos conectados y mantenimiento preventivo y predictivo
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Redes eléctricas inteligentes
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Ciudades inteligentes
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Logística conectada

                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    ● Cadenas de suministro digitales inteligentes
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    <span style={{color:'red'}}>Amplia informacion aqui.</span>
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.oracle.com/co/internet-of-things/what-is-iot/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                    </p>


                                    <div style={{display:'flex', justifyContent:'space-between',padding:'15px 0px 10px 0px'}} >

                                                    <div style={{padding:'0px 10px 0px 0px'}}>

                                    <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                    </div>

                                             <div className="container" style={{width:'95%'}}>


                              <p> Para que entiendan con más facilidad los conceptos anteriores reproduzcan el 
                             siguiente video : <span style={{color:'red'}}> : Qué es el Internet de las Cosas | Futuro Digital Ep.1</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=5zU9uZVvgCs">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                                        </p>

                            </div>
                            </div>
                             </div>
                         </div>
                     <div data-active="21" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage26', 25, '', '', '', "21")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage26', 25, '', '', '', "21")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 27 */}
                 <div id="movPage27" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="22" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage26', 4, '', '', '', "21")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage26', 4, '', '', '', "21")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                             
                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad </h5></div>   

                          <p style={{padding:'5px 0px 0px 0px'}}>
                        ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                        </p>

                        <p style={{padding:'5px 0px 5px 0px'}}>
                        Para verificar que los conocimientos se hayan comprendido
                        correctamente, el formador hará una divertida competencia en
                        vivo, la cual consiste en proyectar unas preguntas por medio de la
                         aplicación  <span style={{color:'red'}}>Kahoot</span><span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 2px'}}><a rel="noreferrer" target='_blank' href="https://kahoot.com/"><FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/></a></span>, y los equipos conectados a la misma por medio
                         de las tablets deberán rápidamente seleccionar la(s) respuesta(s) correcta(s).

                        </p> 

                        <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables </h5></div> 
                       
                        <p style={{padding:'5px 0px 5px 0px'}}>           
                        ● 1 Documento en excel generado por la aplicación Kahoo, en donde se muestra el detalle de las respuestas que realizaron los equipos participantes. 
                         </p>   

                         <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia </h5></div> 

                            
                            <p style={{padding:'10px 0px 0px 0px'}}>
                            ● Redes inalámbricas. (2020, 11 junio). ConceptoABC.
                            <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://conceptoabc.com/redes-inalambricas/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                            </p>

                            <p style={{padding:'10px 0px 0px 0px'}}>
                            ● Internet - Concepto, tipos, servicios, usos y navegadores. (s. f.). Concepto.
                            <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://concepto.de/internet/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                            </p>

                            <p style={{padding:'10px 0px 0px 0px'}}>
                            ● ¿Qué es el Internet de las Cosas (IoT)? (s. f.). www.oracle.com.
                            <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.oracle.com/co/internet-of-things/what-is-iot/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                            </p>




                     
                          </div>

                         </div>
                     <div data-active="22" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage27', 26, '', '', '', "22")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage27', 26, '', '', '', "22")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>




                {/* paper 28 */}
                <div id="movPage28" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="23" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage27', 3, '', '', '', "22")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage27', 3, '', '', '', "22")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="23" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia1Naidi5;
