import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* guia17 */
class Guia2Naidi5  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
             <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 5</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{fontFamily:'Montserrat-SemiBold', color:'red',textDecorationLine:'underline'}}>Control remoto</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>


                                    

                                    <div style={{padding:'7px 0px 10px 0px'}}>

                                <p style={{padding:'10px 0px 10px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt="" />

                                                 </div>
                                   
                                          
                                          <p> 
                                          Estimados lectores, la recta final está más cerca que nunca!! 
                                          </p>
                                            
                                                   
                                            
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       Por lo tanto ya conocemos los conceptos de mundo inalámbrico, y haciendo uso del mismo, en esta guía 
                                       aprenderán a dominar uno de esos tipos de conexiones que ya conocen desde la guía anterior, por el cual van a poder 
                                       controlar remotamente los prototipos que han venido construyendo sesión a sesión.
                                       </p>

                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          

                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                 
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px', textDecorationLine:'underline', color:'red'}}><h4 style={{fontFamily:'Montserrat-SemiBold' }}>Conexion bluetooth</h4> <h4 style={{ fontFamily:'Montserrat-SemiBold'}}>Objetivos de la guia</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                     
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de conocer los conceptos específicos 
                                    de conexión Bluetooth, de igual manera aprenderán a configurar el módulo Bluetooth en la placa 
                                    arduino, programar un algoritmo para dominar componentes y configurar una aplicación para 
                                    establecer conexión con un dispositivo móvil.                                
                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                    ● Aprender conceptos específicos de conexiones Bluetooth.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Realizar conexión física con la placa arduino. 
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Descargar y configurar aplicativo en android para conexión y control del módulo Bluetooth.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Programar algoritmo para interacción entre aplicativo y componentes electrónicos mediante módulo Bluetooth.
                                     </p>


                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                    
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: El Bluetooth</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'5px 0px 5px 0px'}}>
                                      
                                    La transferencia de datos entre distintos dispositivos digitales no suele presentar ningún problema, 
                                    siempre y cuando tengas a mano el cable de conexión adecuado. Si no es así, tendrás que tomar el 
                                    camino más largo y compartir las fotos de la noche anterior con tus amigos a través de una red wifi 
                                    o de la nube. Pero hay otra manera de compartir imágenes, vídeos y archivos de audio que tiene 
                                    un funcionamiento muy sencillo.
                                   </p>

                                   <p>Hace posible transmitir datos y voz entre distintos dispositivos (por ejemplo, de teléfono a teléfono) 
                                       mediante un enlace por radiofrecuencia. Cuenta con poca cobertura de emisión, por lo que sólo se 
                                       pueden conectar dispositivos que se encuentren dentro del rango de alcance de la red Bluetooth.</p>

                                  

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/bluetooth3.png" alt="" />

                                     </div>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     El término Bluetooth describe una tecnología de red desarrollada por el grupo de trabajo IEEE 
                                     802.15.1 del Institute of Electrical and Electronics Engineers estadounidense como estándar 
                                     industrial para conexiones inalámbricas.
                                     </p>

                         

                                   
                                  
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                               
                                    
                                      <p>
                                      La tecnología Bluetooth sirve para la transferencia de 
                                      voz y datos punto a punto sin conexión u orientada a la conexión entre dos dispositivos digitales 
                                      diferentes. El objetivo principal de esta tecnología es reemplazar las conexiones por cable, es decir, 
                                      dejarlas obsoletas, lo cual supone una ventaja, sobre todo, para dispositivos móviles como smartphones o tabletas.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     En comparación con otras tecnologías de transferencia de datos como USB, LAN o Wi-Fi, Bluetooth 
                                     está especializada en la transferencia de datos en distancias cortas, así como en el establecimiento 
                                     de conexiones sencillas y de bajo consumo. Puesto que, en comparación con las demás tecnologías 
                                     mencionadas, en general sólo alcanza velocidades bajas de transferencia de datos, el envío de 
                                     paquetes grandes puede requerir algo más de tiempo. Si el objetivo es enviar archivos individuales o 
                                     servicios y aplicaciones menos complejos, Bluetooth representa, sin lugar a dudas, la solución ideal.  
                                     </p>

                                     <b>¿Por qué existe Bluetooth?</b>

                                     <p>
                                     La invención de Bluetooth deriva del problema, por todos conocido, de la “maraña de cables”: ya en 
                                     los años ochenta se intentaron sustituir las tecnologías de conexión tradicionales de cables por 
                                     alternativas inalámbricas siguiendo distintos métodos.
                                     </p>



                                   

                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                        <p>
                                        Una candidata prometedora fue la tecnología de infrarrojos que, por ejemplo, se utilizó
                                         para comunicar ordenadores e impresoras. Sin embargo, el consumo de energía relativamente alto, unido
                                          a la necesidad de establecer y mantener un “contacto visual” directo entre los aparatos a conectar, impidió que la tecnología se impusiera
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        En los años noventa, un consorcio formado por las empresas electrónicas Ericsson, IBM, Intel, Nokia 
                                        y Toshiba dio vida al Bluetooth Special Interest Group (abreviado: Bluetooth SIG), que buscaba 
                                        desarrollar una solución tecnológica propia. Hasta el momento, “Bluetooth” era únicamente el nombre en clave del proyecto.
                                         Sin embargo, ante la ausencia de otras propuestas, pronto se aceptó como el nombre comercial definitivo.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        El hecho de que las empresas participantes Ericsson y Nokia tuvieran origen escandinavo puede que 
                                        fuera un factor determinante en la elección del nombre: la palabra “Bluetooth” hace referencia 
                                        concretamente a Harald Blåtand, rey vikingo danés. En el siglo X, este rey consiguió unir a las partes 
                                        enfrentadas de Noruega y Dinamarca en un mismo reino. El icónico símbolo de Bluetooth 
                                        representa, dicho sea de paso, una combinación de las runas nórdicas ᚼ y ᛒ, que son las iniciales de 
                                        Harald Blåtand (HB).

                                        </p>





                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                    <b>La conexión Bluetooth</b>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    La frecuencia dedicada a Bluetooth es una banda ISM sin licencia entre los 2,402 GHz y los 2,480 GHz. 
                                    Los dispositivos compatibles que cumplen los estándares del Bluetooth SIG pueden, como 
                                    dispositivos de corto alcance o Short Range Devices (SRD), enviar por este rango de frecuencias en 
                                    todo el mundo y sin licencia. Para poder identificarlo sin ningún género de dudas, cada aparato está 
                                    provisto de una dirección MAC de 48 bits individual.

                                    </p>



                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Una conexión puede iniciarse en cualquier tipo de dispositivo, que se erige en “master” 
                                    (“maestro”) frente a los “slaves” (“esclavos”, es decir, los dispositivos implicados) y que juntos 
                                    establecen una red conocida como “piconet” (una red Bluetooth). Esta puede existir durante un 
                                    tiempo indefinido hasta que el maestro vuelva a desactivar la función Bluetooth en su sistema. Los 
                                    dispositivos que se quieren conectar a una piconet “escuchan” en modo exploración cada 2,56 
                                    segundos a la espera de la señal del maestro. El establecimiento de la conexión se efectúa de media 
                                    en 1,28 segundos. La conexión de dos o más dispositivos vía Bluetooth también se conoce como “pairing” (“emparejar”).

                                    </p>

                                         
 
                                
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                            <p>
                                            En la práctica, los usuarios de una piconet tienen que encontrarse en proximidad directa y tener 
                                            activa la función Bluetooth en el dispositivo correspondiente. La activación se realiza, según el 
                                            dispositivo, a través de un software especial, un panel de control o una tecla con el símbolo de 
                                            Bluetooth. Después, el establecimiento de la conexión debe autorizarse por medio de un código PIN 
                                            (que suele tener cuatro dígitos) que aparece en la pantalla del dispositivo esclavo o que se indica en 
                                            el manual en cuestión. Este proceso, también conocido como “asignación de clave”, garantiza la 
                                            seguridad frente a terceros y, por lo general, se realiza una sola vez. Después, el dispositivo “emparejado” 
                                            se almacena en una lista y se conecta siempre de forma automática en cuanto la piconet está dentro de su 
                                            alcance —siempre y cuando el Bluetooth esté activado.
                                            </p>

                                            <b>La red Bluetooth (piconet)</b>

                                            <p>
                                            Una red conocida como piconet está integrada por ocho dispositivos Bluetooth activos como 
                                            máximo. Además, en teoría, la red puede mantener hasta otros 200 dispositivos en modo de espera 
                                            o de ahorro de energía al mismo tiempo dentro de la red y que se activen a petición. 
                                            </p>

                                                             
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 

                                    <p>
                                    Un dispositivo Bluetooth puede estar registrado como esclavo en distintas piconets, pero solo puede funcionar 
                                     como maestro en una. Hasta diez piconets forman lo que se conoce como scatternet. Todos los 
                                     dispositivos que conforman la red pueden ponerse en contacto con los demás. Sin embargo, la 
                                     velocidad de transmisión de datos se resiente.
                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2.5em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿En qué tipo de dispositivos se utiliza Bluetooth?</h5></div>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Bluetooth tiene muchos usos y aplicaciones. Estos son algunos ejemplos:
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Ordenadores de sobremesa y portátiles:</span>  muchos sistemas operativos actuales Windows y 
                                    Linux son compatibles con Bluetooth de serie. Un chip integrado en el hardware permite la 
                                    conexión de distintos periféricos como ratones, teclados, auriculares o impresoras. Además, la 
                                    tecnología inalámbrica se utiliza para los tokens de seguridad de la autenticación de doble factor.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Dispositivos móviles:</span> prácticamente todos los teléfonos y tabletas modernos integran chips 
                                    Bluetooth. Gracias a ellos podemos sincronizar archivos, fotos y vídeos con el ordenador personal.

                                    </p>







                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                        <p>
                                        ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Dispositivos de salida de audio:</span>  muchos altavoces y auriculares inalámbricos disponen de 
                                        Bluetooth, lo que permite reproducir archivos MP3 directamente desde el smartphone.
                                        </p>

                                        <p style={{ padding:'5px 0px 0px 0px'}}>
                                        ●<span style={{fontFamily:'Montserrat-SemiBold'}}>Dispositivos de manos libres:</span>  si el smartphone se acopla por Bluetooth al dispositivo de 
                                        manos libres del automóvil o del casco, se puede responder a las llamadas entrantes 
                                        directamente. En vehículos bien equipados, el ordenador de a bordo también es compatible 
                                        con esta tecnología inalámbrica, lo que permite navegar por los contactos y menús del 
                                        teléfono móvil desde la pantalla del habitáculo.
                                        </p>

                                            <p style={{ padding:'5px 0px 0px 0px'}}>
                                            ● <span style={{fontFamily:'Montserrat-SemiBold'}}> Dispositivos deportivos y domótica:</span> algunas pulseras de actividad y relojes inteligentes 
                                            envían datos relativos a la salud directamente a la aplicación de salud del smartphone a través 
                                            de Bluetooth. Los electrodomésticos con Bluetooth, los sistemas de alarma, las cerraduras electrónicas 
                                            o los marcos de fotos digitales también han contribuido al éxito de los sistemas de domótica en todo el mundo.
                                            </p>


                                            <p style={{ padding:'5px 0px 0px 0px'}}>
                                            ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Juguetes y videoconsolas:</span> la industria del juguete lanza constantemente al mercado nuevas 
                                            muñecas y figuras de acción que pueden comunicarse e interactuar entre ellas a través de 
                                            Bluetooth. Los mandos de videoconsolas tan populares como Nintento Switch, Play Station 4 
                                            y Xbox ONE también funcionan a través de este estándar inalámbrico.
                                            </p>

                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                            
                                <p>
                                   ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Tecnología médica:</span> los audífonos de gama alta pueden estar equipados con función de 
                                    manos libres gracias a la tecnología Bluetooth. Además, ahora es posible configurar de forma 
                                  inalámbrica prótesis avanzadas de brazos y piernas, bombas de insulina y medidores de glucosa
                                  </p>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                  ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Industria:</span> la tecnología de comunicación inalámbrica no se olvida de impulsar la propia 
                                  industria, pues las máquinas y las plantas de fabricación están interconectadas y hacen posible 
                                  que se puedan automatizar aún más los procesos de producción.
                                  </p>

                                  <p style={{ padding:'3px 0px 0px 0px'}}>
                                        <span style={{color:'red'}}>Amplia informacion aqui.</span>
                                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ionos.es/digitalguide/servidores/know-how/que-es-bluetooth/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                    </p>


                                    <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 10px 0px'}} >

                                                            <div style={{padding:'0px 10px 0px 0px'}}>

                                            <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                                </div>

                                                <div className="container" style={{width:'95%'}}>


                            <p> Para que entiendan con más facilidad  los conceptos anteriores reproduce el 
                            siguiente video :<span style={{color:'red'}}>: Una explicación simple de cómo funciona Bluetooth | La magia de los auriculares inalámbricos</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=A2romSdr2Qw&list=PLHsWxcSr3WR4BTj_Wp6QDKJY3Qa7CV1Vn&index=8">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                                        </p>

                                            </div>

                                             </div>

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Módulo Bluetooth HC-05</h5></div>

                                    <p>
                                    El HC-05 módulo bluetooth maestro es un pequeño módulo transmisor/receptor TTL diseñado 
                                    para ser controlado a través de RS232. Permite transmitir como recibir datos a través de tecnología 
                                    bluetooth sin conectar cables a los dispositivos a comunicar. Es un dispositivo muy fácil de usar y 
                                    compacto se controla mediante comandos AT por el puerto serie. Es compatible con Arduino o 
                                    cualquier microcontrolador con UART. Este dispositivo es la versión HC-05 que es maestro, si 
                                    buscas el esclavo HC-06 también lo manejamos.
                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/bluetooth4.png" alt="" />

                                                  </div>

                                                  <b>ESPECIFICACIONES</b>

                                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                                  ● Módulo bluetooth hc-05 controlado por RS232.
                                                  </p>


                                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                                  ● De fácil uso y completamente encapsulado
                                                  </p>

                                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                                  ● Antena en PCB.
                                                  </p>

                                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                                  ● Chipset: CSR.
                                                  </p>

                                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                                  ● Version bluetooth: V2.0.
                                                  </p>


                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Voltaje de operación: 3.3V
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Corriente de operación: 20 a 30mA.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Baud rates definidos por el usuario: 1200, 2400, 4800, 9600, 19200, 38400, 57600 y 115200.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Default 9600, N, 8, 1.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Password: 1234

                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Ideal para proyectos de robótica.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Función: dispositivo bluetooth maestro
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Compatiblidad: Arduino UNO R3, Arduino MEGA R3, Raspberry pi 3
                                    </p>
                                    <p style={{ padding:'3px 0px 5px 0px'}}>
                                        <span style={{color:'red'}}>Amplia informacion aqui.</span>
                                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://hetpro-store.com/hc-05/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conexion arduino</h5></div>

                                    <p  style={{ padding:'5px 0px 0px 0px'}}>
                                    El módulo bluetooth viene diseñado para trabajar en los puertos 1 y 2, pero se debe tener en cuenta 
                                    que estos puertos son especiales y están directamente conectado al puerto serie, por lo tanto cada 
                                    vez que vayamos a subir un algoritmo a la placa arduino, debemos asegurarnos que el módulo esté 
                                    desconectado de la fuente de alimentación, esto quiere decir que debe estar apagado para poder 
                                    subir el codigo y luego volverlo a encender para ser usado.
                                    </p>




                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                               
                                         
                                <div style={{padding:'0px 0px 0px 0px'}}>

                <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/arduino23.png" alt="" />

                                           </div>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           Algo muy importante a tener en cuenta también, es que también se pueden usar otros pines, 
                                           preferiblemente PWM, y se deben tener en cuenta para hacer el cambio en el bloque. si 
                                           definitivamente nos decidimos en dejar la conexión por defecto, debemos de tener en cuenta que 
                                           los pines entre el módulo y la placa arduino se cruzan, esto quiere decir que el pin RxD del módulo 
                                           se conecta directo a Tx del arduino y el TxD del módulo al Rx del arduino como se muestra en la anterior imagen.

                                           </p>

                                           <b>Descargar e instalar aplicación para control por bluetooth</b>

                                           <p style={{padding:'5px 0px 0px 0px'}}>
                                           Para poder hacer el control de diferentes componentes por medio de un dispositivo celular o tablet, 
                                           necesitamos para ello tener una aplicación con la cual nos conectaremos al módulo, para ello 
                                           tenemos dos opciones, la primera es construir una a través de un lenguaje de programación como 
                                           el App Inventor y la segunda es usar una que esté disponible en la play store.
                                           </p>                             
                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                        <p>
                                        Para el desarrollo de este proyecto optamos por el uso de la segunda opción, puesto que nos ahorra tiempo y es algo que 
                                        ya está probada y es funcional, por lo que después de ensayar varias aplicaciones, se determinó usar 
                                        la aplicación Bluetooth Serial Controller por su flexibilidad y facilidad de personalización
                                        </p>

                                        <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/bluetooth5.png" alt="" />

                                                       </div>

                                                       <p style={{padding:'10px 0px 10px 0px'}}>
                                                       Para descargar esta aplicación nos dirigimos a abrir la play store de nuestro teléfono.
                                                       </p>

                                                       <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/android1.png" alt="" />

                                                      </div>



                                     

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <p>
                                En el buscador de aplicaciones, se escribe <span style={{fontFamily:'Montserrat-SemiBold'}}>bluetooth serial controller</span> y luego se presiona el botón 
                                instalar para que comience el proceso de instalación, ten en cuenta que debes tener espacio en 
                                memoria y estar conectado a internet para hacer todo este proceso.
                                </p>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                            <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/android2.png" alt="" />

                                            </div>

                                            <p>
                                            Antes de iniciar el proceso de instalación va a pedir permisos para acceder a contenido multimedia 
                                            y conexiones de bluetooth para poder funcionar correctamente por lo cual presionamos el botón aceptar.
                                            </p>

                                              <div style={{padding:'10px 0px 0px 0px'}}>

                         <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/android3.png" alt="" />

                                                     </div>




                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                               
                               <p>
                               Cuando termina el proceso de instalación solo es presionar en el botón abrir.
                               </p>

                               <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/android4.png" alt="" />

                                              </div>

                                              <p>
                                              Por último nos abre la interfaz de la aplicación para ser configurada
                                              </p>

                                              <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/android5.png" alt="" />

                                         </div>




      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>


                                <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 5px 0px'}} >

                                            <div style={{padding:'0px 10px 0px 0px'}}>

                                        <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                                    </div>

                                <div className="container" style={{width:'95%'}}>


                    <p> Sólo falta configurar la aplicación para que podamos usarla y definir los datos de
                        envío, necesarios para el desarrollo del algoritmo en el programa mBlock, te invito
                        a que veas todo el proceso de configuración en el siguiente video:<span style={{color:'red'}}>  : BlueTooth Serial Controller - Aprenda a Configurarlo - ¡¡¡Facil y Rapido!!!</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=jyToLTPXwqk">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                </p>

                </div>

                    </div>


    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Programación mBlock</h5></div>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                Ahora vamos a aprender cómo se hace la programación del módulo bluetooth en nuestro
                                 programa mBlock.
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                Como hemos hecho en guías anteriores, debemos agregar un nuevo grupo de bloques a nuestra 
                                área de bloques, por lo tanto vamos a extensiones y ponemos la palabra bluetooth en el cuadro de búsqueda.
                                </p>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                        </div>



                            
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 20 */}
                 <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                <div style={{padding:'5px 0px 0px 0px'}}>

                <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/mblock15.png" alt="" />

                                        </div>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        Escogemos HC-05 Bluetooth PCM porque ya está ensayado y a diferencia de los demás, nos brinda unas estructuras
                                         claras y nos ahorra trabajo a la hora de realizar esas mismas estructuras, con esa misma lógica con la combinación 
                                         de otros bloques.
                                        </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                        Podemos observar que se nos agrega un nuevo grupo de bloques con las opciones 
                                        específicas que necesitamos para interactuar con nuestro módulo Bluetooth y otros dispositivos.
                                        </p>

                                                 
                                <div style={{padding:'5px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/mblock16.png" alt="" />

                                             </div>






                          </div>

                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 21 */}
                <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                    <p>
                                    El siguiente bloque nos sirve para hacer la configuración de nuestro módulo, con el que vamos a programar el algoritmo para nuestro sensor.
                                    </p>

                                    <div style={{padding:'5px 0px 0px 0px'}}>

                    <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/mblock17.png" alt="" />

                                         </div>

                                         <p>
                                         El siguiente bloque, es una combinación de una estructura condicional y un bloque de recepción de 
                                         datos del módulo, ideal para preguntar si se está recibiendo algún dato desde el módulo, sin importar 
                                         que sea.
                                         </p>


                                         <div style={{padding:'5px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/mblock18.png" alt="" />

                                         </div>

                                         <p>
                                         El siguiente bloque hace lo mismo que el anterior pero con un comparativo para filtrar acciones 
                                         según lo que se esté recibiendo por medio del módulo.

                                         </p>

                                         <div style={{padding:'5px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/mblock19.png" alt="" />

                                            </div>

                                            <p>
                                            El siguiente bloque se usa solo para verificar si se está recibiendo algún dato del módulo bluetooth.
                                            </p>

                                            <div style={{padding:'5px 0px 0px 0px'}}>

                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/mblock20.png" alt="" />

                                            </div>




                          </div>

                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                    <p>
                                    El siguiente bloque sirve para usar los datos que se están recepcionando en cualquier estructura de control que tenemos disponibles para armar nuestros algoritmos.
                                    </p>

                                    <div style={{padding:'5px 0px 0px 0px'}}>

                              <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/mblock21.png" alt="" />

                                             </div>

                                             <p>
                                             El siguiente bloque sirve para enviar datos a otros componentes bluetooth, cuando configuramos 
                                             nuestro módulo como un máster, algo que es poco usado pero se puede hacer
                                             </p>

                                             <div style={{padding:'5px 0px 0px 0px'}}>

                    <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/mblock22.png" alt="" />

                                                </div>
                                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>

                                                <p style={{padding:'5px 0px 0px 0px'}}>
                                                ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                                </p>

                                                <p style={{padding:'5px 0px 0px 0px'}}>
                                                Ahora que ya sabemos hacer la conexión de nuestro módulo 
                                                bluetooth, y hemos adquirido otros conocimientos con 
                                                anterioridad, se requiere hacer el encendido y apagado de 3 
                                                diodos LED, mediante el uso de un aplicativo remoto.
                                                </p>

                                  
                               

                          </div>

                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 23 */}
                     <div id="movPage23" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                            
                                <p>
                                El formador de su territorio, les suministrará los siguientes componentes:
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● 1 Placa Arduino
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● 1 placa de prototipos (Protoboard)
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● 2 Diodos LED
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● 2 Resistencias de 220 Ohm
                                </p>

                                <p style={{padding:'5px 0px 0px 0px'}}>
                                ● 1 Módulo Bluetooth HC-05
                                </p>

                                <p style={{padding:'5px 0px 5px 0px'}}>
                                ● Cables Jumpers M-M

                                </p>


                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>

                                <p style={{padding:'5px 0px 5px 0px'}}>
                                ● Video y archivo.mblock de cada grupo con el reto completado. 
                                </p>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>


                                    <p style={{padding:'5px 0px 5px 0px'}}>
                                    ● ¿Qué es Bluetooth? Toda la información sobre el estándar inalámbrico. (2022, 18 marzo). IONOS Digitalguide
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ionos.es/digitalguide/servidores/know-how/que-es-bluetooth/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                    </p>

                                    <p style={{padding:'5px 0px 5px 0px'}}>
                                    ● HeTPro. (s. f.). HeTPro 
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://hetpro-store.com/hc-05/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                    </p>
                          </div>

                         </div>
                     <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                
                  



                {/* paper 24 */}
                <div id="movPage24" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="19" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="19" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
     
      </div>
    );
  }
}

export default Guia2Naidi5;
