import React, { Component } from "react";
import Lista from "../Lista";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import fguia9 from "../../svg/portadas-front-end/guia-1-3.svg";
import fguia10 from "../../svg/portadas-front-end/guia-2-3.svg";
import fguia11 from "../../svg/portadas-front-end/guia-3-3.svg";
import fguia12 from "../../svg/portadas-front-end/guia-4-3.svg";
import fguia13 from "../../svg/portadas-front-end/guia-5-3.svg";
import fguia14 from "../../svg/portadas-front-end/guia-6-3.svg";

class GuiaMd3  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Guías</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', '', false,'fguia9')}><Lista courses="Guía I" imgurl={fguia9} imgalt="¿Qué es JavaScript?"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia10')}><Lista courses="Guía II" imgurl={fguia10} imgalt="Tomando Decisiones"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia11')}><Lista courses="Guía III" imgurl={fguia11} imgalt="JavaScript Orientado a Objetos"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia12')}><Lista courses="Guía IV" imgurl={fguia12} imgalt="APIS"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia13')}><Lista courses="Guía V" imgurl={fguia13} imgalt="Almacenamiento del Lado del Cliente"/></span>
                <span onClick={(e)=>this.someMethod('', '', false,'fguia14')}><Lista courses="Guía VI" imgurl={fguia14} imgalt="Presentación de JavaScript Asincrónico"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default GuiaMd3;
