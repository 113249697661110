import React, { Component } from 'react';
/* components */
import ControlList from './component/ControlList.jsx';
import ModuloList from './component/frontend/ModuloList.jsx';
import ModuloList2 from './component/naidi/ModuloList2.jsx';
import ModuloList3 from './component/startup/ModuloList3.jsx';
/* Frontend */
import GuiaList from './component/frontend/GuiaList.jsx';
import GuiaMd1 from './component/frontend/GuiaMd1.jsx';
import GuiaMd2 from './component/frontend/GuiaMd2.jsx';
import GuiaMd3 from './component/frontend/GuiaMd3.jsx';
import GuiaMd4 from './component/frontend/GuiaMd4.jsx';
import GuiaMd5 from './component/frontend/GuiaMd5.jsx';
/* Naidi */
import GuiaNaidi1 from './component/naidi/GuiaNaidi1.jsx';
import GuiaNaidi2 from './component/naidi/GuiaNaidi2.jsx';
import GuiaNaidi3 from './component/naidi/GuiaNaidi3.jsx';
import GuiaNaidi4 from './component/naidi/GuiaNaidi4.jsx';
import GuiaNaidi5 from './component/naidi/GuiaNaidi5.jsx';
/* Contenedor */
import Contents from './component/Contents.jsx';
/* css */
import './App.css';
/* librerias */
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome, faListUl, faShareAlt} from '@fortawesome/free-solid-svg-icons';
/* svg */
import logo from './svg/logo.svg';
import facebook from './svg/facebook.svg';
import instagram from './svg/instagram.svg';
import twitter from './svg/twitter.svg';
import linkedin from './svg/linkedin.svg';
import youtube from './svg/youtube.svg';

class App extends Component {

  constructor(props){
    super(props)
    this.state={
      listVw:true,
      modulo1Vw:false,
      modulo2Vw:false,
      modulo3Vw:false,
      /* contenido front-end */
      guiaVw:false,
      guia1Vw:false,
      guia2Vw:false,
      guia3Vw:false,
      guia4Vw:false,
      guia5Vw:false,
      /* contenido naidi */
      guia6Vw:false,
      guia7Vw:false,
      guia8Vw:false,
      guia9Vw:false,
      guia10Vw:false,
      contentsVw:false,
    }
  }

  Vw = (liVw, moduloVw, guiaVw, contVw) => {
    var modulo1, modulo2, modulo3;
    /* modulos */
    switch (moduloVw){
      case 'front':
        modulo1=true;
        break;
      case 'naidi':
        modulo2=true;
        break;
      case 'startup':
        modulo3=true;
        break;
      default:
        modulo1=false;
        modulo2=false;
        modulo3=false;
        break;
    }
    /* lista de guias por modulo */
    var guia0, guia1, guia2, guia3, guia4, guia5, guia6, guia7, guia8, guia9, guia10;
    switch (guiaVw){
      /* front-end */
      case 'front0':
        guia0=true;
      break;
      case 'front1':
        guia1=true;
      break;
      case 'front2':
        guia2=true;
      break;
      case 'front3':
        guia3=true;
      break;
      case 'front4':
        guia4=true;
      break;
      case 'front5':
        guia5=true;
      break;
      /* naidi */
      case 'naidi1':
        guia6=true;
      break;
      case 'naidi2':
        guia7=true;
      break;
      case 'naidi3':
        guia8=true;
      break;
      case 'naidi4':
        guia9=true;
      break;
      case 'naidi5':
        guia10=true;
      break;
      default:
        /* front-end */
        guia0=false;
        guia1=false;
        guia2=false;
        guia3=false;
        guia4=false;
        guia5=false;
        /* naidi */
        guia6=false;
        guia7=false;
        guia8=false;
        guia9=false;
        guia10=false;
      break;
    }
    
    /* guías */
    var cntVw;
    sessionStorage.setItem('libro', contVw);
    if(sessionStorage.getItem('libro').length >0){
      cntVw = true;
    };

    this.setState({
      listVw:liVw, /* representa la vista de los proyectos */
      modulo1Vw:modulo1, /* representa la vista de los modulos de Front-end */
      modulo2Vw:modulo2, /* representa la vista de los modulos de Pasifico 4.0 */
      modulo3Vw:modulo3, /* representa la vista de los modulos de Start up girl */
      /* Front-end */
      guiaVw:guia0, /* representa la vista de las gías de Front-end Introductorio */
      guia1Vw:guia1, /* representa la vista de las gías de Front-end modulo 1 */
      guia2Vw:guia2, /* representa la vista de las gías de Front-end modulo 2 */
      guia3Vw:guia3, /* representa la vista de las gías de Front-end modulo 3 */
      guia4Vw:guia4, /* representa la vista de las gías de Front-end modulo 4 */
      guia5Vw:guia5, /* representa la vista de las gías de Front-end modulo 5 */
      /* Naidi */
      guia6Vw:guia6, /* representa la vista de las gías de naidi modulo 1 */
      guia7Vw:guia7, /* representa la vista de las gías de naidi modulo 2 */
      guia8Vw:guia8, /* representa la vista de las gías de naidi modulo 3 */
      guia9Vw:guia9, /* representa la vista de las gías de naidi modulo 4 */
      guia10Vw:guia10, /* representa la vista de las gías de naidi modulo 5 */
      contentsVw:cntVw,
    });
  }

  listVw(){
    this.setState({
      /* lista de proyectos, cursos y libros */
      listVw:true,
      /* Contenedor de módulos por proyectos */
      modulo1Vw:false,
      modulo2Vw:false,
      modulo3Vw:false,
      /* Front-end */
      guiaVw:false,
      guia1Vw:false,
      guia2Vw:false,
      guia3Vw:false,
      guia4Vw:false,
      guia5Vw:false,
      /* naidi */
      guia6Vw:false,
      guia7Vw:false,
      guia8Vw:false,
      guia9Vw:false,
      guia10Vw:false,
      contentsVw:false,
    });
  }

  componentDidMount(){
    var elems = document.querySelectorAll('.fixed-action-btn');
    var instances = M.FloatingActionButton;
    instances.init(elems, {
        direction: 'top',
        hoverEnabled: false
      });
  }

  render() {
    return (
      <div className="App">
        {/* header */}
        <div className="App-header">
          <img src={logo}  width="150px" className="App-logo" alt="logo"/>
        </div>
        {/* section */}
        <div className="App-cont">
          <div className="App-mg">
            {/* Listas de proyectos, cursos y libros*/}
            {
              this.state.listVw?
              <ControlList handler={this.Vw}/>
              :null
            }
            {/* Listado de modulos */}
            {
              this.state.modulo1Vw?
              <ModuloList handler={this.Vw}/> /* Front-end */
              :null
            }
            {
              this.state.modulo2Vw?
              <ModuloList2 handler={this.Vw}/>/* Naidi */
              :null
            }
            {
              this.state.modulo3Vw?
              <ModuloList3 handler={this.Vw}/>/* Startup */
              :null
            }
            {/* Listado guías Front-End*/}
            {
              this.state.guiaVw?
              <GuiaList handler={this.Vw}/>/* Front-End */
              :null
            }
            {
              this.state.guia1Vw?
              <GuiaMd1 handler={this.Vw}/>/* Front-End */
              :null
            }
            {
              this.state.guia2Vw?
              <GuiaMd2 handler={this.Vw}/>/* Front-End */
              :null
            }
            {
              this.state.guia3Vw?
              <GuiaMd3 handler={this.Vw}/>/* Front-End */
              :null
            }
            {
              this.state.guia4Vw?
              <GuiaMd4 handler={this.Vw}/>/* Front-End */
              :null
            }
            {
              this.state.guia5Vw?
              <GuiaMd5 handler={this.Vw}/>/* Front-End */
              :null
            }
            {/* Listado guías Naidi*/}
            {
              this.state.guia6Vw?
              <GuiaNaidi1 handler={this.Vw}/>/* naidi */
              :null
            }
            {
              this.state.guia7Vw?
              <GuiaNaidi2 handler={this.Vw}/>/* naidi */
              :null
            }
            {
              this.state.guia8Vw?
              <GuiaNaidi3 handler={this.Vw}/>/* naidi */
              :null
            }
            {
              this.state.guia9Vw?
              <GuiaNaidi4 handler={this.Vw}/>/* naidi */
              :null
            }
            {
              this.state.guia10Vw?
              <GuiaNaidi5 handler={this.Vw}/>/* naidi */
              :null
            }
            {/* área de visualización todas las guías */}
            {
              this.state.contentsVw?
              <Contents/>
              :null
            }
          </div>
        </div>

        {/* footer */}
        <footer className="footer">
          <div className="footer__nav">
            {/* home */}
            <div className="App-btn" >
              <span className="App-lnk" style={{cursor:'pointer'}} onClick={(e) => this.listVw()}>
                <span className="">
                  <FontAwesomeIcon icon={faHome}/>
                </span>
              </span>
            </div>
            {/* list-brochure */}
            <div className="App-btn">
              <span className="App-lnk" style={{cursor:'pointer'}}>
                <span className="">
                  <FontAwesomeIcon icon={faListUl}/>
                </span>
              </span>
            </div>
            {/* to share */}
            <div className="App-btn">
              <span className="App-lnk" style={{cursor:'pointer'}}>
                <div className="fixed-action-btn" style={{position:'relative', right:0, bottom:0, paddingTop:0}}>
                  <a className="" href="/#">
                    <span className="lnk" style={{color:'white'}}>
                      <FontAwesomeIcon icon={faShareAlt}/>
                    </span>
                  </a>
                  <ul>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#3b5998', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.facebook.com/ManosVisibles/" target="_blank" rel="noreferrer">
                        <img src={facebook} alt="facebook" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#125688', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.instagram.com/manosvisibles/" target="_blank" rel="noreferrer">
                        <img src={instagram} alt="instagram" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#007bb5', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.linkedin.com/in/manos-visibles-078503123" target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="linkedin" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#55acee', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://twitter.com/manosvisibles" target="_blank" rel="noreferrer">
                        <img src={twitter} alt="linkedin" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#bb0000', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.youtube.com/user/ManosVisibles" target="_blank" rel="noreferrer">
                        <img src={youtube} alt="linkedin" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
            {/* contacts */}
            {/* <div className="App-btn">
              <span className="App-lnk" style={{cursor:'pointer'}}>
                <div className="fixed-action-btn" style={{position:'relative', right:0, bottom:0, paddingTop:0}}>
                  <a className="" href="/#">
                    <span className="lnk" style={{color:'white'}}>
                      <FontAwesomeIcon icon={faAddressBook}/>
                    </span>
                  </a>
                  <ul>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#4dc247', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://api.whatsapp.com/send?phone=573135906515">
                        <img src={whatsapp} alt="whatsapp" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating red" style={{display:'flex', justifyContent:'center', alignItems:'center'}} href="mailto:ceo@koulutus.com.co​">
                        <img src={email} alt="email" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating blue" style={{display:'flex', justifyContent:'center', alignItems:'center'}} href="tel:+573135906515​">
                        <img src={phone} alt="phone" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div> */}
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
