import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia 12 */
class Guia2Naidi3  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
        {/* paper 1 */}
        <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 3</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Control y bucles</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p> 

                                          Estimados lectores, el avance que han logrado hasta el 
                                          momento ha sido significativo, con lo cual pueden construir 
                                          cualquier tipo de algoritmo sin ninguna dificultad, puesto que 
                                          con las estructuras de control, es muy fácil tomar decisiones 
                                          según los datos con los que se cuenten, y ejecutar tareas 
                                          múltiples sin que nada los detenga.
                                          </p>
                                            
                                                          
                                            </div>

                                            </div>
                                   

                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                       <p>
                                       Pero en ocasiones eso no es suficiente, puesto que en selectos 
                                       casos, nos vamos a encontrar con momentos en donde 
                                       debemos hacer una tarea repetidamente y con solo 
                                       condicionales se torna extenso y difícil, por lo cual en ésta guía 
                                       aprenderán un nivel más de complejidad con estructuras que 
                                       les permitirán hacer tareas múltiples ahorrando así mucho de 
                                       trabajo y esfuerzo.
                                       </p>

                                       
                                        <p  style={{padding:'3px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          


                                   
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
             
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Objetivos de la guia</h4> <h4 style={{ fontWeight:'bold'}}>Control y bucles</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                        
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de dominar el concepto de
                                    estructuras repetitivas o ciclos, de igual manera serán capaces de construir un circuito electrónico
                                    y programarlo para que ejecute tareas repetitivas específicas.
                                        
                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Aprender conceptos específicos estructuras repetitivas o ciclos.


                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Aprender conceptos zumbador o buzzer
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Construir prototipo de conteo sonoro.
                                     </p>


                  
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                  
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptualizacion: ¿Qué es un ciclo?</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Así como los condicionales, los ciclos también realizan preguntas. La diferencia es que hacen la 
                                    misma pregunta una y otra vez hasta que cierta tarea haya sido elaborada.
                                   </p>

                                   <p style={{ padding:'5px 0px 0px 0px'}}>  
                                   Por ejemplo, cuando clavas una puntilla en una pared. Aunque no lo notas, constantemente te preguntas: "¿ya entró la puntilla?

                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Cuando la respuesta es negativa, martillas nuevamente y continúas repitiendo esta pregunta hasta 
                                    que la respuesta sea afirmativa y puedas parar.

                                    </p>

                                  
                                    
                                  
                                    <div style={{padding:'5px 0px 0px 0px'}}>

                  <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/martillo.png" alt="" />

                                     </div>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Los ciclos te ayudan a programar tareas repetitivas, sin necesidad de hacer el mismo código una y 
                                    otra vez, para la misma acción.
                                    </p>


                                     <p>
                                      <span style={{color:'red'}}>Amplía información aquí</span>
                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://edu.gcfglobal.org/es/informatica-avanzada/secuencias-condicionales-y-ciclos/1/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                    </p>

                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                                
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p>
                                    Los ciclos o bucles de programación (también denominados estructuras de control iterativas o 
                                    repetitivas) son sentencias que se utilizan para ejecutar una o varias instrucciones de forma repetitiva 
                                    cuando sea necesario.
                                    </p>

                                    <p style={{padding:'5px 0px 5px 0px'}}>
                                    El funcionamiento es más sencillo de lo que parece, ya que estas estructuras están controladas por 
                                    ciertas variables o condiciones. De esa manera es posible tener un control de la cantidad de 
                                    repeticiones y así evitar la aparición de bucles infinitos. No obstante, existen varios tipos de ciclos de 
                                    programación y cada uno de ellos tiene su propia finalidad.
                                    </p>

                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tipos de ciclos de programación</h5></div>

                                    <div style={{padding:'40px 0px 0px 0px'}}>

                        <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/ciclo1.png" alt="" />

                                              </div>
                                
                      
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                   <b>Ciclo for </b>

                                    <p>
                                    ● En español se traduce como “ciclo para” y es uno de los bucles más utilizados en la programación 
                                    gracias a su estructuración, ya que permite determinar el número de repeticiones a realizar (además, 
                                    es necesario conocer dicho valor para su uso). El mismo está compuesto por cuatro parámetros:
                                    </p>

                                    <p  style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Variable de inicio del ciclo:</span>  es donde se comenzará a ejecutar
                                    </p>

                                    <p  style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Condición o límite del ciclo:</span> sirve como determinante de la continuidad o finalización del ciclo. Solo 
                                    se repite en caso de que la condición sea verdadera.
                                    </p>

                                    <p  style={{padding:'5px 0px 0px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Modificador:</span>  se encargará de modificar la variable cuando sea necesario.
                                    </p>

                                    <p  style={{padding:'5px 0px 5px 0px'}}>
                                    ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Cuerpo:</span>  donde se añaden las instrucciones a repetir, pudiendo ser una o varias.
                                    </p>

                                    <b>Ciclo while</b>

                                    <p  style={{padding:'5px 0px 0px 0px'}}>
                                    El ciclo de programación while (que se traduce como “ciclo mientras”) permite establecer una 
                                    condición donde se repetirán las instrucciones hasta que dicha condición no se cumpla.
                                    </p>

                   

                              
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                <p>
                                 A diferencia del ciclo anterior, este no cuenta con un valor inicial o final, solo está compuesto por la 
                                 condición. Sin embargo, es necesario añadir una sentencia que finalice el ciclo.
                                 </p>

                                    <p style={{padding:'5px 0px 5px 0px'}}>
                                    Este bucle se utiliza cuando se desea repetir ciertas instrucciones un número indeterminado de 
                                    veces hasta que no se cumpla la condición
                                    </p>

                                 <b> Ciclo do-while</b>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Se traduce como “repetir mientras”. A diferencia del bucle anterior, el bucle do-while primero ejecuta 
                                    las instrucciones y luego hace la comprobación de la condición especificada, lo que significa que se 
                                    ejecutará al menos una vez. En otras palabras, las acciones se realizan hasta que se cumpla la 
                                    condición.
                                    </p>

                                    <h5 style={{padding:'10px 0px 0px 0px'}}>¿Por qué utilizar bucles en programación y cuándo hacerlo?</h5>

                                    <p style={{padding:'10px 0px 0px 0px'}}>
                                    A pesar de que los principiantes suelen creer que duplicar el código puede ser una alternativa, en 
                                    realidad es una mala decisión. Esto se debe a que repetir códigos trae consigo una serie de 
                                    problemas, como menor productividad, dificultad de revisión o actualización del programa y un 
                                    mayor peso del archivo.

                                    </p>

                                         
 
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  
                                  <p>
                                  En otras palabras, la importancia de los ciclos de programación radica en la posibilidad de:
                                  </p>
                                      
                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Disminuir la cantidad de instrucciones a usar
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Reducir el tamaño del programa.

                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Programar con mayor rapidez.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● Solicitar datos según un valor desconocido al momento de ejecutar la aplicación.
                                      </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    El uso de los bucles de programación puede ser confuso cuando no se tiene experiencia. Sin 
                                    embargo, existe una forma de saber cuándo utilizar el ciclo for, while o do-while. Esta técnica consiste 
                                    en analizar si existe una cantidad determinada o indeterminada de iteraciones. En caso de contar 
                                    con número definido de repeticiones, se debe utilizar for; mientras que en caso contrario se debe 
                                    optar por elegir while o do-while según las funcionalidades descritas anteriormente.
                                    </p>

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    <span style={{color:'red'}}>Amplía información aquí</span>
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.explicacion.net/ciclos-de-programacion/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                    </p>


                                    <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 10px 0px'}} >

                                                  <div style={{padding:'0px 10px 0px 0px'}}>

                                      <img style={{width:'100%'}} src="./img/valle4.png" alt=""/>

                                                    </div>

                                  <div className="container" style={{width:'95%'}}>


<p> Para que entiendan con más facilidad los conceptos anteriores reproduce el
siguiente video :<span style={{color:'red'}}>: Estructuras de programación (qué es secuencia, condicional,
ciclo) | Computación y programación</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=rNY5eWogl18">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
</p>

</div>

</div>




                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Práctica mBlock</h5></div>

                                  <p style={{ padding:'5px 0px 0px 0px'}}>
                                       
                                  Por ahí se ha escuchado el dilema eterno de que ¿quién fue primero el huevo o la gallina?
                                        </p>

                                      
                                        <div style={{padding:'5px 0px 5px 0px'}}>

                                      <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/gallina.png" alt="" />

                                      </div>

                                      <p>
                                      Es algo que ya tiene solución y está demostrado científicamente <span style={{color:'red'}}>“puedes comprobarlo aquí”</span>  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 5px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="http://www.avicab.com/back/2018/07/19/que-fue-primero-el-huevo-o-la-gallina/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>, 
                                      pero ese no es problema, lo que debemos analizar es que la gallina
                                       tiene un ciclo, el cual es que de la gallina sale el huevo y del huevo sale la gallina y así se repite y repite y vuelve a repetir.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Lo mismo pasa con el tiempo, por ejemplo un día tiene 24 horas, luego de pasar las 24 horas vuelve
                                      a comenzar el conteo día tras día.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Otro ejemplo de ciclo es el del sol, todos los días sale y se esconde y vuelve a salir
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Lo mismo pasa en programación, entonces ya conociendo los conceptos de ciclos y cuales son los 
                                      tipos de ciclos que podemos encontrar, quiero contarles que el programa mBlock ha estructurado 
                                      los mismos conceptos en forma de bloques pero con algunas variaciones.
                                      </p>

                              
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                 <p>
                                 Antes que nada debemos conocer los bloques con los que vamos a trabajar, los cuales los 
                                 encontraremos en el grupo de bloques control y es el siguiente:
                                 </p>

                                 <p>
                                 El bloque repite, es el mismo ciclo for con la diferencia que solo nos permite modificar la variable de 
                                 límite, esto quiere decir que solo controlamos cuantas veces se va a repetir el conjunto de 
                                 instrucciones, por lo cual su inicio siempre va a ser cero, su límite menor que el que configuremos y 
                                 su incremento será de uno en uno.
                                 </p>
                                      
                                 <div style={{padding:'5px 0px 5px 0px'}}>

                      <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/ciclo2.png" alt="" />

                                        </div>

                                        <p>
                                        Existe un bloque con el cual nos hemos venido familiarizando y es el para siempre el cual es un ciclo 
                                        tipo Void Loop de arduino que no termina nunca, solo cuando le quitamos el fluido eléctrico a 
                                        nuestra placa, el cual nos sirve para hacer la estructura principal de nuestra placa arduino, no se 
                                        recomienda usarlo para instrucciones específicas, porque no habría forma de romperlo con una 
                                        condición y seguir con otras instrucciones.
                                        </p>


                                        <div style={{padding:'5px 0px 5px 0px'}}>

                              <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/ciclo3.png" alt="" />

                                                    </div>


                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Por último tenemos el bloque repite hasta que el cual realizará o ejecutara instrucciones que tenga 
                                  dentro cuantas veces sea necesario hasta que se cumpla una condición específica que le 
                                  indiquemos.

                                  </p>


                                  <div style={{padding:'5px 0px 5px 0px'}}>

                                <img style={{width:'35%', display:'block', margin:'auto'}} src="./img/ciclo4.png" alt="" />

                                  </div>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Zumbador o Buzzer</h5></div>

                                  <h5 style={{padding:'5px 0px 5px 0px'}}>¿Qué es un buzzer?</h5>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  Un zumbador o mejor conocido como buzzer (en inglés) es un pequeño transductor capaz de 
                                  convertir la energía eléctrica en sonido. Para hacerlos funcionar solo basta conectar el positivo con 
                                  el + y la tierra o negativo con el – de una batería o cualquier fuente de corriente directa.
                                  </p>

                                  <div style={{padding:'5px 0px 5px 0px'}}>

                                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/buzz.png" alt="" />

                                  </div>

                                  
                                   <b>¿Cómo funciona un buzzer?</b>
                                      


                                  <p>
                                  El funcionamiento se basa en el efecto piezoeléctrico de los materiales, Este efecto funciona de tal 
                                  manera que cuando aplicamos un voltaje el volumen del material cambia ligeramente.
                                  </p>



                                

                                

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p> 
                                  Los zumbadores están construidos con dos pequeñas placas una metálica y una cerámica, las cuales 
                                  aprovechan este efecto pero solo generan un click ya que los materiales cambian de forma pero no 
                                  regresan a su estado natural hasta que se les quita el voltaje.

                                  </p>

                                  <div style={{padding:'5px 0px 5px 0px'}}>

                                <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/buzz2.png" alt="" />

                                  </div>

                                  <p>
                                  Para que se pueda emitir un sonido continuo las placas necesitan vibrar constantemente, para eso 
                                  se instala un oscilador que hace que los materiales cambian de estado una y otra vez, y así puedan 
                                  cambiar miles de veces para poder alcanzar un audio perceptible.
                                  </p>


                                  <div style={{padding:'5px 0px 5px 0px'}}>

                                <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/buzz3.png" alt="" />

                                  </div>



                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p style={{padding:'0px 0px 5px 0px'}}>
                                  También podemos encontrar otro tipo de buzzer (electromagnético) que intercambia los 
                                  materiales por 1 imán permanente y 1 una bobina. Cuando la bobina recibe electricidad hace que 
                                  la chapa metálica vibre
                                  </p>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Tipos de buzzer?</h5></div>


                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      A pesar de que todos estos dispositivos a simple vista todos se ven iguales, existe una gran 
                                      diferencia en cuanto a su funcionamiento y se pueden clasificar en 2 tipos diferentes: 
                                      electromagnéticos y piezoeléctricos.
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      Dentro de los piezoeléctricos podemos encontrar 2 tipos:
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Sin oscilador:</span> Requieren de un voltaje y un oscilador externo para funcionar
                                      </p>

                                      <p style={{padding:'5px 0px 0px 0px'}}>
                                      ● <span style={{fontFamily:'Montserrat-SemiBold'}}>Con oscilador:</span> Cuentan con un oscilador interno lo que facilita el funcionamiento, ya que 
                                      solo es cuestión de aplicar voltaje.
                                      </p>

                                      <div style={{padding:'5px 0px 5px 0px'}}>

                          <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/buzz4.png" alt="" />

                                                   </div>

                                  

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <b>Aplicaciones</b>

                                <p style={{padding:'5px 0px 5px 0px'}}>
                                Estos dispositivos se utilizan principalmente para alarmas y controles de sonido con un pequeño 
                                margen de frecuencia, como en los electrodomésticos.  <span style={{color:'red'}}>Amplía información aquí</span>
                               <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ingmecafenix.com/electronica/el-buzzer/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                </p>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>


                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  En la siguiente actividad construiremos un prototipo que consiste 
                                  en generar un sonido y mostrar luz con un diodo LED, mediante 
                                  la generación de un número aleatorio del 1 al 10, después de 
                                  activar un pulsador, que al igual que en el dado electrónico, éste 
                                  mostrará con sonido el número seleccionado.
                                  </p>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     El formador de su territorio, les suministrará los siguientes componentes:
                                     </p>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     ● 1 Placa Arduino
                                     </p>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     ● 1 placa de prototipos (Protoboard)
                                     </p>

                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     ● 1 Diodos LED de diferentes colores
                                     </p>


                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● 2 Resistencia de 220 Ω
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● 1 Resistencia de 10 kΩ
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● Cables Jumpers M-M
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● 1 Zumbador o Buzzer
                                 </p>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 ● El esquema de circuito que van a construir es el siguiente:
                                 </p>

                                 <div style={{padding:'5px 0px 5px 0px'}}>

                    <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/arduino17.png" alt="" />

                                         </div>


                                         <div style={{padding:'40px 0px 5px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                                           </div>






                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <p>
                                  A continuación les mostraré solo una parte del algoritmo, para 
                                  que el reto de ésta actividad consista en que lo puedan 
                                  completar.
                                  </p>


                                  <div style={{padding:'5px 0px 5px 0px'}}>

            <img style={{width:'85%', display:'block', margin:'auto'}} src="./img/arduino18.png" alt="" />

                                       </div>

                                       <p>
                                       Para ver todo el proceso de ensamble y programación paso a paso en video, Presiona clic en el siguiente enlace:
                                       <span style={{color:'red'}}> Conteo Sonoro con mBlock</span>
                                       <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 5px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=oo_5YU-Dpkw">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.0rem', color:'red'}}/> </a></span>
                                      
                                       </p>


                                       <p style={{padding:'5px 0px 5px 0px'}}>
                                       ¡Ahora los reto!! deben agregar un diodo LED más y que cuando el número aleatorio sea menor o igual a 5, encienda 
                                       un led tal cual como lo hicimos, pero cuando el número sea mayor que 5, encienda el nuevo LED que agregaron y el tono
                                        del buzzer sea diferente.

                                       </p>
                               

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>

                                  <p style={{padding:'10px 0px 10px 0px'}}>
                                  ● Video y archivo.mblock de cada grupo con el reto completado. 
                                  </p>


                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                  ● Informática Avanzada: Secuencias, condicionales y ciclos. (s. f.). GCFGlobal.org. 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://edu.gcfglobal.org/es/informatica-avanzada/secuencias-condicionales-y-ciclos/1/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                  </p>

                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                  ● Mecafenix, I. (2020, 22 junio). Que es el buzzer y como funciona (zumbador). Ingeniería Mecafenix.
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ingmecafenix.com/electronica/el-buzzer/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>       
                                  </p>




      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                 

                {/* paper 19 */}
                <div id="movPage19" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia2Naidi3;
