import React, { Component } from "react";
import Lista from "../Lista.jsx";
import "../../styles/modulos.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import front0 from "../../svg/portadas-front-end/modulo-0.svg";
import front1 from "../../svg/portadas-front-end/modulo-1.svg";
import front2 from "../../svg/portadas-front-end/modulo-2.svg";
import front3 from "../../svg/portadas-front-end/modulo-3.svg";
import front4 from "../../svg/portadas-front-end/modulo-4.svg";
import front5 from "../../svg/portadas-front-end/modulo-5.svg";

class ModuloList  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

    render(){
        return(
          <div className="App-book">
            <div className="breadcrbs">
              <h5>Inicio</h5>
              <FontAwesomeIcon icon={faAngleRight}/>
              <h5>Módulos</h5>
            </div>
            <div className="modulos">
              <div className="Lista-modulos">
                <span onClick={(e)=>this.someMethod('', false, 'front0', '')}><Lista courses="Introductorio" imgurl={front0} imgalt="Módulo Introductorio"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'front1', '')}><Lista courses="Módulo I" imgurl={front1} imgalt="Módulo 1"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'front2', '')}><Lista courses="Módulo II" imgurl={front2} imgalt="Módulo 2"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'front3', '')}><Lista courses="Módulo III" imgurl={front3} imgalt="Módulo 3"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'front4', '')}><Lista courses="Módulo IV" imgurl={front4} imgalt="Módulo 4"/></span>
                <span onClick={(e)=>this.someMethod('', false, 'front5', '')}><Lista courses="Módulo V" imgurl={front5} imgalt="Módulo 5"/></span>
              </div>
            </div>
          </div>
        );
    }
}

export default ModuloList;
