import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* Guia5*/

class Guia1Naidi2  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
       {/* paper 1 */}
       <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 2</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4>Conceptos basicos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p style={{padding:'3px 0px 0px 0px'}}> 

                                          Estimados lectores, en las guías anteriores hemos aprendido
                                          muchos conceptos, y hemos conocido el programa en donde
                                          vamos a darle las órdenes a nuestros robots, por lo cual
                                          debemos seguir conociendo muchas más temáticas
                                          necesarias para hacer eso realidad.
                                          </p>

                                        <p style={{padding:'5px 0px 0px 0px'}}>
                                       En ésta guía, vamos a introducirnos aún más en el mundo de
                                        la robótica, por lo cual debemos conocer los fundamentos de
                                        la misma, e iniciar a interactuar con los componentes
                                        electrónicos desde lo más básico
                                       </p>
                                            
                                                          
                                            </div>

                                            </div>
                                              
                                   


                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                       
                                     
                                        <p  style={{padding:'5px 0px 0px 0px'}}>
                                        No olviden que a medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>

                                          <p style={{padding:'10px 0px 10px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <div>
                                               <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/valle3.png" alt=""/>
                                               
                                               </div>
                                            

                          


                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
              
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Electronica basica - Acumuladores DC.</h4> <h4 style={{ fontWeight:'bold'}}> Objetivos de la guia </h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                     
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de conocer el concepto de baterías,
                                    pilas y regulador de voltaje, de igual manera serán capaces de conectarlas a un circuito electrónico
                                    sencillo y con regulación de voltaje.
                                        

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● Aprender conceptos de baterías y pilas

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Construir circuito electrónico básico

                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Aprender conceptos de regulación de voltaje.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px'}}>
                                     ● Construir circuito electrónico básico con regulación de voltaje.

                                     </p>


                                     
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Baterías y Pilas</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                <h5 style={{ padding:'5px 0px 0px 0px'}}> ¿Qué son las Pilas y las Baterías? </h5>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    La batería, pila o también conocido como acumulador es un dispositivo capaz de generar energía
                                  eléctrica que se utiliza para hacer funcionar dispositivos eléctricos y/o electrónicos, tales como
                                  celulares, tablets, juguetes, leds, motores de corriente directa, etc.
                                   </p>

                                   <p style={{ padding:'5px 0px 0px 0px'}}>
                                   Una de las características principales de estos dispositivos 
                                   es que solo son capaces de generar corriente directa
                                   </p>


                                    <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/pilas1.png" alt="" />

                                     </div>


                                
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Cuál es la diferencia entre batería y pila? </h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                              

                                    <p style={{ padding:'5px 0px 0px 0px'}} >

                                    Estos términos provienen desde la invención de estos dispositivos y no eran tan confusos como hoy
                                    en día, ya que todo dependía de la forma en que se colocaban los componentes ya sea en una pila;
                                    uno material sobre otro, o en forma de batería un material junto al otro. Se podría decir que en las
                                    baterías los dos polos se encuentran en la misma cara, mientras que en las pilas los encontramos en
                                    diferentes extremos.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}} >
                                    Muchas veces se confunden estos dos términos, llamando así pilas a los dispositivos que no son
                                    recargables y baterías a lo que sí lo son, pero en realidad esta forma de clasificarla está dada en celdas
                                    primarias y celdas secundarias.
                                    </p>

                                    <div style={{padding:'20px 0px 0px 0px'}}>

                                      <img style={{width:'90%', display:'block', margin:'auto'}} src="./img/pilas2.png" alt="" />

                                      </div>
                      
                                      
                                  
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <b> Baterías con celdas primarias</b>
                                      
                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      Las pilas o baterías con celdas primarias son aquellas que solo pueden producir electricidad durante
                                      un solo ciclo, ya que la reacción química que sufren los polos no puede ser regenerada y por tanto
                                      una vez que termina su ciclo quedan inservibles, no pueden producir más electricidad.
                                      </p>

                                      <div style={{padding:'20px 0px 0px 0px'}}>

                <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pilas3.png" alt="" />

                              </div>

                              <b>Baterías con celdas secundarias</b> 

                              <p style={{ padding:'5px 0px 0px 0px'}}>
                              Este tipo de celdas son de una generación más moderna y se caracterizan por tener la capacidad de
                              ser recargadas durante muchos ciclos para ser utilizadas nuevamente. Esto se debe a que el proceso
                            químico que sufren los electrodos puede ser revertido a través de una inyección o recarga de
                            electricidad.

                              </p>
                        
                                     
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pilas4.png" alt="" />

                                    </div>
                                 
                                   <b>Partes de una batería</b>

                                 <p style={{ padding:'5px 0px 0px 0px'}}>
                                 A pesar de que las baterías pueden estar compuestas por diferentes materiales dependiendo de cada tipo, la estructura básica es la misma. 
                                 Podemos encontrar elementos que son comunes y cada uno de estos cumple una función especial.
                                 </p>

                                 <p style={{ padding:'5px 0px 0px 0px'}}>
                                 Dentro de la construcción podemos encontrar diferentes celdas, cada una contiene dos polos (ánodo y cátodo) 
                                 de diferentes materiales, un electrolito conductor, una cámara de aire y una carcasa. 
                                 </p>

                                 <div style={{padding:'20px 0px 0px 0px'}}>

                  <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/pilas5.png" alt="" />

                                          </div>

                                         
 
                               
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>
                                  En cuanto a los polos puede existir una pequeña confusión y esto se debe a que en las baterías lo
                                  polos son inversos de lo que normalmente se acostumbra, el ánodo es el polo negativo y el cátodo es
                                  el polo positivo, esto se debe a ánodo es el que genera las cargas negativas o electrones.

                                  </p>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Cómo funciona una batería?</h5></div>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    El funcionamiento de una batería se basa en una reacción electroquímica llamada Redox (reducción– oxidación). 
                                    Esta reacción a simples palabras trata de un intercambio de electrones entre dos polos,
                                    provocando un cambio en los estados de oxidación de los materiales.

                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    Los dos polos están sumergidos en una solución electrolítica y cada uno reacciona de diferente forma:
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● Mientras que el ánodo o polo negativo reacciona en forma de oxidación (se oxida) debido a 
                                    la liberación de electrones.
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● El cátodo o polo positivo sufre una reducción de oxidación (se reduce) debido a la ganancia de electrones.
                                    </p>
                                
                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/pilas6.png" alt="" />

                        </div>

                        <p style={{ padding:'5px 0px 5px 0px'}}>
                        Como bien sabemos esta liberación de electrones del polo negativo viaja hacia el polo positivo a
                        través del circuito o aparato que esté conectado.
                        </p>

                        <p style={{ padding:'5px 0px 5px 0px'}}>

                        Cuando los polos llegan a su límite el cátodo queda cargado de electrones y reducido, mientras que
                        el ánodo los pierde y queda oxidado. Este proceso se puede revertir a través de una fuente de energía externa,
                       ya que el exceso de electrones viajan hacia el lado positivo de la batería y la falta de estos en
                        el polo negativo los cubre la batería.

                        </p>

                        <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                </div>
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  Las baterías tienen un tiempo de carga específico establecido por los fabricantes para que los
                                  materiales no sobrepasen su estado natural.
                                  </p>

                                  <div style={{padding:'5px 0px 0px 0px'}}>

                                <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/pilas7.png" alt="" />

                                </div>
                                
                                <p style={{padding:'10px 0px 0px 0px'}}>
                                En cuanto a las celdas primarias o pilas no recargables 
                                se utilizan otros tipo de materiales como el carbón y el zinc que no se puede revertir el proceso químico que sufren.
                                </p>

                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Tipos de pilas y baterías</h5></div>

                                 <p style={{padding:'5px 0px 0px 0px'}}>
                                 Existen muchas maneras de clasificar estos dispositivos ya sea por su forma, si son secas o húmedas,
                                si son de celdas primarias (desechables) o de celdas secundarias (recargables), etc.
                                 </p>

                                 <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/pilas9.gif" alt="" />

                                             </div>



                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p>
                                  En esta ocasión
                                   las clasificaremos por su tipo de celdas ya que las demás formas no son tan exactas, por ejemplo:
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Las pilas o baterias húmedas se llaman así por que el electrolito que utilizan es líquido y los
                                    dos polos están sumergidos en este
                                  </p>


                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Las secas utilizan un electrolito en forma de pasta lo suficientemente húmedo como para
                                    conducir electricidad, debido a que en estas baterías no existe la posibilidad de una fuga o
                                    derrame de líquido son las más utilizadas en la actualidad.
                                  </p>


                                  <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/pilas10.png" alt="" />

                                             </div>



                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Baterías o pilas desechables (celdas primarias)</h5></div>

                                <p style={{padding:'5px 0px 10px 0px'}}>
                                Estos dispositivos se caracterizan por tener una mayor duración de las otras pero como característica
                                principal solo pueden ser utilizadas durante un ciclo de descarga.
                                </p>

                                <b>  Zinc – Carbono</b>

                                <p style={{padding:'5px 0px 10px 0px'}}>
                                Estas son las más antiguas y más baratas, por consecuencia almacenan una menor cantidad de
                                energía y como resultado duran menos que las alcalinas, se pueden utilizar prácticamente en
                                cualquier aparato electrónico como juguetes, radios, controles, relojes, etc.
                                </p>

                                <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/pilas_zinc.png" alt="" />

                                             </div>
                                 

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <b>Alcalinas </b>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Estas baterías están blindadas para evitar derrames, son de larga duración y su aplicaciones
                                  están dadas en componentes electrónicos como: juguetes, radios, linternas, cámaras, relojes,
                                  etc.
                                  </p>

                                  
                                  <div style={{padding:'10px 0px 0px 0px'}}>

              <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/pilas_alcalinas.png" alt="" />

                                     </div>


                                     <p style={{padding:'5px 0px 0px 0px'}}>
                                     Dentro de este tipo de pilas podemos encontrar diferentes modelos que son clasificados por
                                    su tamaño, podemos encontrar desde las más comunes que son: AA, AAA, C, D y de 9v, aunque
                                    también existen pilas para usos especiales como son: J, N, A23, 21-23, AAAA, etc
                                     </p>

                                     <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pilas11.png" alt="" />

                                        </div>

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                   <b>De boton</b> 

                                    <p style={{padding:'5px 0px 0px 0px'}}>
                                    Dentro de estas podemos encontrar las de óxido de plata y las de óxido de mercurio.
                                   Con respecto a la de óxido de mercurio se deben tratar con mucha precaución ya que son las
                                   más tóxicas por su contenido de 30% de mercurio. Sus aplicaciones se basan en instrumentos
                                    de precisión, calculadoras, relojes, aparatos para la sordera etc.

                                    </p>

                                    <div style={{padding:'10px 0px 0px 0px'}}>

                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/pila_boton.png" alt="" />

                               </div>

                               <div style={{backgroundColor:'#ef7a51ff', height:'2.5em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Baterías o pilas desechables (celdas secundarias)</h5></div>
                                      <div style={{padding:'5px 0px 0px 0px'}}>

                             <b> Plomo o acido</b>

                               </div>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               Podemos encontrar dos tipos diferentes:
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● Las VLA donde los electrodos se encuentran sumergidos en exceso de electrolito líquido
                               </p>

                               <p style={{padding:'5px 0px 0px 0px'}}>
                               ● Las VRLA donde el electrolito se encuentra inmovilizado en un separador absorbente o en un gel
                               </p>







                                     
                                    

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 

                                                           
                                     <p  style={{ padding:'5px 0px 5px 0px'}}> 
                                     Este tipo de batería por lo regular está compuesto por seis celdas conectadas en serie lo que equivale a un aproximado de 12v y 
                                     se utiliza principalmente en la automoción como batería de arranque de motores, ya sea en automóviles, molinetes, hélices de proa, etc.

                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/pilas12.png" alt="" />

                                           </div>

                                          <b>Niquel</b>

                                           <p style={{ padding:'5px 0px 5px 0px'}}>
                                           Fueron creadas principalmente para reemplazar a las baterías de plomo y ácido, se caracterizan
                                           por tener la mitad del peso con respecto a las de plomo, podemos encontrar algunas
                                           variaciones de materiales cada una con diferentes características:
                                           </p>

                                           <p style={{ padding:'5px 0px 5px 0px'}}>
                                           ● Ni-Fe (Níquel -Hierro) Son muy comunes en la vida cotidiana ya que se utilizan en
                                            juguetes, radios, cámaras fotográficas , etc.
                                           </p>

                                           <p style={{ padding:'5px 0px 5px 0px'}}>
                                           ● Ni-Cd (Níquel – Cadmio) Se fabrican de diferentes formas y tamaños, practicante son de
                                              las más utilizadas, ya que se pueden utilizar en celulares, portátiles, equipo electrónico
                                              portátil, juguetes, etc.
                                           </p>

                                   
                                     

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Aplicación</h5></div>


                                  <p style={{ padding:'5px 0px 0px 0px'}}>

                                  ● Ni-MH (Níquel – Hidruro metálico) Prácticamente son las de nueva generación, ya que
                                    tienen la capacidad de generar un 50% más de energía y sus posibles usos están dados
                                    en vehículos de propulsión eléctrica, híbridos y dispositivos electrónicos portátiles.

                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                  <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pilas13.png" alt="" />

                                           </div>

                                          <b>Litio</b>

                                           <p style={{ padding:'5px 0px 0px 0px'}}>
                                           Estas baterías son las de mayor rendimiento, ya que siendo comparadas con las de plomo
                                            pueden generar 6 veces más energía utilizando el mismo tamaño de batería. Son utilizadas en
                                          los dispositivos electrónicos de uso constante como lo son las tablets, los portátiles,
                                            smartphones. Debido a su rendimiento, sus dimensiones y su peso.
                                           </p>



                               

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Robotica</h5></div>

                               
                                <p style={{padding:'5px 0px 0px 0px'}}>
                                Dentro de esta categoría podemos encontrar las de:
                                </p>

                                <p style={{ padding:'5px 0px 0px 0px'}}>
                                ● Iones de litio (Li-ion) Tienen una alta densidad energética, tienen un bajo efecto de
                                  memoria y debido a su gran capacidad energética y reducido peso se utilizan en
                                  dispositivos electrónicos portátiles.
                                </p>

                                <p style={{ padding:'5px 0px 0px 0px'}}>
                                ● Polímero de litio (Li-Po) Son utilizadas en aplicaciones que requieren más de 1A, un peso 
                                y tamaño reducidos

                                </p>


                                <div style={{padding:'10px 0px 0px 0px'}}>

        <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/pilas14.png" alt="" />

                         </div>

                        <b>Grafeno</b> 

                         <p>
                         Este tipo de baterías son la promesa de un futuro cercano ya que prometen una mayor
                        duración, ser más baratas y tener mucho mayor durabilidad. Cabe mencionar que este tipo de
                        baterías aun se encuentra en desarrollo. <span style={{color:'red'}}>Amplia informacion aqui</span>
                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ingmecafenix.com/electronica/que-es-una-bateria/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 

                         </p>




      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                                 
                                <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 0px 0px'}} >

                                          <div style={{padding:'0px 10px 0px 0px'}}>

                                <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                                  </div>

                                <div className="container" style={{width:'95%'}}>


                          <p> Para que entiendan con más facilidad los conceptos anteriores, 
                reproduzcan el siguiente video<span style={{color:'red'}}>: ¿Cómo funcionan las pilas_ - Taller de Robótica Básico Faraday Perú </span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=bmeAeet0Xi4">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                                </p>

                          </div>

                            </div>
                            <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad 1 - Práctica</h5></div>


                            <p style={{padding:'5px 0px 0px 0px'}}>
                                ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                                  </p>

                                  <p style={{padding:'5px 0px 10px 0px'}}>
                                  El objetivo de esta práctica es conectar una batería para hacer
                                encender un diodo LED, haciendo las conexiones en una placa de
                                prototipos “Protoboard”, usando un led y una resistencia de 220
                                ohmios.
                                  </p>

                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  El formador de sus territorios les suministrará los elementos
                                   necesarios para hacer las conexiones, que se muestran en el
                                  siguiente esquema:
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/pilas15.png" alt="" />

                                             </div>

                                             <div className="container" style={{width:'95%'}}>


<p>Todo el proceso de conexión, lo explico detalladamente en el
siguiente video<span style={{color:'red'}}>: Conexión para encendido de LED con batería 9v </span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=z_O-qfQmidI">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
      </p>

</div>


                                  
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 20 */}
                 <div id="movPage20" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Regulador de Voltaje</h5></div>

                                 <h5 style={{padding:'5px 0px 0px 0px'}}>¿Qué es un regulador de voltaje?</h5>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                   Un regulador de voltaje es un circuito que, sin importar lo que conectes a su salida, mantendrá un
                                   voltaje constante en sus terminales. A grandes rasgos esa es la utilidad de este circuito, pero los hay
                                    de distintos diseños con propósitos y aplicaciones diferentes, en este artículo revisaremos los
                                    distintos tipos de reguladores que existen y sus características.
                                  </p>

                                  <b>Regulador Lineal </b>

                                  <p  style={{padding:'5px 0px 0px 0px'}}>
                                  Son especialmente útiles dada su circuitería simple y facilidad de uso. Los reguladores lineales son
                                  elementos de 3 terminales, generalmente. Están basados en circuitos analógicos con realimentación
                                  que ajusta el voltaje de salida dependiendo de la señal de realimentación.
                                  </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                    <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/regulador1.png" alt="" />

                         </div>


                          </div>

                         </div>
                     <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage20', 19, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                {/* paper 21 */}
                <div id="movPage21" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="16" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage20', 10, '', '', '', "15")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  

                                  <p style={{padding:'3px 0px 0px 0px'}}>
                                  Se les puede encontrar en varias categorías, como reguladores positivos o negativos. En el caso de
                                los reguladores negativos no hay muchas variantes. En el caso positivo los hay fijos y variables. El
                              primer tipo ofrece un voltaje constante especificado en su matrícula; la serie 78xx y 79xx son ejemplos
                               de este tipo de reguladores. Los variables son dispositivos que permiten ajustar el voltaje de salida
                                mediante elementos externos, como resistores; los conocidos integrados 317 Y 337 son ejemplos de
                              reguladores variables positivos y negativos, respectivamente.
                                  </p>

                                  <div style={{padding:'5px 0px 0px 0px'}}>

                            <img style={{width:'70%', display:'block', margin:'auto'}} src="./img/regulador2.png" alt="" />

                                     </div>

                                  <p style={{padding:'3px 0px 0px 0px'}}>
                                  Aparte de su clasificación en reguladores fijos o variables, los reguladores lineales se pueden clasificar
                                  de tipo estándar o de baja caída de tensión (Low-dropout, LDO).
                                  </p>

                                

                                  

                                   


                                

                          </div>

                         </div>
                     <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage21', 20, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 22 */}
                 <div id="movPage22" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage21', 9, '', '', '', "16")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                  
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  Los reguladores LDO ofrecen una
                                  conversión mejorada, pues la caída de voltaje entre entrada y salida es menor a 1V, a diferencia de los
                                  estándar que suele estar alrededor de los 3V. Un último aspecto a tomar en cuenta es el transistor de
                                  salida, que en general suele ser un transistor PNP, lo que determina la corriente de salida y la caída
                                  de tensión entre entrada y salida.
                                  </p>

                                  <div style={{padding:'5px 0px 0px 0px'}}>

            <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/regulador3.png" alt="" />

                          </div>


                          <p style={{padding:'5px 0px 0px 0px'}}>
                          La ventaja de estos reguladores, reitero, es la facilidad de seleccionar los componentes para un
                         propósito específico. Además, dado que no se basan en un principio de conmutación, se les suele
                          utilizar en aplicaciones de bajo ruido, como en comunicaciones, instrumental médico y metrología.
                      Las desventajas, y una de las principales, es su baja eficiencia y la necesidad de un elemento
                    adecuado para la disipación de calor. Además, esta tecnología sólo permite reducir el voltaje de
                    entrada, no aumentarlo, como su contraparte basada en conmutación.
                          </p>



                                
                                

                          </div>

                         </div>
                     <div data-active="17" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage22', 21, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 23 */}
                     <div id="movPage23" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="18" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage22', 8, '', '', '', "17")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                               
                               <b>Reguladores por conmutación</b> 

                               <p  style={{padding:'5px 0px 0px 0px'}}>
                               Los reguladores por conmutación son más eficientes que los lineales dado que los transistores
                              funcionan en saturación o corte (encendido-apagado) en vez de en su región lineal (como un resistor variable), 
                              minimizando la pérdida de potencia. Además, el tamaño de las fuentes de alimentación
                              se reduce, dado que se requiere disipar menos calor. Suponiendo una fuente con un voltaje de
                            entrada de 12V con salida de 3.3V, un regulador de conmutación puede alcanzar una eficiencia de
                            más del 90%, respecto al 27.5% de un regulador lineal. Esto se puede interpretar como una reducción
                            del tamaño de cuando menos 8 veces respecto al lineal.
                               </p>

                               <div style={{padding:'5px 0px 0px 0px'}}>

                <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/regulador_c1.png" alt="" />

                                      </div>


                                      <p  style={{padding:'5px 0px 0px 0px'}}>
                                      Sin embargo, la eficiencia de estos dispositivos requiere de un cuidadoso diseño que no se presenta
                                      en los reguladores lineales, y aumenta la cantidad de componentes a elegir para obtener un diseño 
                                      estable y útil, por no decir que existen una diversidad de configuraciones que pueden dar solución a
                                      un problema de diseño.
                                      </p>
                                  

                          </div>

                         </div>
                     <div data-active="18" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage23', 22, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                
                     {/* paper 24 */}
                     <div id="movPage24" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="19" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage23', 7, '', '', '', "18")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                
                                <p>
                                Afortunadamente los proveedores de circuitos integrados diseñan sus
                                propios modelos que reducen el número de variables a tomar en cuenta para implementar estos
                              dispositivos, disminuyendo el tamaño de los productos y el esfuerzo para realizarlos. <span style={{color:'red'}}>Amplia informacion aqui</span>
                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://blog.330ohms.com/2019/07/29/que-es-un-regulador-de-voltaje/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                </p>

                                  <div style={{padding:'10px 0px 0px 0px'}}>

                <img style={{width:'80%', display:'block', margin:'auto'}} src="./img/regulador_c2.png" alt="" />

                                </div>


                                <div style={{padding:'20px 0px 0px 0px'}}>

                <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/next.gif" alt="" />

                                  </div>

                          </div>

                         </div>
                     <div data-active="19" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage24', 23, '', '', '', "19")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage24', 23, '', '', '', "19")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                     {/* paper 25 */}
                     <div id="movPage25" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="20" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage24', 6, '', '', '', "19")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage24', 6, '', '', '', "19")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad 2</h5></div>

                                
                                <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 0px 0px'}} >

                                        <div style={{padding:'0px 10px 0px 0px'}}>

                            <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                              </div>

                              <div className="container" style={{width:'95%'}}>


                        <p> 
                        ¡Es hora de pasar del concepto a la práctica!
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        El objetivo de esta práctica es conectar una batería para encender
                        un diodo LED con un regulador de voltaje de 5 voltios, haciendo
                        las conexiones en una placa de prototipos “Protoboard”, usando
                       un led y una resistencia de 220 ohmios. dos capacitores y un
                      regulador de voltaje 5v.
                        </p>

                        <p style={{padding:'5px 0px 0px 0px'}}>
                        El formador de sus territorios les suministrará los elementos
                        necesarios para hacer las conexiones, que se muestran en el
                       siguiente esquema: </p>

                          </div>

                        </div>


                                <div style={{padding:'5px 0px 0px 0px'}}>

                    <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/regulador_c3.png" alt="" />

                                      </div>

                                      <p>
                                      Todo el proceso de conexión, lo explico detalladamente en el 
                                      siguiente video:  <span style={{color:'red'}}> Conexión para encendido de LED con batería 9v con regulador 5v</span>
<span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=8UsFTZqq9Kk">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>  
                       
                                      </p>

                              
                          </div>

                         </div>
                     <div data-active="20" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage25', 24, '', '', '', "20")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage25', 24, '', '', '', "20")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 26 */}
                  <div id="movPage26" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="21" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage25', 5, '', '', '', "20")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage25', 5, '', '', '', "20")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>
                          
                                  <p style={{padding:'5px 0px 0px 0px'}}>
                                  ● Video y foto de cada grupo con circuito terminado “Conexión de batería para encender LED”. 
                                  </p>

                                  <p style={{padding:'5px 0px 5px 0px'}}>
                                  ● Video y foto de cada grupo con circuito terminado “Conexión de batería para encender LED con regulador 5v”. 
                                  </p>


                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                  ● Mecafenix, I. (2021, 17 mayo). ¿Qué es una batería y como funciona? Ingeniería Mecafenix.
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ingmecafenix.com/electronica/que-es-una-bateria/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 
                                  </p>

                                  <p style={{padding:'10px 0px 0px 0px'}}>
                                  ● Camarillo, A. (2021, 11 noviembre). ¿Qué es un regulador de voltaje? 330ohms. 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://blog.330ohms.com/2019/07/29/que-es-un-regulador-de-voltaje/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span> 

                                  </p>
                          </div>

                         </div>
                     <div data-active="21" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage26', 25, '', '', '', "21")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage26', 25, '', '', '', "21")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>




                {/* paper 27 */}
                <div id="movPage27" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="22" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage26', 4, '', '', '', "21")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage26', 4, '', '', '', "21")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="22" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}

export default Guia1Naidi2;
