import React, { Component } from "react";
import Lista from "./Lista";
import frontEnd from "../svg/portadas-front-end/fron-end-pacifico.svg";
import naidi from "../svg/portadas-naidi/valle-del-naidi.svg";
import startup from "../svg/portadas-startup/startup-girl.svg";

class ControlList  extends Component{

  someMethod = (lVw, mVw, gVw, cVw) => {
    this.props.handler(lVw, mVw, gVw, cVw)
  }

  render(){
    return(
      <div className="App-book">
        <h4>Proyectos</h4>
        <div className="Cursos">
          <div className="Lista-cursos">
            <span onClick={(e)=>this.someMethod(false, 'front', '', '')}><Lista courses="Front-End Pacífico" imgurl={frontEnd} imgalt="Front-End Pacífico"/></span>
            <span onClick={(e)=>this.someMethod(false, 'naidi', '', '')}><Lista courses="Valle del Naidi" imgurl={naidi} imgalt="Valle del Naidi"/></span>
            <span><Lista courses="Startup Girl" imgurl={startup} imgalt="Startup Girl"/></span>
            {/* <span onClick={(e)=>this.someMethod(false, 'startup', '', '')}><Lista courses="Startup Girl" imgurl={startup} imgalt="Startup Girl"/></span> */}
          </div>
        </div>
        <h4>Libros</h4>
        <div className="Cursos">
          <div className="Lista-cursos">
            {/* <span><Lista courses="Libros" imgurl="./img/cover_page/book2.png" imgalt="Libros Manos Visibles"/></span> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ControlList;
