import React, { Component } from "react";
/* css */
import "../../styles/flipbookMv.css";
import "../../styles/styleVw.css";
import "../../styles/naidiBackground.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight,faLink} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
/* import { faFilePdf } from "@fortawesome/free-regular-svg-icons"; */
/* guia1 */
class Guia1Naidi1  extends Component{

  componentDidMount(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox;
    instances.init(elems);

    var elmModal = document.querySelectorAll('.modal');
    var instModal = M.Modal;
    instModal.init(elmModal);
  }

  flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo = document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.add('movFlipEfectNext');

    if (!elemenVideo){
      /* null */
    }else{
      let vpause= document.querySelectorAll('video')[indexVideo];
      vpause.pause();
    }

    if (view === 1){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
    }else{
      setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        setTimeout(()=> (e.classList.add('eVisibility')), 800);
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();

  }

  flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
    let page = document.getElementById(elemt);
    let eVideo= document.getElementById(elemenVideo);
    let eImg = document.querySelectorAll('div[data-active]');

    page.classList.remove('movFlipEfectNext');
    page.style.transform ='translateX(0%)';

    if (view === 0){
      setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
    }else{
      setTimeout(() => (page.style.zIndex = index), 500);
    }

    eImg.forEach(function(e){
      if (e.dataset.active === elemenImg){
        e.classList.remove('eVisibility');
      }
    });

    let aUrl ='./media/paperflip.mp3';
    let audioUrl = new Audio(aUrl);
    audioUrl.play();
  }

  pausarVideo(idvideo){
    let vpause= document.getElementById(idvideo);
    vpause.remove();
  }

  obIframe(vUrl, mVideo){
    let obModal = document.getElementById(mVideo);
    let obIfram = document.createElement('iframe');
    obIfram.id ='vdo';
    obIfram.width ='450';
    obIfram.height ='250';
    obIfram.title ='Roles en Front-End';
    obIfram.src = vUrl;
    obIfram.frameBorder = '0';
    obIfram.allowFullScreen = 'true';
    obModal.appendChild(obIfram);
  }

  render(){
    return(
      <div className="flip-container">
                {/* paper 1 */}
                <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content nbackground-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="nfront__portada1">
                                    {/* --*/}
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>


                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle1.png" alt=""/>
                                    <h4>Módulo 1</h4>
                                    <img style={{maxWidth:"30%", height:'auto'}} src="./img/valle2.png" alt=""/>

                                  </div>

                                    <div className="center" style={{paddingBottom:'5px', paddingTop:'5px'}}><h4 style={{color:'red'}}>Conceptos basicos</h4></div>

                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    </div>
                                    <div>
                                    

                                    </div>

                                    <div style={{display:'flex'}} >

                                    <div>
                                      <p style={{padding:'10px 20px 0px 0px',fontWeight:'bold'}}>Hola! soy nai y pertenezco al valle del naidi</p>

                                    <img style={{width:'95%'}} src="./img/valle3.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'190%'}}>
                                      
                                   
                                          
                                          <p>
                                            Estimados lectores, están a punto de iniciar una nueva aventura en el Valle del Naidí, de donde vengo, y los acompañaré  por toda  esta travesía  donde  se  formarán  
                                            en temas de ciencia, tecnología e innovación, haciendo uso de larobótica como eje pilar de enseñanza. </p>
                                            
                                             
                                            <p style={{padding:'5px 0px 0px 0px'}}>
                                              ¡Hola soy NAI, y pertenezco al Valle del Naidí! Estas guías están 
                                            diseñadas con la misión de formar estudiantes   de 10 a 13 años de edad, las cuales aportarán conocimientos para su desarrollo integral, y los preparará para afrontar 
                                            esta era digital en la que vivimos con la industria 4.0.</p>
                                            
                                            </div>

                                            </div>
                                              
                                   


                                    
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Alistamiento</h5></div>
                                    <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                        <p>
                                        A medida que vayan avanzando encontrarán enlaces <span style={{color:'red'}}>“Amplía información  aquí”</span> que presionando click en la palabra <span style={{color:'red'}}>“aquí”</span> 
                                        los llevará al origen del escrito y les ampliaran o complementaran la información aquí plasmada.
                                        </p>
                                          <p style={{padding:'3px 0px 0px 0px'}}> También encontrarán enlaces a videos de Youtube, en donde podrán aclarar dudas acerca de lo 
                                            estudiado y les ayudará a entender con más facilidad lo que se está plasmando.</p>

                                            <p  style={{padding:'3px 0px 0px 0px'}}>El desarrollo de toda esta aventura será un trabajo en equipo, fortaleciendo así las habilidades del siglo XXI,
                                               por lo tanto, se deben crear grupos de trabajo, conformados por 3 estudiantes cada uno, y deben bautizarlos con un 
                                               nombre, por lo cual en una hoja de papel se tiene que  escribir el nombre del equipo y los nombres de cada uno de los 
                                               integrantes. Tengan en cuenta que los equipos que se conformen desde aquí, deben perdurar hasta el final, por lo cual, 
                                               recomiendo elegir muy bien. </p>


                                   
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">

                            
                              <div style={{height:'97%'}}>
                              

                              <div className="center" style={{paddingBottom:'5px', paddingTop:'5px',color:'red', textDecorationLine:'underline'}}><h4 style={{ fontWeight:'bold'}}>Objetivos de la guia</h4> <h4 style={{ fontWeight:'bold'}}>Introduccion a la electronica</h4></div>
                                        
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>General</h5></div>

                                    <p style={{ padding:'10px 0px 10px 0px'}}>
                                    Al culminar esta guía, los estudiantes estarán en la capacidad de entender algunos conceptos básicos y que cumplen componentes como resistores, capacitores y bobinas.

                                    </p>
                                    <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Especificos</h5></div>
                                    
                                   
                                    <p style={{ padding:'3px 0px 0px 0px'}}>
                                     ● seleccionar conceptos básicos de la electrónica, tales como la electricidad, el electrón, la resistencia.
                                     </p>

                                     <p style={{ padding:'5px 0px 0px 0px',textAlign:'left'}}>
                                     ● Conocer componentes básicos de un circuito electrónico, sus funciones y aplicaciones.Y este es otro diferente.
                                     </p>


                                     


                                    
                                
                                </div>
                              </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                 
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                <div style={{backgroundColor:'#ef7a51ff', height:'2.7em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Conceptos basicos: ¿Que es la electricidad?</h5></div>
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p style={{ padding:'0px 0px 5px 0px'}}>
                                    No es posible no utilizarla en nuestro mundo globalizado, pero ¿quién sabe definirla realmente?
                                   </p>

                                   <p>  De forma simple, podemos decir que la electricidad es esa energía capaz de hacer brillar bombillas,
                                        mover los coches más eficientes y dar vida al monstruo de Frankenstein. Si profundizamos en el
                                        concepto más técnico, es la energía generada por el movimiento de electrones positivos y negativos
                                        en el interior de materiales conductores.
                                    </p>

                                    <p style={{ padding:'7px 0px 0px 0px'}}> 
                                      ¿Y qué es esto? Materia, pequeños átomos minúsculos, cuyas partes, si son opuestas, se atraen. Sus
                                        cargas positivas y negativas se complementan creando dos tipos de electricidad: la electricidad
                                        estática (generada por fricción) y la electricidad dinámica (concebida por corriente).
                                    </p>

                                    <p style={{ padding:'7px 0px 0px 0px'}}>
                                    Es maravillosa pero no es fácil que llegue hasta tu casa. Pulsar el interruptor conlleva un proceso que
                                    no es para nada sencillo. No hay persona en el mundo que pueda discutir sobre si la capacidad de generar electricidad
                                    es el mayor invento de la historia de la humanidad. La sociedad moderna no es
                                    posible concebirla sin energía eléctrica, y nuestras vidas dependen de la energía por muchos motivos.
                                    </p>
                               
                                </div>
                              </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>

                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                              <div style={{height:'97%'}}>
                                
                                <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>

                                    <p>
                                    La electricidad o energía eléctrica se puede obtener de diferentes fuentes o recursos naturales de las
                                    cuales solo mencionaremos los más importantes que son: 
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● La hidráulica que se obtiene de los ríos. 
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px', textAlign:'left'}}>
                                    ● Combustibles fósiles que se dan bajo la superficie de la tierra como el carbón, el gas y el petróleo. 
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}}>
                                    ● La eólica que se obtiene del viento 
                                    </p>

                                    <p style={{ padding:'5px 0px 0px 0px'}} >
                                    ● La solar que se obtiene de los rayos del sol. <span  style={{color:'red'}}>Amplía información aquí </span> 
                                    <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.endesa.com/es/conoce-la-energia/energia-y-mas/como-se-genera-electricidad">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>
                                    </p>
                                    


                                    <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 10px 0px 0px'}}>
                                      
                                    <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'95%'}}>
                                                                        
                                    <p> Para que entiendan con más facilidad los conceptos anteriores, reproduzcan el
                                      siguiente video : <span style={{color:'red'}}>  ¿Qué es la electricidad? - Ciencia para niños - Episodio 1 </span>
                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=ovrgMD6tEm8">  <FontAwesomeIcon icon={faYoutube} style={{ cursor:'pointer', fontSize:'1.2rem', color:'red'}}/> </a></span>
                                      </p>
                                            
                                    </div>

                                    </div>

                                  

                                 
                                </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> ¿Que es el electron?</h5></div>
                                  <div className="container" style={{overflowY:'auto', height:'auto', width:'100%', paddingTop:'10px'}}>
                                      <p>
                                      Se conoce como electrón a la partícula esencial más liviana que compone un átomo y que presenta la menor carga posible en lo referente a la electricidad negativa.
                                       Se trata de un elemento subatómico que se sitúa en torno al núcleo del átomo, formado por neutrones y protones. <span style={{color:'red'}}> Amplía información aquí</span> 

                                       <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.endesa.com/es/conoce-la-energia/energia-y-mas/como-se-genera-electricidad">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>
                                      </p>

                                      <div style={{padding:'10px 0px 5px 0px'}}>
                                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/electron.png" alt="" />
                                      </div>

                                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}> ¿Que es el voltaje?</h5></div>
                                     
                                      <div style={{padding:'10px 0px 0px 0px'}}>
                                      <p>
                                      Denominado también como tensión o diferencia de potencial es una magnitud física que impulsa a
                                      los electrones a lo largo de un conductor en un circuito eléctrico cerrado, provocando el flujo de una
                                      corriente eléctrica. <span style={{color:'red'}}>Amplía información aquí</span> 

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=sZ5fGiYEBYk">  <FontAwesomeIcon icon={faYoutube} style={{color:'red', cursor:'pointer', fontSize:'1.1rem'}}/> </a></span>
                                      </p>

                                      

                                      </div>

                                      <div style={{padding:'10px 0px 0px 0px'}}>
                                      <img style={{width:'30%', display:'block', margin:'auto'}} src="./img/corriente.png" alt="" />
                                      </div>


                                 
                                  </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Que es la corriente electrica?</h5></div>

                                      <p>La corriente es la velocidad a la que un flujo de electrones pasa por un punto de un circuito eléctrico
                                          completo. Del modo más básico, corriente = flujo. <span style={{color:'red'}}>Amplía información aquí</span>

                                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.fluke.com/es-co/informacion/blog/electrica/que-es-la-corriente">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>

                                          </p>

                                          <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center', marginTop:'20px'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Que es la resistencia?</h5></div>

                                          <p style={{padding:'5px 0px 0px 0px'}} >La resistencia es una medida de la oposición al flujo de corriente en un circuito eléctrico. <span style={{color:'red'}}>Amplía información aquí</span>

                                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.fluke.com/es-co/informacion/blog/electrica/que-es-la-resistencia#:~:text=La%20resistencia%20es%20una%20medida,entre%20voltaje%2C%20corriente%20y%20resistencia.">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>
                                           </p>

                                      <div>

                                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/resistencia.png" alt="" />

                                      </div>


                                      <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 10px 0px 0px'}}>
                                      
                                    <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'95%'}}>
                                                                        
                                    <p>Para que entiendan con más facilidad los conceptos anteriores reproduzcan el
                                      siguiente video : ⚡<span style={{color:'red'}}>Que es el VOLTAJE, la CORRIENTE ELECTRICA y la RESISTENCIA explicado paso a paso</span>

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=sZ5fGiYEBYk">  <FontAwesomeIcon icon={faYoutube} style={{color:'red', cursor:'pointer', fontSize:'1.1rem'}}/> </a></span>
                                          </p>
                                            
                                    </div>

                                    </div>

                                      




 
                                  
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                
                 {/* paper 9 */}
                 <div id="movPage9" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Que es un circuito electrico?</h5></div>

                                      <p style={{padding:'10px 0px 0px 0px'}}> Un circuito eléctrico es la unión de dos o más elementos que permiten circular la corriente eléctrica, facilitando
                                          el flujo de electricidad al mismo tiempo que nos da la posibilidad de controlarla. El paso de la corriente
                                          depende de las partes que integran el circuito eléctrico, entre las que se encuentran: interruptores,
                                          resistencias, condensadores, semiconductores, cables, entre otros. <span style={{color:'red'}}>Amplía información aquí.</span> 
                                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprende.com/blog/oficios/instalaciones-electricas/como-funciona-un-circuito-electrico/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.2rem', color:'FF5733'}}/> </a></span>
                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                                      <img style={{width:'40%', display:'block', margin:'auto'}} src="./img/c_electrico.png" alt="" />

                                      </div>

                                         

                                    

                                      <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                    <div style={{padding:'0px 10px 0px 0px'}}>
                                      
                                    <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                    </div>
                                    
                                    <div className="container" style={{width:'95%'}}>
                                                                        
                                    <p>Para que entiendan con más facilidad el concepto anterior reproduzcan el
                                      siguiente video :<span style={{color:'red'}}> EL CIRCUITO ELÉCTRICO ¿Qué es ¿Cómo funciona y cuales son
                                      sus partes</span> 

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer"  target='_blank' href="https://www.youtube.com/watch?v=nBSJJEzdKUo">  <FontAwesomeIcon icon={faYoutube} style={{color:'red', cursor:'pointer', fontSize:'1.1rem'}}/> </a></span>


                                          </p>
                                            
                                    </div>

                                    </div>

                                      
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                   {/* paper 10 */}
                   <div id="movPage10" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Componentes electronicos</h5></div>

                                  <h4 style={{ fontWeight:'bold',textDecoration:'underline',color:'red'}}>La resistencia</h4>

                                 
                                      <p> La resistencia R es la inversa de la conductancia eléctrica G, definida para un conductor cilíndrico como:</p>

                                      <p style={{ padding:'5px 0px 5px 0px'}}>
                                    ● R= 1 / G. para corriente alterna
                                    </p>

                                    <p style={{ padding:'5px 0px 5px 0px'}}>
                                    ● La eólica que se obtiene del viento 
                                    </p>

                                         <p> La resistencia para circuitos de corriente alterna y para campos electromagnéticos alternos se 
                                           describe en términos de impedancia e impedancia característica.</p>

                                           <p>El dispositivo que mide la resistencia eléctrica es el ohmímetro.</p>

                                         <div style={{padding:'10px 0px 0px 0px'}}>

                                    <img style={{width:'50%', display:'block', margin:'auto'}} src="./img/ohmimetro.png" alt="" />

                                        </div>
 
                              
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 11 */}
                 <div id="movPage11" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>¿Como funciona el ohmimetro?</h5></div>
                               
                       
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        La resistencia al paso de la corriente se debe a que las cargas eléctricas (iones o electrones ) que fluyen
                                          a través de un conductor eléctrico chocan contra átomos del propio conductor. Al chocar, parte de su
                                          energía cinética se convierte en calor. Es decir, uno de los efectos del paso de corriente en un
                                          conductor es su calentamiento. Este efecto se llama efecto Joule.
                                      </p>

                                      <p  style={{ padding:'5px 0px 5px 0px'}}> 
                                        La resistencia de un conductor depende del material con el que esté fabricado, su tamaño y su
                                          temperatura. Al aumentar la temperatura, aumenta la resistencia. Igualmente, al bajar la
                                          temperatura, la resistencia disminuye. <span style={{color:'red'}}> Amplía información aquí </span> 

                                          <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://solar-energia.net/electricidad/circuito-electrico/resistencia-electrica">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                      </p>

                                      <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Los condensadores</h5></div>

                                      <p style={{ padding:'5px 0px 0px 0px'}}>
                                      Un condensador también conocido como capacitor, es uno de los componentes electrónicos
                                      pasivos como las resistencias. El condensador se utiliza generalmente para almacenar carga
                                      eléctrica. La carga del condensador se almacena en forma de «campo eléctrico». Los condensadores
                                      desempeñan un papel importante en muchos circuitos eléctricos y electrónicos
                                      </p>

                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 12 */}
                  <div id="movPage12" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Generalmente, un condensador tiene dos placas de metal paralelas que no están conectadas entre
                                      sí. Las dos placas del condensador están separadas por un aislamiento no conductor, este medio se
                                      conoce comúnmente como dieléctrico.betsafe app
                                      </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}> 
                                      Hay diferentes tipos y formas de condensadores disponibles, desde los pequeños condensadores
                                      que se utilizan en circuitos de resonancia, a grandes condensadores para estabilizar líneas HVDC.
                                      Pero todos los condensadores están haciendo el mismo trabajo que es almacenar carga eléctrica.
                                      </p>

                                      <p style={{ padding:'10px 0px 0px 0px'}}>
                                      La forma de un condensador puede ser rectangular, cuadrada, redonda, cilíndrica o esférica. A
                                      diferencia de una resistencia, un condensador ideal no disipa energía. Para los diferentes tipos de
                                      condensadores están disponibles diferentes símbolos. <span style={{color:'red'}}>Amplía información aquí</span> 

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.web-robotica.com/taller-de-web-robotica/electronica/componentes-electronicos/que-es-un-condensador-electrico">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>


                                      </p>

                                      <div style={{padding:'10px 0px 0px 0px'}}>

                        <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/condensador.png" alt="" />

                                      </div>

                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                 {/* paper 13 */}
                 <div id="movPage13" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Los transitores</h5></div>
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Un transistor es un dispositivo semiconductor que transfiere una señal débil del circuito de baja
                                      resistencia al circuito de alta resistencia. En otras palabras, es un dispositivo utilizado como
                                      amplificador o interruptor que regula las señales eléctricas como el voltaje o la corriente.
                                      </p>

                                      <p style={{ padding:'5px 0px 0px 0px'}}> 

                                      Los transistores son uno de los componentes básicos de la electrónica moderna. Se componen de
                                      material semiconductor y suelen tener al menos tres terminales para conectarse a un circuito externo. <span style={{color:'red'}}>Amplía información aquí</span>

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://electronicaonline.net/componentes-electronicos/transistor/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                     
                                      </p>

                                    

                                      <div style={{padding:'40px 0px 0px 0px'}}>

                        <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/transitores.png" alt="" />

                                      </div>

                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                  {/* paper 14 */}
                  <div id="movPage14" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>El diodo led</h5></div>
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Los diodos son componentes electrónicos que permiten el paso de la corriente en un solo sentido, en
                                      sentido contrario no deja pasar la corriente (como si fuera un interruptor abierto). Un diodo Led es un
                                      diodo que además de permitir el paso de la corriente solo  un sentido, en el sentido en el que la corriente
                                       pasa por el diodo, este emite luz. Cuando se conecta un diodo en el sentido que permite el
                                      paso de la corriente se dice que está polarizado directamente. <span style={{color:'red'}}>Amplía información aquí  </span> 

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ledtecnologia.com/que-es-un-led/">  <FontAwesomeIcon icon={faLink  } style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>

                                      </p>

                          
                                    

                                      <div style={{padding:'40px 0px 0px 0px'}}>

                        <img style={{width:'60%', display:'block', margin:'auto'}} src="./img/diodo_led.png" alt="" />

                                      </div>

                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>


                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>La protoboard</h5></div>
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Una Protoboard o placa de prototipos, es una tabla rectangular de plástico con un montón de
                                      pequeños agujeros en ella. Estos agujeros permiten insertar fácilmente componentes electrónicos
                                      para hacer un prototipo (es decir, construir y probar una versión temprana de) un circuito electrónico,
                                      como por ejemplo con una batería, un interruptor, una resistencia y un LED (diodo emisor de luz).


                                      </p>

                                     
                                    

                                      <div style={{padding:'40px 0px 0px 0px'}}>

                        <img style={{width:'100%', display:'block', margin:'auto'}} src="./img/protoboard.png" alt="" />

                                      </div>

                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 16 */}
                 <div id="movPage16" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                 
                               
                      
                                      <p  style={{ padding:'5px 0px 0px 0px'}}> 
                                        
                                      Es una herramienta simple que se utiliza para conectar fácilmente los componentes eléctricos y los
                                      cables entre sí. Sólo ciertos tipos de componentes y cables son aplicables para el uso de la protoboard.
                                      Siempre que los componentes tengan pasadores con agujeros pasantes (a diferencia del montaje ensuperficie)
                                      , probablemente sean aplicables para las protoboard. <span style={{color:'red'}}> Amplía información aquí </span>

                                      <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://descubrearduino.com/protoboard/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span>
                                      </p>

                                      <div style={{display:'flex', justifyContent:'space-between',padding:'30px 0px 0px 0px'}} >

                                       <div style={{padding:'0px 10px 0px 0px'}}>
  
                                      <img style={{width:'110%'}} src="./img/valle4.png" alt=""/>

                                      </div>

                                    <div className="container" style={{width:'95%'}}>
                                    
                                    <p> Para que entiendan con más facilidad el concepto anterior reproduzcan el
                                        siguiente video : <span style={{color:'red'}}>Cómo usar una Protoboard</span>
                                        
                                        <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.youtube.com/watch?v=ulsv7uxDZoc&list=PLHsWxcSr3WR4BTj_Wp6QDKJY3Qa7CV1Vn&index=2">  <FontAwesomeIcon icon={faYoutube} style={{color:'red', cursor:'pointer', fontSize:'1.1rem'}}/> </a></span>
                                        </p>
        
                                      </div>

                                      </div>

                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>



                 {/* paper 17 */}
                 <div id="movPage17" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Actividad</h5></div>

                                  <div style={{display:'flex', justifyContent:'space-between',padding:'10px 0px 10px 0px'}} >

                                  <div style={{padding:'0px 10px 0px 0px'}}>
  
                        <img style={{width:'110%',padding:'50px 0px 0px 0px'}} src="./img/valle4.png" alt=""/>

                                </div>

                      <div className="container" style={{width:'95%'}}>
                                    
                  <p>
                  ¡Es hora de probar los conocimientos adquiridos hasta el momento!
                 </p>

                 <p style={{ padding:'5px 0px 0px 0px'}}>
                 Para verificar que los conocimientos se hayan comprendido
                  correctamente, el formador hará una divertida competencia en
                vivo, la cual consiste en proyectar unas preguntas por medio de la
                aplicación <span style={{color:'red'}}>Kahoot</span><span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 2px'}}><a rel="noreferrer" target='_blank' href="https://kahoot.com/"><FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.0rem', color:'FF5733'}}/></a></span>, y los grupos conectados a la misma aplicación
                por medio de las tablets, computadores o celulares deberán
                rápidamente seleccionar la(s) respuesta(s) correcta(s).

                
                 </p>
        
                </div>

                </div>

                <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Entregables</h5></div>

                <p style={{ padding:'5px 0px 5px 0px'}}>
                 ● 1 Documento en excel generado por la aplicación Kahoot, en donde se muestra el detalle de las respuestas que realizaron los equipos participantes
                 </p>

                 <p style={{ padding:'5px 0px 5px 0px'}}>
                 ● Hojas de equipos de trabajo conformados. 
                 </p>

                 

                </div>
                  </div>
                     <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                

                  {/* paper 18 */}
                 <div id="movPage18" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                  <div style={{backgroundColor:'#ef7a51ff', height:'2em', display:'flex', alignItems:'center'}}><h5 className="white-text" style={{paddingLeft:'5px'}}>Bibliografia</h5></div>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● Contenidos Digitales Endesa. (2022, 23 febrero). Cómo se genera la energía eléctrica. Endesa.
                              Recuperado 5 de marzo de 2022, de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href=" https://www.endesa.com/es/conoce-la-energia/energia-ymas/como-se-genera-electricidad">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>
                              
                              <p style={{ padding:'5px 0px 5px 0px'}}>● Definición de electrón — Definicion.de. (s. f.). Definición de Electron. Recuperado 5 de marzo de 2022,
                                  de <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://definicion.de/electron/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● EcuRed. (s. f.). Voltaje - EcuRed. www.ecured.cu. Recuperado 5 de marzo de 2022, de 
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ecured.cu/Voltaje">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● F. (2021, 29 octubre). ¿Qué es la corriente? www.fluke.com. Recuperado 5 de marzo de 2022, de
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.fluke.com/es-co/informacion/blog/electrica/que-es-la-corriente">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                   <p style={{ padding:'5px 0px 5px 0px'}}>● ¿Qué es la resistencia? (2021, 9 mayo). www.fluke.com. Recuperado 5 de marzo de 2022, de 
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.fluke.com/es-co/informacion/blog/electrica/que-es-la-resistencia#:~:text=La%20resistencia%20es%20una%20medida,entre%20voltaje%2C%20corriente%20y%20resistencia">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                   <p style={{ padding:'5px 0px 5px 0px'}}>● Cómo funciona un circuito eléctrico – Aprende Institute. (s. f.). www.aprende.com. Recuperado 5 de marzo de 2022, de
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://aprende.com/blog/oficios/instalaciones-electricas/como-funciona-un-circuito-electrico/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                   <p style={{ padding:'5px 0px 5px 0px'}}>● Planas, O. (2021, 21 septiembre). ¿Qué es una resistencia eléctrica? Funcionamiento y tipos. www.solar-energia.net. Recuperado 5 de marzo de 2022, de
                                   <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://solar-energia.net/electricidad/circuito-electrico/resistencia-electrica">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>                          
      
                          </div>

                         </div>
                     <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>

                  {/* paper 19 */}
                  <div id="movPage19" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● Ahedo, J. (2021, 22 julio). Que es un condensador eléctrico. Web-Robotica. Recuperado 5 de marzo de 2022, de 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href=" https://www.web-robotica.com/taller-de-web-robotica/electronica/componentes-electronicos/que-es-un-condensador-electrico">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● J.L, B. (2022, 17 enero). Transistores. Electrónica Online. Recuperado 5 de marzo de 2022, de 
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://electronicaonline.net/componentes-electronicos/transistor/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● Tecnología, E. L. (2017, 21 agosto). ¿Que es un LED? | LEDTecnología - Iluminación Led. Led Tecnología - Iluminación Led en Rosario. Recuperado 5 de marzo de 2022, de
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://www.ledtecnologia.com/que-es-un-led/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>

                                  <p style={{ padding:'5px 0px 5px 0px'}}>● Protoboard, ¿Qué es y cómo se usa? (2022, 1 marzo). Descubrearduino.com. Recuperado 5 de marzo de 2022, de
                                  <span style={{display:'inline-flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>  <a rel="noreferrer" target='_blank' href="https://descubrearduino.com/protoboard/">  <FontAwesomeIcon icon={faLink} style={{ cursor:'pointer', fontSize:'1.1rem', color:'FF5733'}}/> </a></span></p>
                              
      
                          </div>

                         </div>
                     <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage19', 18, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>




              

                {/* paper 20 */}
                <div id="movPage20" className="page">
                    <div className="front">
                        <div className="front__content background-page2">
                            <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                <a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage19', 11, '', '', '', "14")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                {/*fin*/}
                                </div>
                            </div>
                            <div data-active="15" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/*<a style={{opacity:'0.9', backgroundColor:'#ef7a51ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

export default Guia1Naidi1;
